import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faLock, faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { isFactorTv, isPlatformWeb } from '@rnv/renative';
import * as React from 'react';
import { Image as RNImage, Pressable as RNPressable, View as RNVIew } from 'react-native';
import { Animated, CoreManager, Image, Pressable, View } from '@scriptx-com/xtv-toolkit';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { PlayerContext } from '../../../context/playerContext';
import { UserContext } from '../../../context/userContext';
import { favoriteStatus, watchingStatus } from '../../../data/account';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { InteractiveTV } from '../../../models/content/shared/interactivetv';
import { Translations } from '../../../models/content/shared/translations';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import TheoPlayer from '../../components/players/theoPlayer';
import { RemoteImageHeightWidth } from '../../components/remoteimage/remoteimage';
import Text from '../../components/text';
import { getDrm } from '../players/helpers/security';


const AnimatedView = Animated.createAnimatedComponent(View);
const AnimatedPressable = Animated.createAnimatedComponent(isPlatformWeb ? RNPressable : Pressable);
const AnimatedImage = Animated.createAnimatedComponent(Image);

export interface Program {
    n: string;
    s: any;
    e: any;
}

export interface Data {
    name: string;
    _id: string;
    number?: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    catchuptv?: boolean;
    interactivetv?: InteractiveTV;
    progress?: number;
    program?: Program;
    translations?: Translations[];
    streams?: any;
    parental?: any;
}

export interface Props {
    fontDensity?: number;
    focusContext?: any;
    focusRepeatContext?: any;
    noMargin?: boolean;
    height: number;
    focusOptions?: any;
    width: number;
    widewidth: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    index: number;
    rails?: boolean;
    onLongPress: any;
}

export const Player = ({ fontDensity, focusContext, focusRepeatContext, noMargin, height, width, widewidth, focusOptions, onPress, styling, type, data, index, onLongPress }: Props) => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const screenName = getCurrentScreenName();
    const [showPlayer, setShowplayer] = React.useState(false);
    const [showWidescreen, setShowWidescreen] = React.useState(false);
    const animatedWidth = React.useRef(new Animated.Value(width)).current;
    const animatedOpacity = React.useRef(new Animated.Value(0)).current;
    const pressableRef = React.useRef<RNVIew>(null);
    const viewRef = React.useRef<RNVIew>(null);
    const posterRef = React.useRef<RNImage>(null);
    const widescreenRef = React.useRef<RNImage>(null);
    const timeout = React.useRef<NodeJS.Timeout>();
    const EXPANDABLE_WIDTH = (height / 9) * 16;
    const EXPANDABLE_OPACITY = 1;
    const TIMEOUT_MS = deviceContext.isWebTV ? 1000 : 2000;

    if (fontDensity == undefined) {
        fontDensity = 1;
    }
    var favorite = favoriteStatus(type, data?._id, userContext);
    var progress = -1;
    var parentalIsApplied = false;

    const startPlayer = async (data) => {
        var stream = '' as any;
        switch (type) {
            case 'Movies':
                if (!data?.parental?.enabled) {
                    stream = data?.streams?.movies?.trailer != undefined ? data?.streams?.movies?.trailer : data?.streams?.movies?.url ?? '';
                    var watching = watchingStatus('Movies', data._id, userContext);
                    var drm = await getDrm(data, data._id, 'movies', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    playerContext.setStream({
                        url: stream,
                        progress: watching != undefined && watching.progress != 0 ? watching.position : 0, //TODO:test this
                    });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Movies',
                        error: '',
                    });
                    setShowplayer(true);
                    playerContext.setPaused(false);
                }
                break;
        }
    };

    if (type == 'Movies') {
        try {
            var watching = watchingStatus('Movies', data?._id, userContext);
            progress = Math.round((watching.position / watching.duration) * 100);
        } catch (e) {}
    }

    const getRibbonText = () => {
        var test = styling.ribbon.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.title != undefined) {
                return styling.content.title[0].title;
            } else {
                return '';
            }
        }
    };
    const getExtraStyle = () => {
        if (styling.ribbon.align == 'Right') {
            return { right: -2 };
        } else {
            return { left: -2 };
        }
    };

    const getCorrectTranslation = (translations) => {
        return translations.find((trans) => trans.name == userContext.selectedLanguage)?.description;
    };
    const focusWidth = () => {
        return getPressableStyling(styling.components.button._id, appContext).borderWidth * 2;
    };

    const animate = React.useCallback((animationValue, opacityValue) => {
        Animated.timing(animatedWidth, {
            toValue: animationValue,
            duration: 500,
            useNativeDriver: false,
        }).start(() => {
            Animated.timing(animatedOpacity, {
                toValue: opacityValue,
                duration: 200,
                useNativeDriver: false,
            }).start();
        });
    }, []);

    const onFocus = (index) => {
        if (showPlayer == false && showWidescreen == false) {
            timeout.current = setTimeout(() => {
                animate(EXPANDABLE_WIDTH - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 4), EXPANDABLE_OPACITY);
                setShowWidescreen(true);
                clearTimeout(timeout.current);
                timeout.current = setTimeout(() => {
                    setShowWidescreen(false);
                    setShowplayer(true);
                    startPlayer(data);
                }, TIMEOUT_MS * 2);
            }, TIMEOUT_MS);
        }
    };

    React.useEffect(() => {
        animatedWidth.addListener(({ value }) => {
            if (isFactorTv) {
                CoreManager.setViewIsAnimating(true);
                if (Math.round(value) === Math.round(width)) {
                    CoreManager.setViewIsAnimating(false);
                }
            }

            if (pressableRef.current) {
                pressableRef.current.setNativeProps({
                    style: {
                        width: value,
                    },
                });
            }
            if (viewRef.current) {
                viewRef.current.setNativeProps({
                    style: {
                        width: value,
                    },
                });
            }
        });
        animatedOpacity.addListener(({ value }) => {
            if (widescreenRef.current) {
                widescreenRef.current.setNativeProps({
                    style: {
                        opacity: value,
                    },
                });
            }
        });
    }, [pressableRef]);

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        setShowWidescreen(false);

        animate(width, 0);
        setShowplayer(false);
    };

    const checkIfLogoExists = () => {
        if (data?.images?.logo != undefined && data?.images?.logo != '') {
            return true;
        } else {
            return false;
        }
    };

    if (data == undefined) {
        return null;
    } else {
        return (
            <View
                style={{
                    backgroundColor: appContext.application.theme.images?.background,
                    margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                    borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius),
                }}
            >
                <AnimatedPressable
                    ref={pressableRef}
                    style={[
                        {
                            ...getPressableStyling(styling.components.button._id, appContext),
                            paddingHorizontal: getCorrectWidth(deviceContext, 2.5),
                            width: width,
                            height: height,
                        },
                    ]}
                    onHoverIn={() => onFocus(data)}
                    onHoverOut={() => onBlur()}
                    onFocus={() => onFocus(data)}
                    onBlur={() => onBlur()}
                    focusContext={focusContext}
                    onLongPress={() => onLongPress(data?._id)}
                    focusRepeatContext={focusRepeatContext}
                    animatorOptions={getFocusStyling('Buttons', appContext)}
                    onPress={() => (screenName == 'Recordings' ? onPress(data) : onPress(data?._id))}
                    focusOptions={{
                        ...focusOptions,
                        animator: getFocusStyling('Buttons', appContext),
                    }}
                >
                    {styling.ribbon != undefined && styling.ribbon.show_ribbon && (
                        <View style={[getExtraStyle(), { position: 'absolute', zIndex: 9999 }]}>
                            <View
                                style={{
                                    padding: getCorrectWidth(deviceContext, 3),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: styling.ribbon.background_color,
                                    borderRadius: getCorrectHeight(deviceContext, styling.ribbon.border_radius),
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.ribbon.title_color,
                                        fontWeight: styling.ribbon.title_bold ? 'bold' : 'normal',
                                    }}
                                >
                                    {getRibbonText()}
                                </Text>
                            </View>
                        </View>
                    )}
                    <View
                        style={{
                            margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius),
                            width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                            height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                            alignItems: 'center',
                            alignContent: 'center',
                            alignSelf: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <AnimatedView
                            ref={viewRef}
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                alignContent: 'center',
                                alignSelf: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {!parentalIsApplied && showPlayer && (
                                <View
                                    style={{
                                        height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                        width: widewidth - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 6),
                                        alignItems: 'center',
                                    }}
                                >
                                    <TheoPlayer
                                        adsEnabled={false}
                                        castEnabled={false}
                                        style={{
                                            objectFit: 'cover',
                                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                            height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth(),
                                            width: widewidth - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 6) - focusWidth(),
                                        }}
                                    />
                                </View>
                            )}
                            {!parentalIsApplied && !showPlayer && !showWidescreen && (
                                <AnimatedImage
                                    ref={posterRef}
                                    deviceContext={deviceContext}
                                    source={{ uri: appContext.cloudUrl + data?.images?.poster }}
                                    resizeMethod={'resize'}
                                    resizeMode={'cover'}
                                    style={{
                                        borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                        width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth(),
                                        height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth(),
                                    }}
                                />
                            )}
                            {!showPlayer && showWidescreen && (
                                <AnimatedImage
                                    ref={widescreenRef}
                                    deviceContext={deviceContext}
                                    source={{ uri: appContext.cloudUrl + data?.images?.background }}
                                    resizeMethod={'resize'}
                                    resizeMode={'cover'}
                                    style={{
                                        opacity: 0,
                                        borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                        height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth(),
                                        width: widewidth - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 6) - focusWidth(),
                                    }}
                                />
                            )}

                            {appContext.application.theme.icons.show_favorite && favorite && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        top: 5,
                                        right: 5,
                                        width: '100%',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <View
                                        style={{
                                            backgroundColor: '#333',
                                            borderRadius: 100,
                                            padding: getCorrectHeight(deviceContext, 3),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faHeart} color={'#fff'} size={getFontSize(deviceContext, 'Normal')} />
                                    </View>
                                </View>
                            )}
                            {parentalIsApplied && (
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: height - appContext.application.theme.images.margin * 2 - getPressableStyling(styling.components.button._id, appContext).borderWidth * 2,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faLock} color={'#999'} size={getFontSize(deviceContext, 'ExtraLarge')}></FontAwesomeIcon>
                                </View>
                            )}
                            {appContext.application.theme.icons.show_play && styling.content.styling.content.play_directly && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 0,
                                        left: 0,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            borderRadius: 100,
                                            height: width / 7,
                                            width: width / 7,
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            size={width / 15}
                                            style={{
                                                color: appContext.application.theme.icons.background,
                                            }}
                                            icon={faPlay}
                                        />
                                    </View>
                                </View>
                            )}
                            {(showPlayer || showWidescreen) && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        bottom: appContext.application.theme.images.margin * 2,
                                        left: appContext.application.theme.images.margin * 2,
                                        right: appContext.application.theme.images.margin * 2,
                                    }}
                                >
                                    {!checkIfLogoExists() && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                                color: styling.texts.title_color,
                                                fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                                marginLeft: getCorrectHeight(deviceContext, 5),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data?.name}
                                        </Text>
                                    )}
                                    {checkIfLogoExists() && (
                                        <View style={{ marginLeft: getCorrectWidth(deviceContext, 5) }}>
                                            <RemoteImageHeightWidth uri={appContext.cloudUrl + data?.images.logo} desiredWidth={100} desiredHeight={100} deviceContext={deviceContext} />
                                        </View>
                                    )}
                                    {data.translations != undefined && data.translations?.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                                marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 2),
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                                                color: styling.texts.subtext_color,
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data.translations?.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                                        </Text>
                                    )}
                                    {data.translations != undefined && data.translations?.find((trans) => trans.name == userContext.selectedLanguage) == undefined && data[0].translations.length > 0 && (
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                                marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 2),
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: styling.texts.subtext_bold ? 'bold' : null,
                                                color: styling.texts.subtext_color,
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data.translations[0]?.description}
                                        </Text>
                                    )}

                                    {progress > 0 && (
                                        <View
                                            style={{
                                                marginRight: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                                marginVertical: getCorrectHeight(deviceContext, 2),
                                                backgroundColor: progress == 0 ? 'transparent' : appContext.application.theme.progresses.base,
                                                height: getCorrectHeight(deviceContext, 2),
                                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                shadowOffset: { width: -1, height: 1 },
                                                shadowRadius: 2,
                                            }}
                                        >
                                            <View
                                                style={{
                                                    backgroundColor: appContext.application.theme.progresses.progress,
                                                    height: getCorrectHeight(deviceContext, 2),
                                                    shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                    shadowOffset: { width: -1, height: 1 },
                                                    shadowRadius: 2,

                                                    width: progress + '%',
                                                }}
                                            ></View>
                                        </View>
                                    )}
                                </View>
                            )}
                        </AnimatedView>
                    </View>
                </AnimatedPressable>
            </View>
        );
    }
};
export default Player;
