import { View, withParentContextMapper, Image, CoreManager, setFocus } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { isFactorTv } from '@rnv/renative';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { Images } from '../../../models/content/shared/images';
import { Hero_Slider as styling } from '../../../models/elements/heros/slider';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { TextFade } from '../../animations/textFade';
import { getChannelsFromSubscription, getCoursesFromSubscription, getGamesFromSubscription, getMoviesFromSubscription, getSeriesFromSubscription } from '../rails/helpers/helper';
import moment from 'moment';
import { BackgroundImageBoxed, ButtonRow, DescriptionRow, LogoRow, MetaRow, Pager, StatusTimeRow } from './helpers/helpers_landscape';
import { onPressContinue, onPressFavorite, onPressInformation, onPressPlay, onPressPlayCatchupTV, onPressPlaySeason, onPressPurchase, onPressReplay, onPressTrailer, onPressWatchlist } from '../details/helpers/functions';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import Swiper from '../../components/swiper/swiper';
import { SettingsContext } from '../../../context/settingsContext';
import { checkIfChannelHasPrograms } from './helpers/helper';

export interface Data {
    images: Images;
    name: string;
    tags: string;
    translations: any;
    _id: string;
}

export interface Props {
    styling: styling;
    navigation: any;
    focusContext: any;
}

const TextFadeWithContext = withParentContextMapper(TextFade);
const FocusAwareSwiper = withParentContextMapper(Swiper);

export const Hero_Single = ({ styling, navigation, focusContext }: Props) => {
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const settingsContext = React.useContext(SettingsContext);
    const initialFocusSet = React.useRef(false);

    const screenName = getCurrentScreenName();

    const [data, setData] = React.useState([] as any);
    const [pageIndex, setPageIndex] = React.useState(0);

    const [updateScreenFavorite, setUpdateScreenFavorite] = React.useState(0);
    const [updateScreenWatchlist, setUpdateScreenWatchlist] = React.useState(0);

    const getData = async () => {
        var path = '';
        if (styling.content.type == 'Content List') {
            if (styling.content.list?.type == 'Movies') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Series') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Channels') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Games') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/games/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = (await response.json()) as any;
                if (data != undefined && data.length > 0) {
                    if (styling.content.list?.type == 'Channels') {
                        var dataOut = getChannelsFromSubscription(contentContext, data[0].items);
                        var dataOutNew = [] as any;
                        if (dataOut.length > 0) {
                            for (var channel of dataOut) {
                                var test = checkIfChannelHasPrograms(channel, contentContext);
                                if (test) {
                                    dataOutNew.push(channel);
                                }
                            }
                            setData(dataOutNew);
                        }
                    } else if (styling.content.list?.type == 'Movies') {
                        var dataOut = getMoviesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Courses') {
                        var dataOut = getCoursesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Series') {
                        var dataOut = getSeriesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    } else if (styling.content.list?.type == 'Games') {
                        var dataOut = getGamesFromSubscription(contentContext, data[0].items);
                        if (dataOut.length > 0) {
                            let newData = dataOut;
                            if (dataOut.length > 5) {
                                newData = dataOut.slice(0, 5);
                            }
                            setData(newData);
                        }
                    }
                }
            } catch (error) {
                setData(null);
            }
        }
    };

    React.useEffect(() => {
        navigation.addListener('focus', () => {
            getData();
        });
    }, [navigation]);

    const HeroObject = ({ item, index }) => {
        var program = [] as any;
        if (styling.content.list?.type == 'Channels') {
            if (contentContext.epgTv != undefined) {
                var epgChannel = contentContext.epgTv?.find((e) => e.i == item._id);
                if (epgChannel != undefined) {
                    var currentTime = moment().unix();
                    program = epgChannel.epg.find((e) => e.s <= currentTime && e.e >= currentTime);
                }
            }
        }

        if (index > 0) {
            initialFocusSet.current = true;
        }

        return (
            <View key={index} focusContext={focusContext}>
                <BackgroundImageBoxed styling={styling} program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext}></BackgroundImageBoxed>
                <View
                    style={{
                        zIndex: 3,
                        width: getRealWidth(deviceContext),
                        height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.55 : 0.47),
                        justifyContent: 'center',
                        // paddingLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                    }}
                >
                    <View style={{ paddingLeft: getCorrectWidth(deviceContext, 40) }}>
                        <LogoRow program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={styling}></LogoRow>
                        {styling.content.list?.type == 'Channels' && (
                            <StatusTimeRow
                                program={program}
                                item={data[index]}
                                type={styling.content.list?.type}
                                deviceContext={deviceContext}
                                appContext={appContext}
                                userContext={userContext}
                                contentContext={contentContext}
                                styling={styling}
                            ></StatusTimeRow>
                        )}
                        {styling.content.list?.type != 'Channels' && (
                            <MetaRow program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></MetaRow>
                        )}
                        <DescriptionRow program={program} item={data[index]} type={styling.content.list?.type} deviceContext={deviceContext} userContext={userContext} contentContext={contentContext} styling={styling}></DescriptionRow>
                    </View>

                    <View style={{ paddingLeft: getCorrectWidth(deviceContext, 40) }}>
                        <ButtonRow
                            focusable={index === pageIndex}
                            hasPreferredFocus={index === 0 && !initialFocusSet.current}
                            focusContext={focusContext}
                            item={data[index]}
                            program={program}
                            type={styling.content.list?.type}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPressBack={() => navigation.goBack()}
                            onPressPlay={(item) => onPressPlay(styling.content.list?.type, navigation, item, contentContext, appContext, userContext, 0, settingsContext)}
                            onPressInformation={(item) => onPressInformation(styling.content.list?.type, contentContext, appContext, userContext, item, navigation, settingsContext)}
                            onPressPlaySeason={(seasonIndex, episodeIndex) => onPressPlaySeason(contentContext, data, navigation, seasonIndex, episodeIndex, settingsContext)}
                            onPressPlayCatchupTV={(startType, item, programIndex) => onPressPlayCatchupTV(navigation, startType, item, contentContext, settingsContext, programIndex)}
                            onPressTrailer={(item) => onPressTrailer(styling.content.list?.type, navigation, item, settingsContext)}
                            onPressPurchase={() => onPressPurchase()}
                            onPressFavorite={(isFavorite, item) => onPressFavorite(isFavorite, contentContext, userContext, styling.content.list?.type, appContext, deviceContext, setUpdateScreenFavorite, item)}
                            onPressWatchlist={(isWatchlist, item, seasonIndex, episodeIndex) =>
                                onPressWatchlist(isWatchlist, userContext, styling.content.list?.type, appContext, contentContext, setUpdateScreenWatchlist, item, seasonIndex, episodeIndex)
                            }
                            onPressContinue={(item) => onPressContinue(styling.content.list?.type, navigation, item, settingsContext)}
                        />
                    </View>
                </View>
            </View>
        );
    };

    var left = getCorrectLeftMargin(deviceContext, appContext, screenName) + 10 + getCorrectWidth(deviceContext, global.menuWidth);
    return (
        <View style={{ flex: 1, paddingLeft: getCorrectWidth(deviceContext, global.menuWidth), paddingRight: getCorrectWidth(deviceContext, global.menuWidth) }} focusContext={focusContext}>
            {data != null && data.length != 0 && (
                <View
                    style={{
                        position: 'absolute',
                        width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth),
                        height: getRealHeight(deviceContext),
                        zIndex: -1,
                        opacity: 0.5,
                    }}
                >
                    <Image
                        source={require('../../../../assets/images/blackis.png')}
                        resizeMode={'cover'}
                        style={{ height: getRealHeight(deviceContext) + 10, width: getRealWidth(deviceContext) + getCorrectWidth(deviceContext, global.menuWidth) }}
                    ></Image>
                </View>
            )}

            {data != null && data.length != 0 && (
                <View
                    style={{
                        marginTop: getCorrectHeight(deviceContext, 50),
                        left: left - 10,
                        width: getRealWidth(deviceContext),
                        flexDirection: 'row',
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    <View
                        style={[
                            {
                                ...getPressableStyling(styling.components.button._id, appContext),
                                height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.53) + 14,
                                padding: 4,
                                width: getRealWidth(deviceContext) - left,
                                flexDirection: 'row',
                                backgroundColor: 'transparent',
                                zIndex: -1,
                            },
                        ]}
                        focus={false}
                        focusOptions={{
                            stealFocus: true,
                            focusKey: 'content-screen',
                            nextFocusLeft: ['menu-drawer'],
                            autoFocusEnabled: true,
                        }}
                        animatorOptions={{
                            type: 'border',
                            focus: {
                                duration: 150,
                                borderColor: getFocusStyling('Buttons', appContext).focus.borderColor,
                                borderWidth: 2,
                                backgroundColor: 'transparent',
                            },
                            blur: {
                                borderWidth: 0,
                                backgroundColor: 'transparent',
                            },
                        }}
                        hasPreferredFocus={true}
                        onPress={() => null}
                    >
                        <View style={{ width: getRealWidth(deviceContext) - left + 10 }}>
                            <FocusAwareSwiper
                                focusContext={focusContext}
                                loop={true}
                                showsPagination={true}
                                scrollEnabled={true}
                                autoplayTimeout={9}
                                index={pageIndex}
                                animated={true}
                                width={getRealWidth(deviceContext)}
                                height={getRealHeight(deviceContext) * 0.6}
                                autoplay={true}
                                pagingEnabled={true}
                                showsButtons={false}
                                paginationStyle={{
                                    position: 'absolute',
                                    bottom: deviceContext.isWebTV || deviceContext.isTablet ? 20 : 0,
                                    left: 0,
                                    right: 80,
                                    flexDirection: 'row',
                                    flex: 1,
                                    justifyContent: 'flex-end',
                                }}
                                dotStyle={{ marginRight: getCorrectWidth(deviceContext, 5) }}
                                activeDotStyle={{ marginRight: getCorrectWidth(deviceContext, 5) }}
                                dotColor={appContext.application.theme.progresses.base}
                                activeDotColor={appContext.application.theme.progresses.progress}
                                onIndexChanged={(index) => {
                                    if (isFactorTv) {
                                        setPageIndex(index);
                                    }
                                }}
                                startingPoint={getCorrectWidth(deviceContext, global.menuWidth) + getCorrectLeftMargin(deviceContext, appContext, screenName)}
                            >
                                {data.map((item, index) => (
                                    <HeroObject key={index} item={item} index={index} />
                                ))}
                            </FocusAwareSwiper>
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
};
export default Hero_Single;
