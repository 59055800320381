import * as React from 'react';
import { View, FlashList, CreateListRenderItemInfo, FocusContext, Pressable as Pressable_, setFocus, CoreManager } from '@scriptx-com/xtv-toolkit';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import Widescreen from '../shared/widescreen';
import { getFontSize } from '../../../styling/fontSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getRealWidth } from '../../../styling/realDeviceSizes';
import { ContentContext } from '../../../context/contentContext';
import {
    checkIfAccessToContent,
    getCamsFromSubscription,
    getChannelsFromSubscription,
    getCoursesFromSubscription,
    getDetailsScreen,
    getFavoritesForRails,
    getGamesFromSubscription,
    getRadioFromSubscription,
    getSeriesFromSubscription,
    getSortingByRecommendation,
    getWatchlistForRails,
} from './helpers/helper';
import { UserContext } from '../../../context/userContext';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Pressable from '../../components/pressable/pressable';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faRectangleHistory } from '@fortawesome/pro-light-svg-icons';
import { FlashList as FlashList_ } from '@scriptx-com/flash-list';
import Text from '../../components/text';
import { getExternalSearchData, iterateLevel, mapValuesToShort, replaceIterationKeys } from '../grids/helpers/functions';
import { getFocusStyling, getModalStyling, getPressableStyling } from '../../components/helpers/helper';
import Modal from '../../components/modal/modal';
import { favoriteStatus, manageFavorite, manageWatching, watchingStatus } from '../../../data/account';
import lang from '../../../../application/languages/languages';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { isFactorMobile, isFactorTv, isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import { MediaCache } from 'react-native-theoplayer';
import { getCorrectLeftMargin } from '../../../styling/appLeftMargin';
import { SettingsContext } from '../../../context/settingsContext';
import { GetBottomPart, GetScrollElement, GetTopPart } from './helpers/views';


export interface Data {
    items: [
        {
            image: string;
            title: string;
            _id: string;
        }
    ];
}

export interface Props {
    styling: any;
    focusContext?: FocusContext;
    navigation: any;
    reloadElements: any;
    heroIsPlayer: boolean;
    railIndex?: number;
}

export const Widescreen_Rails = React.memo(({ styling, focusContext, navigation, reloadElements, heroIsPlayer, railIndex }: Props) => {
    const [data, setData] = React.useState([] as any);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const settingsContext = React.useContext(SettingsContext);

    const rails = React.useRef<FlashList_<number> | null>(null);
    const [viewIndex, setViewIndex] = React.useState(0);
    const [viewableItemsCount, setViewableItemsCount] = React.useState(0);
    const screenName = getCurrentScreenName();

    let height = isPlatformAndroid || isPlatformIos ? React.useRef(getCorrectHeight(deviceContext, styling.placement?.height)).current : getCorrectHeight(deviceContext, styling.placement?.height);
    var width = (height / 9) * 16;
    var fontDensity = height / 150;

    const [dataRefreshed, setDataRefreshed] = React.useState<any>();
    const currentFocusRailIndex = React.useRef<number | undefined>();

    const getData = async () => {
        if (styling.content.type == 'Watched') {
            var items = (await getWatchlistForRails(styling.content.list?.type, userContext, contentContext, appContext)) as any;
            if (items != undefined && items.length > 0) {
                if (checkIfAccessToContent(appContext, styling.content.list?.type)) {
                    setData(items);
                    setDataRefreshed(Date.now());
                }
            }
        }

        if (styling.content.type == 'Favorites') {
            var items = (await getFavoritesForRails(styling.content.list?.type, userContext, contentContext, appContext)) as any;
            if (items != undefined && items.length > 0) {
                if (checkIfAccessToContent(appContext, styling.content.list?.type)) {
                    setData(items);
                    setDataRefreshed(Date.now());
                }
            }
        }

        if (styling.content.type == 'Downloads') {
            const items = MediaCache.tasks as any;
            if (items != undefined && items.length > 0) {
                setData(items);
            }
        }
        if (styling.content.type == 'External') {
            if (styling.content.mappings.mappings != undefined && styling.content.mappings.iteration_path != '') {
                let response = await fetch(styling.content.mappings.api);
                if (response.status == 200) {
                    let data = await response.json();
                    var items = data[styling.content.mappings.base.slice(0, -1)];
                    var jsonMappings = JSON.parse(styling.content.mappings.mappings);
                    const iterationPath = styling.content.mappings.iteration_path.slice(0, -1);
                    const objects = [];
                    iterateLevel(items, iterationPath, [], (keys) => {
                        objects.push(
                            mapValuesToShort(items, jsonMappings, appContext, (mapping) => {
                                if (mapping == null) {
                                    return undefined;
                                } else {
                                    return replaceIterationKeys(mapping, keys);
                                }
                            })
                        );
                    });
                    if (styling.content.list?.type == 'Shorts') {
                        setData(objects);
                    }
                }
            }
        }
        if (styling.content.type == 'Content List') {
            var path = '';
            if (styling.content.list?.type == 'Series') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Courses') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Channels') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Cams') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/cams/lists/' + styling.content.list.list + '_list.json';
            }
            if (styling.content.list?.type == 'Games') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/games/lists/' + styling.content.list.list + '_list.json';
            }
            try {
                let response = await fetch(path);
                let data = await response.json();
                if (data != undefined) {
                    if (styling.content.list?.type == 'Channels') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getChannelsFromSubscription(contentContext, sortedByRecommended);
                        setData(dataOut);
                    }
                    if (styling.content.list?.type == 'Cams') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getCamsFromSubscription(contentContext, sortedByRecommended);
                        setData(dataOut);
                    }
                    if (styling.content.list?.type == 'Radio') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getRadioFromSubscription(contentContext, sortedByRecommended);
                        setData(dataOut);
                    }
                    if (styling.content.list?.type == 'Series') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getSeriesFromSubscription(contentContext, sortedByRecommended);
                        setData(dataOut);
                    }
                    if (styling.content.list?.type == 'Courses') {
                        var sortedByRecommended = getSortingByRecommendation(data[0].items, userContext.profile.recommendations);
                        var dataOut = getCoursesFromSubscription(contentContext, sortedByRecommended);
                        setData(dataOut);
                    }
                    if (styling.content.list?.type == 'Games') {
                        var dataOut = getGamesFromSubscription(contentContext, data[0].items);
                        setData(dataOut);
                    }
                }
            } catch (error) {
                setData(null);
            }
        }
    };

    React.useEffect(() => {
        if (railIndex === currentFocusRailIndex.current) {
            if (data.length === 0) {
                setFocus(`empty-wide-placeholder-${railIndex}`);
            } else {
                const focusIndex = data.length > 2 ? 2 : 0;
                setFocus(`wide-${railIndex}-${focusIndex}`);
            }
        }
    }, [dataRefreshed]);

    useEffectOnce(() => {
        getData();
    });

    React.useEffect(() => {
        if (styling.content.type == 'Watched' || styling.content.type == 'Favorites' || styling.content.type == 'Downloads') {
            getData();
        }
    }, [userContext.refreshData]);

    React.useEffect(() => {
        async function myAsynFunction() {
            try {
                if (styling.content.list?.type == 'Recordings' && appContext.application.menus?.find((m) => m.name == 'Channels') != undefined) {
                    var recordings = [] as any;
                    if (contentContext.search.length > 2) {
                        contentContext.recordings.forEach((recording) => {
                            if (recording.program.name.toLowerCase().indexOf(contentContext.search.toLowerCase()) > -1) {
                                if (!recordings.some((_) => _._id === recording._id)) {
                                    recordings.push(recording);
                                }
                            }
                        });
                    }
                    if (recordings.length > 0 && contentContext.search.length > 2) {
                        setData(recordings);
                    }
                }
                if (styling.content.list?.type == 'Channels' && appContext.application.menus?.find((m) => m.name == 'Channels') != undefined) {
                    var channels = [] as any;
                    if (contentContext.search.length > 2) {
                        contentContext.channels.categories.forEach((category) => {
                            category.channels.forEach((channel) => {
                                if (channel.name.toLowerCase().indexOf(contentContext.search.toLowerCase()) > -1) {
                                    if (!channels.some((_) => _._id === channel._id)) {
                                        channels.push(channel);
                                    }
                                }
                            });
                        });
                    }
                    if (channels.length > 0 && contentContext.search.length > 2) {
                        setData(channels);
                    }
                }
                if (styling.content.list?.type == 'Cams' && appContext.application.menus?.find((m) => m.name == 'Cams') != undefined) {
                    var cams = [] as any;
                    if (contentContext.search.length > 2) {
                        contentContext.cams.categories.forEach((category) => {
                            category.cams.forEach((cam) => {
                                if (cam.name.toLowerCase().indexOf(contentContext.search.toLowerCase()) > -1) {
                                    if (!cams.some((_) => _._id === cam._id)) {
                                        cams.push(cam);
                                    }
                                }
                            });
                        });
                    }
                    if (cams.length > 0 && contentContext.search.length > 2) {
                        setData(cams);
                    }
                }
                if (styling.content.list?.type == 'Courses' && appContext.application.menus?.find((m) => m.name == 'Courses') != undefined) {
                    var courses = [] as any;
                    if (contentContext.search.length > 2) {
                        contentContext.courses.categories.forEach((category) => {
                            category.courses.forEach((course) => {
                                if (course.name.toLowerCase().indexOf(contentContext.search.toLowerCase()) > -1) {
                                    if (!courses.some((_) => _._id === course._id)) {
                                        courses.push(course);
                                    }
                                }
                            });
                        });
                    }
                    if (courses.length > 0 && contentContext.search.length > 2) {
                        setData(courses);
                    }
                }
                if (styling.content.list?.type == 'Series' && appContext.application.menus?.find((m) => m.name == 'Series') != undefined) {
                    var series = [] as any;
                    if (contentContext.search.length > 2) {
                        contentContext.series.categories.forEach((category) => {
                            category.series.forEach((serie) => {
                                if (serie.name.toLowerCase().indexOf(contentContext.search.toLowerCase()) > -1) {
                                    if (!series.some((_) => _._id === serie._id)) {
                                        series.push(serie);
                                    }
                                }
                            });
                        });
                    }
                    if (series.length > 0 && contentContext.search.length > 2) {
                        setData(series);
                    }
                }
                if (styling.content.list?.type == 'Shorts' && appContext.application.menus?.find((m) => m.name == 'Shorts') != undefined) {
                    var shorts = [] as any;
                    if (contentContext.search.length > 2) {
                        if (styling.content.type == 'Search External') {
                            var result = await getExternalSearchData(styling.content, appContext, contentContext.search);
                            result.forEach((short, index) => {
                                if (index < 50) {
                                    shorts.push(short);
                                }
                            });
                        } else {
                            contentContext.shorts.categories.forEach((category) => {
                                category.shorts.forEach((short) => {
                                    if (short.name.toLowerCase().indexOf(contentContext.search.toLowerCase()) > -1) {
                                        if (!shorts.some((_) => _._id === shorts._id)) {
                                            shorts.push(short);
                                        }
                                    }
                                });
                            });
                        }
                    }
                    if (shorts.length > 0 && contentContext.search.length > 2) {
                        setData(shorts);
                    }
                }
            } catch (e) {
                setData([]);
            }
        }
        myAsynFunction();
    }, [contentContext.search]);

    const detailsItem = (item: any, index: any) => {
        getDetailsScreen(styling, userContext, contentContext, appContext, navigation, item, settingsContext);
    };


    const renderItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        if (styling.content.type == 'Downloads') {
            //item = userContext.downloads.find((d) => d._id == item.source.metadata._id);
        }
        return (
            <Widescreen
                heroIsPlayer={heroIsPlayer}
                onLongPress={() => showLongpressModal(item, index)}
                rails={true}
                fontDensity={fontDensity}
                type={styling.content.list?.type}
                styling={styling}
                data={item}
                height={height}
                width={width}
                focusOptions={{
                    focusKey: `wide-${railIndex}-${index}`,
                }}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                onPress={() => detailsItem(item, index)}
            ></Widescreen>
        );
    };

    const getRailsWidth = () => {
        if (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs) {
            return getRealWidth(deviceContext) - getCorrectWidth(deviceContext, 5);
        } else {
            return getRealWidth(deviceContext) - getCorrectWidth(deviceContext, +styling.placement.padding + styling.placement.margin_left + styling.placement.margin_right + global.menuWidth);
        }
    };

    const getRailsHeight = () => {
        if (styling.placement.height < height * 2) {
            return null;
        } else {
            var test = getCorrectHeight(deviceContext, styling.placement.height + styling.placement.padding * 2 + appContext.application.theme.rails_bottom_margin + appContext.application.theme.rails_top_margin);
            return test;
        }
    };

    const scrollRight = () => {
        const next = viewIndex + 1;
        if (next === data.length) {
            rails.current?.scrollToEnd({ animated: true });
        } else {
            rails.current?.scrollToIndex({ animated: true, index: viewIndex + 1 });
        }
    };

    const scrollLeft = () => {
        const next = Math.max(0, viewIndex - viewableItemsCount);
        rails.current?.scrollToIndex({ animated: true, index: next });
    };

    const onViewableItemsChanged = ({ viewableItems, changed }) => {
        if (isFactorTv || isFactorMobile) return;
        if (viewableItems.length !== viewableItemsCount) {
            setViewableItemsCount(viewableItems.length);
        }
        if (Array.isArray(changed)) {
            let visibleItemIndex;
            changed.forEach((item) => {
                if (item.isViewable) {
                    visibleItemIndex = item.index;
                }
            });
            if (visibleItemIndex !== undefined) {
                setViewIndex(visibleItemIndex);
            }
        }
    };

    const [showLongpress, setShowLongpress] = React.useState(false);
    const [favorite, setFavorite] = React.useState(false);
    const [watchlist, setWatchlist] = React.useState(false);
    const [longItem, setLongItem] = React.useState(false);

    const showLongpressModal = (item, index) => {
        const currentFocusParent = CoreManager.getCurrentFocus()?.getParent()?.getParent();
        const parentGroup = currentFocusParent;
        // @ts-ignore
        currentFocusRailIndex.current = Number(parentGroup?._focusKey?.split?.('-')?.[1]);

        if (styling.content.press_and_hold && (appContext.application.settings.allow_watchlist || appContext.application.settings.allow_favorites)) {
            setFavorite(favoriteStatus(styling.content.list?.type, item._id, userContext));
            setWatchlist(watchingStatus(styling.content.list?.type, item._id, userContext) == undefined ? false : true);
            setLongItem(item);
            setShowLongpress(true);
        }
    };
    const manageWatchlist_ = () => {
        setWatchlist(!watchlist);
        manageWatching(userContext, styling.content.list?.type, longItem, appContext.application, watchlist, 0, 0, [], [], [], [], contentContext);
        setShowLongpress(false);
        reloadElements();
    };
    const manageFavorite_ = () => {
        setFavorite(!favorite);
        manageFavorite(contentContext, styling.content.list?.type, longItem, appContext.application, favorite, userContext, deviceContext, appContext);
        setShowLongpress(false);
        reloadElements();
    };

    React.useEffect(() => {
        if (showLongpress) {
            appContext.setModal(
                <Modal
                    watchlist={watchlist}
                    favorite={favorite}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    type={'quickmenu'}
                    submitChoice2={manageWatchlist_}
                    submitChoice={manageFavorite_}
                    setShowModal={() => setShowLongpress(false)}
                    data={longItem}
                    contentType={styling.content.list?.type}
                />
            );
        } else {
            appContext.setModal(null);
        }
    }, [showLongpress, watchlist, favorite, longItem]);

    const extraTopBottomMargin = () => {
        if (styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent') {
            return getCorrectHeight(deviceContext, styling.placement.padding);
        } else {
            return 0;
        }
    };

    const openOverviewPage = () => {
        navigation &&
            navigation.navigate(styling.content.list?.type, {
                railsItems: data,
            });
    };

    return (
        <View focusContext={focusContext}>
            {data != null && data.length > 0 && (
                <View
                    style={{
                        flex: 1,
                        marginLeft: getCorrectLeftMargin(deviceContext, appContext, screenName),
                        marginRight: 0,
                        marginTop: appContext.application.theme.rails_top_margin + extraTopBottomMargin(),
                        marginBottom: appContext.application.theme.rails_bottom_margin + extraTopBottomMargin(),
                    }}
                >
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                backgroundColor: styling.placement.background_color,
                                height: data.length > 0 ? getRailsHeight() : data.length == 0 && styling.content.type != 'Search' ? getRailsHeight() : 0,
                                borderRadius: getCorrectWidth(deviceContext, styling.placement.border_radius),
                                paddingTop: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingBottom: getCorrectHeight(deviceContext, styling.placement.padding),
                                paddingLeft:
                                    styling.placement.background_color != '#00000000' && styling.placement.background_color != 'transparent'
                                        ? deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                            ? getCorrectWidth(deviceContext, 5)
                                            : getCorrectWidth(deviceContext, styling.placement.padding)
                                        : 0,
                                paddingRight:
                                    deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                        ? getCorrectWidth(deviceContext, 0)
                                        : deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs
                                        ? 0
                                        : getCorrectWidth(deviceContext, styling.placement.padding),
                            }}
                        >
                            <GetTopPart focusContext={focusContext} openOverviewPage={openOverviewPage} deviceContext={deviceContext} appContext={appContext} userContext={userContext} styling={styling} data={data} ></GetTopPart>
                            {data.length > 0 && (
                                <View style={{ flexDirection: 'row' }}>
                                    {(styling.content.type == 'Watched' || styling.content.type == 'Favorites' || data.length > 0) && styling.content.list?.enable_overview && (
                                        <View style={{ marginRight: getCorrectWidth(deviceContext, 4) }}>
                                            <Pressable
                                                style={[
                                                    {
                                                        ...getPressableStyling(styling.components.button._id, appContext),
                                                        height: height - getCorrectWidth(deviceContext, 7),
                                                        width: width,
                                                    },
                                                ]}
                                                focusContext={focusContext}
                                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                                onPress={() => openOverviewPage()}
                                            >
                                                <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                                    <FontAwesomeIcon icon={faRectangleHistory} color={'#fff'} size={getFontSize(deviceContext, 'ExtraExtraLarge')}></FontAwesomeIcon>
                                                    <Text
                                                        numberOfLines={1}
                                                        style={{
                                                            marginTop: 10,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                                            color: styling.placement.title_color,
                                                        }}
                                                    >
                                                        {lang.getTranslation(userContext, 'more').toUpperCase()}
                                                    </Text>
                                                </View>
                                            </Pressable>
                                        </View>
                                    )}
                                    <View
                                        style={{ flex: 1, justifyContent: 'center', width: getRailsWidth() }}
                                        focusContext={focusContext}
                                        focusOptions={{
                                            group: `wide-${railIndex}`,
                                            focusKey: `wide-${railIndex}`,
                                            allowFocusOutsideGroup: true,
                                        }}
                                    >
                                        <FlashList
                                            ref={rails}
                                            data={data}
                                            onViewableItemsChanged={onViewableItemsChanged}
                                            estimatedItemSize={height}
                                            drawDistance={height * 2}
                                            horizontal={true}
                                            showsHorizontalScrollIndicator={false}
                                            renderItem={renderItem}
                                            initialScrollIndex={0}
                                            type={'row'}
                                            // extraData={favorite}
                                            style={{ flex: 1 }}
                                            focusOptions={{
                                                autoLayoutScaleAnimation: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? true : false,
                                                autoLayoutSize: getFocusStyling('Rails', appContext).type.indexOf('scale') > -1 && deviceContext.formFactor == 'Television' ? 10 : 0,
                                            }}
                                        />
                                        {deviceContext.isWebTV && !deviceContext.isPwa && data.length > 0 && data.length > viewableItemsCount && (
                                            <GetScrollElement height={height} scrollLeft={scrollLeft} scrollRight={scrollRight} deviceContext={deviceContext} appContext={appContext}></GetScrollElement>
                                        )}
                                    </View>
                                </View>
                            )}
                            {data.length > 0 && styling.content.press_and_hold && styling.content.press_and_hold_message && (
                                <View style={{ alignItems: 'flex-end', marginRight: getCorrectWidth(deviceContext, 5) }}>
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            color: styling.placement.title_color,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'press_and_hold')}
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            )}
            {screenName != 'Search' && data?.length == 0 && (styling.content.type == 'Watched' || styling.content.type == 'Favorites') && (
                <GetBottomPart
                    height={height}
                    width={width}
                    styling={styling}
                    deviceContext={deviceContext}
                    appContext={appContext}
                    screenName={screenName}
                    extraTopBottomMargin={extraTopBottomMargin}
                    focusContext={focusContext}
                    lang={lang}
                    userContext={userContext}
                ></GetBottomPart>
            )}
        </View>
    );
});
export default Widescreen_Rails;
