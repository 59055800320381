import React, { useEffect, useState } from 'react';
import { CreateListRenderItemInfo, FlashList, ScrollView, View, Image as Image_, FocusContext } from '@scriptx-com/xtv-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { isLandscape } from 'react-native-device-info';
import moment from 'moment';
import { useSafeAreaInsets } from '../../../../../libs/react-native-safe-area-context';
import { DeviceContext } from '../../../../context/deviceContext';
import { UserContext } from '../../../../context/userContext';
import { ContentContext } from '../../../../context/contentContext';
import { AppContext } from '../../../../context/appContext';
import Pressable from '../../../components/pressable/pressable';
import Image from '../../../components/image/image';
import Text from '../../../components/text';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getCorrectCategories } from './functions';
import { getFocusStyling, getPressableStyling } from '../../../components/helpers/helper';
import { getFontSize } from '../../../../styling/fontSizes';
import lang from '../../../../languages/languages';
import ProgramModal from './program';
import { BackHandler } from 'react-native';

type MiniEPGTypes = {
    focusContext?: FocusContext;
    navigation: any;
    playChannelIndex: any;
    styling: any;
    onPressPlay: any;
    onPressPurchase: any;
    onPressPlayRecording: any;
    onPressPlayCatchupTV: any;
    onHide: () => void;
};

const MiniEPG = ({ focusContext, navigation, onHide, playChannelIndex, styling, onPressPlay, onPressPurchase, onPressPlayRecording, onPressPlayCatchupTV }: MiniEPGTypes) => {
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const insets = useSafeAreaInsets();

    const [channelEpgSelected, setChannelEpgSelected] = useState([] as any);
    const [programSelectedIndex, setProgramSelectedIndex] = useState(0);
    const [channelSelected, setChannelSelected] = useState([] as any);
    const [programSelected, setProgramSelected] = useState([] as any);
    const [showProgramDetails, setShowProgramDetails] = useState(false);

    const disableNativeHardwareBackPress = () => {
        if (showProgramDetails) {
            global.showProgramDetails = false;
            setShowProgramDetails(false);
            return true;
        } else {
            global.showContentList = false;
            onHide();
            return true;
        }
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        };
    }, [showProgramDetails]);

    useEffect(() => {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == channelSelected._id);
            if (epgAll != undefined) {
                setChannelEpgSelected(epgAll.epg);
            } else {
                setChannelEpgSelected([]);
            }
        } else {
            setChannelEpgSelected([]);
        }
    }, [channelSelected]);

    const getProgramStyling = () => {
        var screen = appContext.application.screens.find((s) => s.type == 'Program Details');
        if (screen) {
            var styling = screen.hero[0].details_top;
            if (styling) {
                return styling;
            }
        }
        return [];
    };

    const openCategory = (index: number) => {
        contentContext.setChannelData({
            ...contentContext.channelData,
            channelList: contentContext.channels.categories[index].channels?.sort((a, b) => a?.number - b?.number),
            channelListCategoryIndex: index,
        });
    };

    const renderProgram = (channel) => {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == channel?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var program = currentItem;
                    var catchuptv = channel?.interactivetv.pausetv_enabled;
                    return (
                        <View>
                            <Text
                                numberOfLines={1}
                                style={{
                                    color: '#ffffff',
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    fontWeight: 'normal',
                                }}
                            >
                                {program.n}
                            </Text>
                            <View style={{ marginTop: 2, flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                                {catchuptv && (
                                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 4) }}>
                                        <Text
                                            style={{
                                                backgroundColor: 'royalblue',
                                                color: appContext.application.theme.tags.color,
                                                borderRadius: appContext.application.theme.tags.radius / 2,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                padding: getCorrectWidth(deviceContext, 2),
                                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                                includeFontPadding: false,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'replay').toUpperCase()}
                                        </Text>
                                    </View>
                                )}
                                {!deviceContext.isPhone && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: '#ffffff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        {moment.unix(program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format('ddd ' + deviceContext.clockSetting)}
                                    </Text>
                                )}
                                {deviceContext.isPhone && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: '#ffffff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        {moment.unix(program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format(deviceContext.clockSetting)}
                                    </Text>
                                )}
                            </View>
                            {!deviceContext.isPhone && (
                                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginRight: 10 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: '#ffffff',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'press_for_details')}
                                    </Text>
                                </View>
                            )}
                        </View>
                    );
                }
            }
        } else {
            return null;
        }
    };
    const renderProgramsFromChannel = ({ item: program, focusRepeatContext }: CreateListRenderItemInfo<any>) => {
        var currentTime = moment().unix();
        var live = program.s <= currentTime && program.e > currentTime;
        var totalProgram = program.e - program.s;
        var progress = -1;
        if (live) {
            progress = ((currentTime - program.s) / totalProgram) * 100;
        }
        return (
            <View style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', marginVertical: getCorrectHeight(deviceContext, 2),        borderRadius: getCorrectWidth(deviceContext, 2) }}>
                <Pressable
                    style={{
                        borderRadius: getCorrectWidth(deviceContext, 2)
                    }}
                    focusOptions={
                        {
                            //  focusKey: 'menu-programs',
                            //  nextFocusLeft: ['menu-channels'],
                        }
                    }
                    hasPreferredFocus={false}
                    animatorOptions={getFocusStyling('Buttons', appContext)}
                    onPress={() => {
                        if (!deviceContext.isPhone) {
                            setProgramSelected(program);
                            global.showProgramDetails = true;
                            setShowProgramDetails(true);
                        }
                    }}
                    focusRepeatContext={focusRepeatContext}
                >
                    <View
                        style={{
                            flexDirection: 'column',
                            margin: 5,
                            borderRadius: getCorrectWidth(deviceContext, 2),
                            height: live && !deviceContext.isPhone ? ((getRealWidth(deviceContext) * 0.4 - 45) / 16) * 9 : live && deviceContext.isPhone ? ((getRealHeight(deviceContext) * 0.4 - 45) / 16) * 9 : null,
                        }}
                    >
                        <View
                            style={{
                                borderRadius: 2,
                                height: live && !deviceContext.isPhone ? ((getRealWidth(deviceContext) * 0.4 - 45) / 16) * 9 : live && deviceContext.isPhone ? ((getRealHeight(deviceContext) * 0.4 - 45) / 16) * 9 : null,
                                flexDirection: 'column',
                                padding: live ? 30 : 0,
                                backgroundColor: live ? 'rgba(0, 0, 0, 0.10)' : 'transparent',
                                zIndex: 2,
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: '#ffffff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Large'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {program.n}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'column' }}>
                                <View style={{ flexDirection: 'column' }}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        {live && (
                                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                                <Text
                                                    style={{
                                                        backgroundColor: 'crimson',
                                                        color: appContext.application.theme.tags.color,
                                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        padding: getCorrectWidth(deviceContext, 2),
                                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                                        includeFontPadding: false,
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'live').toUpperCase()}
                                                </Text>
                                            </View>
                                        )}
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: '#ffffff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {moment.unix(program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(program?.e).format('ddd ' + deviceContext.clockSetting)}
                                        </Text>
                                    </View>
                                    {live && (
                                        <View
                                            style={{
                                                marginTop: 10,
                                                backgroundColor: progress == 0 ? 'transparent' : appContext.application.theme.progresses.base,
                                                height: getCorrectHeight(deviceContext, 2),
                                                width: '100%',
                                                paddingRight: 20,
                                            }}
                                        >
                                            <View
                                                style={{
                                                    backgroundColor: appContext.application.theme.progresses.progress,
                                                    height: getCorrectHeight(deviceContext, 2),
                                                    shadowColor: 'rgba(0, 0, 0, 0.1)',
                                                    shadowOffset: { width: -1, height: 1 },
                                                    shadowRadius: 2,
                                                    width: progress + '%',
                                                }}
                                            ></View>
                                        </View>
                                    )}
                                </View>
                                {!deviceContext.isPhone && (
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginTop: 5,
                                                color: '#ffffff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'press_for_details')}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        </View>
                        {live && (
                            <View style={{ position: 'absolute' }}>
                                <Image_
                                    //isLocal={true}
                                    //isCache={true}
                                    // deviceContext={deviceContext}
                                    source={{ uri: program.m }}
                                    resizeMethod={'scale'}
                                    resizeMode={'cover'}
                                    style={{
                                        borderRadius: getCorrectWidth(deviceContext, 2),
                                        height: ((getRealWidth(deviceContext) * 0.4 - 45) / 16) * 9,
                                        width: getRealWidth(deviceContext) * 0.4 - 35,
                                    }}
                                />
                                <View style={{ position: 'absolute' }}>
                                    <Image_
                                        source={require('../../../../../assets/images/player.png')}
                                        resizeMethod={'scale'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getCorrectWidth(deviceContext, 2),
                                            height: ((getRealWidth(deviceContext) * 0.4 - 45) / 16) * 9,
                                            width: getRealWidth(deviceContext) * 0.4 - 35,
                                        }}
                                    />
                                </View>
                            </View>
                        )}
                    </View>
                </Pressable>
            </View>
        );
    };
    return (
        <View
            focusContext={focusContext}
            style={{
                zIndex: 1000,
                width: deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext, 'screen'),
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                position: 'absolute',
                height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext),
                flexDirection: 'row',
            }}
        >
            {showProgramDetails && (
                <View style={{ position: 'absolute', zIndex: 9999 }}>
                    <ProgramModal
                        focusContext={focusContext}
                        programs={channelEpgSelected}
                        programIndex={programSelectedIndex}
                        program={programSelected}
                        channel={channelSelected}
                        closeModal={() => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                        }}
                        styling={getProgramStyling()}
                        navigation={navigation}
                        onPressPlay={(channel, program, programs) => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                            onPressPlay(channel, program, programs);
                        }}
                        onPressPurchase={() => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                            onPressPurchase();
                        }}
                        onPressPlayRecording={(channel, program, programs) => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                            onPressPlayRecording(channel, program, programs);
                        }}
                        onPressPlayCatchupTV={(channel, program, programs, startType) => {
                            global.showProgramDetails = false;
                            setShowProgramDetails(false);
                            onPressPlayCatchupTV(channel, program, programs, startType);
                        }}
                    ></ProgramModal>
                </View>
            )}
            <View style={{ flex: deviceContext.isPhone ? 1.5 : 1, paddingHorizontal: 10, paddingTop: 10 }}>
                <ScrollView style={{ height: getRealHeight(deviceContext) }} contentContainerStyle={{ alignItems: 'center' }} horizontal={false}>
                    <Pressable
                        focusContext={focusContext}
                        style={{
                            width: '100%',

                            borderRadius: getCorrectWidth(deviceContext, 2),
                            marginVertical: getCorrectHeight(deviceContext, 2),
                        }}
                        key={999999}
                        animatorOptions={getFocusStyling('Buttons', appContext)}
                        onPress={() => onHide()}
                    >
                        <View style={{ flexDirection: 'row', backgroundColor: 'rgba(255, 255, 255, 0.1)' ,        borderRadius: getCorrectWidth(deviceContext, 2)}}>
                            <View
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    height: getCorrectHeight(deviceContext, 30),
                                    marginHorizontal: getCorrectHeight(deviceContext, 10),
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: '#ffffff',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {lang.getTranslation(userContext, deviceContext.isPhone ? 'close' : 'close')}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: getCorrectWidth(deviceContext, 20),
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    //height: getCorrectHeight(deviceContext, 30),
                                    marginHorizontal: getCorrectHeight(deviceContext, 5),
                                    borderRadius: getCorrectWidth(deviceContext, 3),
                                    marginVertical: getCorrectHeight(deviceContext, 2),
                                }}
                            >
                                <FontAwesomeIcon icon={faXmark} color={'#fff'} size={getFontSize(deviceContext, 'Normal')}></FontAwesomeIcon>
                            </View>
                        </View>
                    </Pressable>
                    {getCorrectCategories(contentContext.channels, userContext).map((category, index) => {
                        return (
                            <Pressable
                                focusContext={focusContext}
                                style={{
                                    width: '100%',

                                    borderRadius: getCorrectWidth(deviceContext, 2),
                                    marginVertical: getCorrectHeight(deviceContext, 2),
                                }}
                                key={index}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => openCategory(index)}
                                hasPreferredFocus={contentContext.channelData.channelListCategoryIndex === index}
                            >
                                <View style={{ flexDirection: 'row', backgroundColor: 'rgba(255, 255, 255, 0.1)',        borderRadius: getCorrectWidth(deviceContext, 2) }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'row',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            height: getCorrectHeight(deviceContext, 30),
                                            marginHorizontal: getCorrectHeight(deviceContext, 10),
                                        }}
                                    >
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: '#ffffff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {category.name} ({category.channels.length})
                                        </Text>
                                    </View>
                                    {index === contentContext.channelData.channelListCategoryIndex && (
                                        <View
                                            style={{
                                                width: getCorrectWidth(deviceContext, 20),
                                                flexDirection: 'row',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                //height: getCorrectHeight(deviceContext, 30),
                                                marginRight: getCorrectHeight(deviceContext, 5),
                                                borderRadius: getCorrectWidth(deviceContext, 3),
                                                marginVertical: getCorrectHeight(deviceContext, 2),
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCheck} color={'#fff'} size={getFontSize(deviceContext, 'Normal')}></FontAwesomeIcon>
                                        </View>
                                    )}
                                </View>
                            </Pressable>
                        );
                    })}
                </ScrollView>
            </View>
            <View style={{ flex: deviceContext.isPhone ? 3 : 2, paddingHorizontal: 10, paddingTop: 10, height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext) }}>
                <FlashList
                    type={'row'}
                    horizontal={false}
                    data={contentContext.channelData.channelList}
                    showsVerticalScrollIndicator={false}
                    estimatedItemSize={120}
                    contentContainerStyle={{ paddingBottom: isLandscape ? insets.bottom : 0 }}
                    drawDistance={120 * 2}
                    style={{ flex: 1 }}
                    renderItem={({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
                        return (
                            <Pressable
                                focusContext={focusContext}
                                focusRepeatContext={focusRepeatContext}
                                style={{
                                    width: '100%',

                                    borderRadius: getPressableStyling(styling?.components?.button?._id, appContext).borderRadius ?? 2,
                                    marginVertical: getCorrectHeight(deviceContext, 2),
                                }}
                                hasPreferredFocus={contentContext.channelData.channelIndex === index}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => {
                                    var epgAll = contentContext.epgTv?.find((e) => e.i == item?._id);
                                    if (epgAll != undefined) {
                                        var currentTime = moment().unix();
                                        var currentProgramIndex = epgAll.epg.findIndex((e) => e.s <= currentTime && e.e > currentTime);
                                        if (!deviceContext.isPhone) {
                                            setProgramSelectedIndex(currentProgramIndex);
                                            setChannelSelected(item);
                                        } else {
                                            contentContext.setChannelData({
                                                ...contentContext.channelData,
                                                channelList: contentContext.channels.categories[contentContext.channelData.channelListCategoryIndex].channels,
                                            });
                                            playChannelIndex(index, item);
                                        }
                                    }
                                }}
                                onFocus={() => {
                                    setChannelEpgSelected([]);
                                }}
                            >
                                <View style={{ flex: 1, flexDirection: 'row', padding: 10, backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <View style={{ margin: 10 }}>
                                        <Image
                                            isCache={true}
                                            deviceContext={deviceContext}
                                            source={{ uri: appContext.cloudUrl + item?.images?.square }}
                                            resizeMethod={'scale'}
                                            resizeMode={'cover'}
                                            style={{
                                                height: deviceContext.isPhone ? 50 : 75,
                                                width: deviceContext.isPhone ? 50 : 75,
                                            }}
                                        />
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: '#ffffff',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {item.number}. {item.name}
                                        </Text>
                                        {renderProgram(item)}
                                    </View>
                                </View>
                            </Pressable>
                        );
                    }}
                    initialScrollIndex={contentContext.channelData.channelIndex}
                    initialFocusIndex={contentContext.channelData.channelIndex}
                />
            </View>
            <View style={{ flex: 2, paddingHorizontal: 10, paddingTop: 10, height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext) }}>
                {channelEpgSelected.length > 0 && (
                    <FlashList
                        type={'row'}
                        horizontal={false}
                        data={channelEpgSelected}
                        showsVerticalScrollIndicator={false}
                        estimatedItemSize={55}
                        drawDistance={55 * 2}
                        style={{ flex: 1 }}
                        renderItem={renderProgramsFromChannel}
                        initialScrollIndex={programSelectedIndex}
                        initialFocusIndex={programSelectedIndex}
                    />
                )}
            </View>
        </View>
    );
};

export default MiniEPG;
