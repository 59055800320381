import { ActivityIndicator, ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { faLaptop, faMobile, faTablet, faTelevision, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useFocusEffect } from '@react-navigation/native';
import { isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import Pressable_ from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import { getDevices, updateDevicesJson } from '../../../application/data/account';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import { getFocusOptions } from '../../wrapper/helpers/getFocusOptions';
import Text from '../../../application/builder/components/text';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const [devices, setDevices] = React.useState([] as any);
    const [show, setShow] = React.useState(false);
    const insets = useSafeAreaInsets();

    React.useEffect(() => {
        const fetchData = async () => {
            var devices = await getDevices(appContext, userContext, userContext);
            setDevices(devices);
            setShow(true);
        };
        fetchData();
    }, [navigation, route]);

    const deleteDevice = (index: any, uuid) => {
        if (deviceContext.uniqueId != uuid) setShow(false);
        sendActionReport(appContext, deviceContext, userContext, actionType.DELETE_DEVICE);
        devices.splice(index, 1);
        setDevices(devices);
        userContext.setDevices(userContext.devices);
        updateDevicesJson(appContext, userContext, userContext.devices);
        setTimeout(() => {
            setShow(true);
        }, 1000);
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.DEVICES, duration);
            };
        }, [])
    );

    const focusOptions = getFocusOptions(deviceContext, appContext, 'settings-screen');

    return (
        <ScreenWrapper
            style={{ flex: 1 }}
            focusOptions={{
                ...focusOptions,
                group: 'settings-screen',
            }}
        >
            {!show && (
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <ActivityIndicator size={'large'} color={'#fff'}></ActivityIndicator>
                </View>
            )}
            {show && (
                <View style={{ flex: 1, marginLeft: getCorrectWidth(deviceContext, global.menuWidth) }}>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: appContext.application.theme.settings.background_color1,
                            borderRadius: appContext.application.theme.settings.border_radius,
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.services?.header.title_bold ? 'bold' : 'normal',
                                color: '#ffffff',
                            }}
                        >
                            {lang.getTranslation(userContext, 'your_devices')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.services?.header.sub_size),
                                fontWeight: appContext.application.authentication?.services?.header.sub_bold ? 'bold' : 'normal',
                                color: '#ffffff',
                                textAlign: 'center',
                            }}
                        >
                            {lang.getTranslation(userContext, 'device_info').toUpperCase()}
                        </Text>
                    </View>
                    <View style={{ flex: 4, paddingBottom: getCorrectHeight(deviceContext, deviceContext.isTelevision ? 0 : 30) }}>
                        <ScrollView
                            contentContainerStyle={{
                                alignItems: 'center',
                                paddingBottom: insets.bottom + (isPlatformAndroid ? 40 : isPlatformIos ? 120 : 0),
                            }}
                        >
                            {devices.map((device, index) => {
                                return (
                                    <View
                                        key={index}
                                        style={{
                                            width: getRealWidth(deviceContext) - getCorrectWidth(deviceContext, global.menuWidth + 20),
                                            height: getCorrectHeight(deviceContext, 75),
                                            flexDirection: 'row',
                                            backgroundColor: appContext.application.theme.settings.background_color2,
                                            borderRadius: appContext.application.theme.settings.border_radius,

                                            margin: getCorrectWidth(deviceContext, 10),
                                        }}
                                    >
                                        <View style={{ flex: 1.5, justifyContent: 'center', alignItems: 'center' }}>
                                            {device.formfactor == 'Phone' && (
                                                <FontAwesomeIcon
                                                    color={'#fff'}
                                                    size={getFontSize(deviceContext, 'ExtraExtraLarge')}
                                                    icon={faMobile}
                                                />
                                            )}
                                            {device.formfactor == 'Tablet' && (
                                                <FontAwesomeIcon
                                                    color={'#fff'}
                                                    size={getFontSize(deviceContext, 'ExtraExtraLarge')}
                                                    icon={faTablet}
                                                />
                                            )}
                                            {device.formfactor == 'Desktop' && (
                                                <FontAwesomeIcon
                                                    color={'#fff'}
                                                    size={getFontSize(deviceContext, 'ExtraExtraLarge')}
                                                    icon={faLaptop}
                                                />
                                            )}
                                            {device.formfactor == 'Television' && (
                                                <FontAwesomeIcon
                                                    color={'#fff'}
                                                    size={getFontSize(deviceContext, 'ExtraExtraLarge')}
                                                    icon={faTelevision}
                                                />
                                            )}
                                        </View>
                                        <View style={{ flex: 5, flexDirection: 'column', justifyContent: 'center' }}>
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {device.model}
                                            </Text>
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    color: appContext.application.theme.settings.font_color,
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                }}
                                            >
                                                {device.uuid}
                                            </Text>
                                        </View>
                                        <View style={{ flex: 1.5, justifyContent: 'center', alignItems: 'center' }}>
                                            <Pressable_
                                                style={{ borderRadius: 5, padding: getCorrectHeight(deviceContext, 10) }}
                                                animatorOptions={{
                                                    type: 'border',
                                                    focus: {
                                                        scale: 1.1,
                                                        duration: 150,
                                                        borderColor: '#ffffff',
                                                        borderWidth: 2,
                                                    },
                                                }}
                                                onPress={() => deleteDevice(index, device.uuid)}
                                            >
                                                <FontAwesomeIcon color={'#fff'} size={getFontSize(deviceContext, 'ExtraLarge')} icon={faTrash} />
                                            </Pressable_>
                                        </View>
                                    </View>
                                );
                            })}
                        </ScrollView>
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
