import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faLock, faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { isFactorTv, isPlatformWeb } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import { Pressable as RNPressable, View as RNVIew } from 'react-native';
import { Animated, CoreManager, Image as Image_, Pressable, View } from '@scriptx-com/xtv-toolkit';
import { Ratio } from '../../../../screens/wrapper/helpers/getFocusOptions';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { PlayerContext } from '../../../context/playerContext';
import { UserContext } from '../../../context/userContext';
import { favoriteStatus, watchingStatus } from '../../../data/account';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import lang from '../../../languages/languages';
import { InteractiveTV } from '../../../models/content/shared/interactivetv';
import { Translations } from '../../../models/content/shared/translations';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
//import Image from '../../components/image/image';
import TheoPlayer from '../../components/players/theoPlayer';
import Text from '../../components/text';
import { checkParentalLockShouldApply, getLiveImage, getTimedImage } from '../players/helpers/functions';
import { getLiveTag, getReplayTag } from '../players/helpers/helpers';
import { getDrm, getToken } from '../players/helpers/security';
import { getStreamParams } from '../players/helpers/stream';


export const EXPANDABLE_WIDTH = Ratio(300);
const AnimatedView = Animated.createAnimatedComponent(View);
const AnimatedPressable = Animated.createAnimatedComponent(isPlatformWeb ? RNPressable : Pressable);
export interface Program {
    n: string;
    s: any;
    e: any;
}

export interface Data {
    name: string;
    _id: string;
    number?: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    catchuptv?: boolean;
    interactivetv?: InteractiveTV;
    progress?: number;
    program?: Program;
    translations?: Translations;
    streams?: any;
    parental?: any;
}

export interface Props {
    fontDensity?: number;
    focusContext?: any;
    focusRepeatContext?: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    index: number;
    rails?: boolean;
    onLongPress: any;
    focusOptions?: any;
}

const TIMEOUT_MS = 1000;

export const Player = ({ fontDensity, focusContext, focusRepeatContext, noMargin, height, width, onPress, styling, type, data, index, onLongPress, focusOptions }: Props) => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const contentContext = React.useContext(ContentContext);
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const screenName = getCurrentScreenName();
    const [showPlayer, setShowplayer] = React.useState(false);

    const animatedWidth = React.useRef(new Animated.Value(width)).current;
    const pressableRef = React.useRef<RNVIew>(null);
    const viewRef = React.useRef<RNVIew>(null);
    const timeout = React.useRef<NodeJS.Timeout>();

    if (fontDensity == undefined) {
        fontDensity = 1;
    }
    var favorite = favoriteStatus(type, data?._id, userContext);
    var progress = -1;
    var parentalIsApplied = false;

    const startPlayer = async (data) => {
        var stream = '' as any;
        var progress = 0 as any;
        switch (type) {
            case 'Channels':
                if (!data?.parental?.enabled) {
                    if (deviceContext.isSmartTV || deviceContext.isTizen || deviceContext.isWebos || deviceContext.isWebTV || deviceContext.isPwa) {
                        stream = await getToken(data, playerContext.tk, data.streams.channels?.urls.tizen_webos, deviceContext, userContext);
                    } else if (deviceContext.isIos || deviceContext.isAppleTV) {
                        stream = await getToken(data, playerContext.tk, data.streams.channels?.urls.ios_tvos, deviceContext, userContext);
                    } else {
                        stream = await getToken(data, playerContext.tk, data.streams.channels?.urls.standard_url, deviceContext, userContext);
                    }
                    await getDrm(data, data._id, 'channels', appContext.application, deviceContext, playerContext);
                    stream = getStreamParams(stream, data.name, data._id, 'Channels', deviceContext, userContext, appContext, data.streams.params);
                    playerContext.setStream({ url: stream });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: true,
                        type: 'Channels',
                        error: '',
                    });
                    setShowplayer(true);
                    playerContext.setPaused(false);
                }
                break;

            case 'Movies':
                if (!data?.parental?.enabled) {
                    stream = data?.streams?.movies?.trailer != undefined ? data?.streams?.movies?.trailer : data?.streams?.movies?.url ?? '';
                    var watching = watchingStatus('Movies', data._id, userContext);
                    var drm = await getDrm(data, data._id, 'movies', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }

                    playerContext.setStream({
                        url: stream,
                        progress: watching != undefined && watching.progress != 0 ? watching.position : 0, //TODO:test this
                    });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Movies',
                        error: '',
                    });
                    setShowplayer(true);
                    playerContext.setPaused(false);
                }
                break;
            case 'Series':
                if (!data?.parental?.enabled) {
                    stream = contentContext.seriesData.episode?.streams?.series?.url;
                    var test = userContext.profile.watchings.series.find((w) => w._id == data._id);
                    if (test != undefined) {
                        var episode = test.find((e) => e._id == contentContext.seriesData.episode._id);
                        if (episode != undefined) {
                            progress = episode.position;
                        }
                    }
                    var drm = await getDrm(data, data._id, 'cams', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    stream = getStreamParams(stream, contentContext.seriesData.episode.name, contentContext.seriesData.episode._id, 'Series', deviceContext, userContext, appContext, contentContext.seriesData.episode.streams.params);
                    playerContext.setStream({
                        url: stream,
                        progress: progress != undefined && progress != 0 ? progress.continue.position : 0,
                    });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Series',
                        error: '',
                    });
                    setShowplayer(true);
                    playerContext.setPaused(false);
                }
                break;
            case 'Courses':
                if (!data?.parental?.enabled) {
                    stream = contentContext.courseData.lesson?.streams?.courses?.url;
                    var test = userContext.profile.watchings.courses.find((w) => w._id == data._id);
                    if (test != undefined) {
                        var lesson = test.find((e) => e._id == contentContext.courseData.lesson._id);
                        if (lesson != undefined) {
                            progress = lesson.position;
                        }
                    }
                    var drm = await getDrm(data, data._id, 'courses', appContext.application, deviceContext, playerContext);
                    if (drm != null) {
                        playerContext.setDrm(drm);
                    }
                    stream = getStreamParams(stream, data.name, data._id, 'Courses', deviceContext, userContext, appContext, data.streams.params);
                    playerContext.setStream({
                        url: stream,
                        progress: progress != undefined && progress != 0 ? progress.continue.position : 0,
                    });
                    playerContext.setPlayer({
                        ...playerContext.player,
                        paused: false,
                        isLive: false,
                        type: 'Courses',
                        error: '',
                    });
                    setShowplayer(true);
                    playerContext.setPaused(false);
                }
                break;
        }
    };

    if (type == 'Seasons') {
        var watching = watchingStatus(type, contentContext.seriesData.series._id, userContext);
        if (watching != undefined && watching.position != undefined) {
            var episode = watching.continue.episodes.find((e) => e._id == data?._id);
            if (episode != undefined) {
                progress = (episode.position / episode.duration) * 100;
            } else {
                progress = 0;
            }
        } else {
            progress = 0;
        }
    }
    if (type == 'Course' && watching != undefined) {
        var watching = watchingStatus(type, contentContext.courseData.course._id, userContext);

        if (watching != undefined && watching.position != undefined) {
            var lesson = watching.lessons.find((e) => e._id == data?._id);
            if (lesson != undefined) {
                progress = (lesson.position / lesson.duration) * 100;
            } else {
                progress = 0;
            }
        } else {
            progress = 0;
        }
    }
    if (type == 'Channels') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e >= currentTime);

                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;

                    parentalIsApplied = checkParentalLockShouldApply(userContext, contentContext, currentItem, data);
                } else {
                    progress = 0;
                }
            } else {
                progress = 0;
            }
        }
    }
    if (screenName == 'Cams') {
        var epgAll1 = contentContext.epgCam.find((e) => e.i == data?._id);
        if (epgAll1 != undefined) {
            var currentTime = moment().unix();
            var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
            if (currentItem != undefined) {
                var totalProgram = currentItem.e - currentItem.s;
                var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                data.program = currentItem;
                progress = percentageProgram;
            } else {
                progress = 0;
            }
        } else {
            progress = 0;
        }
    }

    if (screenName == 'Channel_Player') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;

                    parentalIsApplied = checkParentalLockShouldApply(userContext, contentContext, currentItem, data);
                }
            } else {
                progress = 0;
            }
        }
    }
    if (screenName == 'Channels') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;

                    parentalIsApplied = checkParentalLockShouldApply(userContext, contentContext, currentItem, data);
                }
            } else {
                progress = 0;
            }
        }
    }
    if (screenName == 'Radio') {
        if (contentContext.epgRadio[0] != undefined) {
            var epgAll = contentContext.epgRadio.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;
                }
            } else {
                progress = 0;
            }
        }
    }
    var recordingSet = false;
    var recordingPlanned = false;
    if (screenName == 'Recordings') {
        var currentTime = moment().unix();
        if (currentTime > data?.program?.e) {
            recordingSet = false;
        } else if (currentTime > data?.program?.s && currentTime < data?.program?.e) {
            recordingSet = true;
        } else {
            recordingSet = false;
            recordingPlanned = true;
        }
    }

    const getCorrectImage = (data: any) => {
        if (screenName == 'Channels' || screenName == 'Cams' || type == 'Channels' || type == 'Cams') {
            if (screenName != 'Cams' && type != 'Cams' && data?.program?.m?.indexOf('https://') > -1) {
                return data?.program?.m;
            } else {
                if (screenName == 'Channels' || type == 'Channels') {
                    return getLiveImage(data?.streams.channels.urls.standard_url);
                }
                if (screenName == 'Cams' || type == 'Cams') {
                    return getLiveImage(data?.streams.cams.urls.standard_url);
                }
            }
        } else if (screenName == 'Recordings') {
            if (data?.program?.m != '') {
                return data?.program?.m;
            } else {
                return getTimedImage(data?.channel.streams.channels.urls.standard_url, data?.program?.s);
            }
        } else {
            if (data?.images?.widescreen.indexOf('https://') > -1) {
                return data?.images?.widescreen;
            } else {
                return appContext.cloudUrl + data?.images?.widescreen;
            }
        }
    };
    const getRibbonText = () => {
        var test = styling.ribbon.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.title != undefined) {
                return styling.content.title[0].title;
            } else {
                return '';
            }
        }
    };
    const getExtraStyle = () => {
        if (styling.ribbon.align == 'Right') {
            return { right: -2 };
        } else {
            return { left: -2 };
        }
    };

    const getCorrectTranslation = (translations) => {
        return translations.find((trans) => trans.name == userContext.selectedLanguage)?.description;
    };
    const focusWidth = () => {
        return getPressableStyling(styling.components.button._id, appContext).borderWidth * 2;
    };

    const animate = React.useCallback((animationValue) => {
        Animated.timing(animatedWidth, {
            toValue: animationValue,
            duration: 500,
            useNativeDriver: false,
        }).start();
    }, []);

    const onFocus = (index) => {
        timeout.current = setTimeout(() => {
            startPlayer(data);
        }, TIMEOUT_MS);
    };

    React.useEffect(() => {
        animatedWidth.addListener(({ value }) => {
            if (isFactorTv) {
                CoreManager.setViewIsAnimating(true);
                if (Math.round(value) === Math.round(width)) {
                    CoreManager.setViewIsAnimating(false);
                }
            }

            if (pressableRef.current) {
                pressableRef.current.setNativeProps({
                    style: {
                        width: value,
                    },
                });
            }
            if (viewRef.current) {
                viewRef.current.setNativeProps({
                    style: {
                        width: value - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - getCorrectWidth(deviceContext, 5) - focusWidth(),
                    },
                });
            }
        });
    }, [pressableRef]);

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        setShowplayer(false);
    };

    if (data == undefined) {
        return null;
    } else {
        return (
            <View
                style={{
                    backgroundColor: appContext.application.theme.images?.background,
                    margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                    borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius),
                }}
            >
                <AnimatedPressable
                    ref={pressableRef}
                    style={[
                        {
                            ...getPressableStyling(styling.components.button._id, appContext),
                            paddingHorizontal: getCorrectWidth(deviceContext, 2.5),
                            width: width,
                        },
                    ]}
                    onHoverIn={() => onFocus(data)}
                    onHoverOut={() => onBlur()}
                    onFocus={() => onFocus(data)}
                    onBlur={() => onBlur()}
                    focusContext={focusContext}
                    onLongPress={() => onLongPress(data?._id)}
                    focusRepeatContext={focusRepeatContext}
                    animatorOptions={getFocusStyling('Buttons', appContext)}
                    onPress={() => (screenName == 'Recordings' ? onPress(data) : onPress(data?._id))}
                    focusOptions={{
                        ...focusOptions,
                        animator: getFocusStyling('Buttons', appContext),
                    }}
                >
                    {styling.ribbon != undefined && styling.ribbon.show_ribbon && (
                        <View style={[getExtraStyle(), { position: 'absolute', zIndex: 9999 }]}>
                            <View
                                style={{
                                    padding: getCorrectWidth(deviceContext, 3),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: styling.ribbon.background_color,
                                    borderRadius: getCorrectHeight(deviceContext, styling.ribbon.border_radius),
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.ribbon.title_color,
                                        fontWeight: styling.ribbon.title_bold ? 'bold' : 'normal',
                                    }}
                                >
                                    {getRibbonText()}
                                </Text>
                            </View>
                        </View>
                    )}
                    <View
                        style={{
                            paddingTop: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius),
                            width: width,
                        }}
                    >
                        <AnimatedView
                            ref={viewRef}
                            style={{
                                alignItems: 'center',
                                height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                            }}
                        >
                            {!parentalIsApplied && showPlayer && (
                                <View
                                    style={{
                                        height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                        width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),

                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            position: 'absolute',
                                            height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),

                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            alignSelf: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <Image_
         
                                            deviceContext={deviceContext}
                                            source={{ uri: getCorrectImage(data) }}
                                            resizeMethod={'resize'}
                                            resizeMode={'cover'}
                                            style={{
                                                borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                                height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                                width: '100%',
                                            }}
                                        />
                                    </View>
                                    <View
                                        style={{
                                            height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                            width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),

                                            alignItems: 'center',
                                        }}
                                    >
                                        {showPlayer && (
                                            <TheoPlayer
                                                adsEnabled={false}
                                                castEnabled={false}
                                                style={{
                                                    borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 2,
                                                    objectFit: 'cover',
                                                    height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                                    width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                                }}
                                            />
                                        )}
                                    </View>
                                </View>
                            )}
                            {!parentalIsApplied && !showPlayer && (
                                <Image_
      
                                    deviceContext={deviceContext}
                                    source={{ uri: getCorrectImage(data) }}
                                    resizeMethod={'resize'}
                                    resizeMode={'cover'}
                                    style={{
                                        borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                        height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                        width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2),
                                    }}
                                />
                            )}
                            {screenName == 'Recordings' && (
                                <View style={{ position: 'absolute', top: 0, right: 0 }}>
                                    {recordingSet && (
                                        <>
                                            <View style={{ flexDirection: 'row' }}>
                                                <View style={{ justifyContent: 'center' }}>
                                                    <View
                                                        style={{
                                                            borderRadius: 100,
                                                            backgroundColor: 'crimson',
                                                            padding: getCorrectWidth(deviceContext, 5),
                                                        }}
                                                    ></View>
                                                </View>
                                                <Text
                                                    style={{
                                                        margin: getCorrectWidth(deviceContext, 5),
                                                        color: '#fff',
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {lang.getTranslation('recording')}
                                                </Text>
                                            </View>
                                        </>
                                    )}
                                    {recordingPlanned && (
                                        <>
                                            <View style={{ flexDirection: 'row' }}>
                                                <View style={{ justifyContent: 'center' }}>
                                                    <View
                                                        style={{
                                                            borderRadius: 100,
                                                            backgroundColor: 'crimson',
                                                            padding: getCorrectWidth(deviceContext, 5),
                                                        }}
                                                    ></View>
                                                </View>
                                                <Text
                                                    style={{
                                                        margin: getCorrectWidth(deviceContext, 5),
                                                        color: '#fff',
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {lang.getTranslation('planned')}
                                                </Text>
                                            </View>
                                        </>
                                    )}
                                </View>
                            )}
                            {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && styling.texts.placement_text == 'Below' && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        top: 10,
                                        left: 12,
                                        width: '100%',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Image_
          
                                        deviceContext={deviceContext}
                                        source={{ uri: appContext.cloudUrl + data?.images?.square }}
                                        resizeMethod={'resize'}
                                        resizeMode={'cover'}
                                        style={{
                                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 4,
                                            height: getCorrectWidth(deviceContext, 30),
                                            width: getCorrectWidth(deviceContext, 30),
                                        }}
                                    />
                                </View>
                            )}
                            {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && appContext.application.theme.icons.show_live && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        bottom: getCorrectWidth(deviceContext, appContext.application.theme.images.margin) + 5,
                                        right: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                        flexDirection: 'row',
                                    }}
                                >
                                    <>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</>
                                    {data?.catchuptv && <>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</>}
                                </View>
                            )}

                            {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams') && appContext.application.theme.icons.show_favorite && favorite && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        top: 5,
                                        right: 5,
                                        width: '100%',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <View
                                        style={{
                                            backgroundColor: '#333',
                                            borderRadius: 100,
                                            padding: getCorrectHeight(deviceContext, 3),
                                            marginLeft: getCorrectWidth(deviceContext, 3),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faHeart} color={'#fff'} size={getFontSize(deviceContext, 'Normal')} />
                                    </View>
                                </View>
                            )}
                            {type != 'Channels' && screenName != 'Channels' && type != 'Cams' && screenName != 'Cams' && appContext.application.theme.icons.show_favorite && favorite && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        top: 5,
                                        right: 5,
                                        width: '100%',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <View
                                        style={{
                                            backgroundColor: '#333',
                                            borderRadius: 100,
                                            padding: getCorrectHeight(deviceContext, 3),
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faHeart} color={'#fff'} size={getFontSize(deviceContext, 'Normal')} />
                                    </View>
                                </View>
                            )}
                            {parentalIsApplied && (
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: height - appContext.application.theme.images.margin * 2 - getPressableStyling(styling.components.button._id, appContext).borderWidth * 2,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faLock} color={'#999'} size={getFontSize(deviceContext, 'ExtraLarge')}></FontAwesomeIcon>
                                </View>
                            )}
                            {appContext.application.theme.icons.show_play && styling.content.styling.content.play_directly && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 0,
                                        left: 0,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            borderRadius: 100,
                                            height: width / 7,
                                            width: width / 7,
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            size={width / 15}
                                            style={{
                                                color: appContext.application.theme.icons.background,
                                            }}
                                            icon={faPlay}
                                        />
                                    </View>
                                </View>
                            )}
                            {styling.texts.placement_text == 'Above' && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        bottom: appContext.application.theme.images.margin / 2,
                                        left: appContext.application.theme.images.margin,
                                        right: appContext.application.theme.images.margin * 2,
                                    }}
                                >
                                    {data?.number != undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                                // width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3),
                                                color: styling.texts.title_color,
                                                fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                marginLeft: getCorrectHeight(deviceContext, 5),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data?.number}. {data?.name}
                                        </Text>
                                    )}
                                    {data?.number == undefined && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                                //width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3) - 20,
                                                color: styling.texts.title_color,
                                                fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                marginBottom: getCorrectHeight(deviceContext, 5),
                                                marginLeft: getCorrectHeight(deviceContext, 5),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data?.name}
                                        </Text>
                                    )}
                                    {progress > -1 && (
                                        <View
                                            style={{
                                                marginRight: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                                marginVertical: getCorrectHeight(deviceContext, 2),
                                                backgroundColor: progress == 0 ? 'transparent' : appContext.application.theme.progresses.base,
                                                height: getCorrectHeight(deviceContext, 2),
                                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                shadowOffset: { width: -1, height: 1 },
                                                shadowRadius: 2,
                                            }}
                                        >
                                            <View
                                                style={{
                                                    backgroundColor: appContext.application.theme.progresses.progress,
                                                    height: getCorrectHeight(deviceContext, 2),
                                                    shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                    shadowOffset: { width: -1, height: 1 },
                                                    shadowRadius: 2,

                                                    width: progress + '%',
                                                }}
                                            ></View>
                                        </View>
                                    )}
                                    {data?.program != undefined && (
                                        <>
                                            {!parentalIsApplied && (
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                                        //  width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3) - 20,
                                                        color: styling.texts.subtext_color,
                                                        fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Small'),
                                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                                        marginLeft: getCorrectHeight(deviceContext, 5),
                                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                        textShadowOffset: { width: -1, height: 1 },
                                                        textShadowRadius: 2,
                                                    }}
                                                >
                                                    {data?.program.n}
                                                </Text>
                                            )}
                                            {parentalIsApplied && (
                                                <Text
                                                    numberOfLines={1}
                                                    style={{
                                                        marginHorizontal: getCorrectHeight(deviceContext, appContext.application.theme.images.margin + 10),
                                                        //  width: width - getCorrectHeight(deviceContext, appContext.application.theme.images.margin * 3),
                                                        color: styling.texts.subtext_color,
                                                        fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        marginBottom: getCorrectHeight(deviceContext, 5),
                                                        marginLeft: getCorrectHeight(deviceContext, 5),
                                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                        textShadowOffset: { width: -1, height: 1 },
                                                        textShadowRadius: 2,
                                                    }}
                                                >
                                                    ***********
                                                </Text>
                                            )}
                                        </>
                                    )}
                                </View>
                            )}
                        </AnimatedView>

                        {styling.texts.placement_text == 'Below' && (
                            <View
                                style={{
                                    marginTop: appContext.application.theme.images.margin > 0 ? 0 : getCorrectHeight(deviceContext, 5),
                                    marginHorizontal: appContext.application.theme.images.margin > 0 ? getCorrectWidth(deviceContext, appContext.application.theme.images.margin) : getCorrectHeight(deviceContext, 5),
                                    marginBottom: getCorrectHeight(deviceContext, 5),
                                }}
                            >
                                {data?.number != undefined && (
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.title_color,
                                                fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Large'),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data?.number}. {data?.name}
                                        </Text>
                                    </View>
                                )}
                                {data?.number == undefined && (
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            color: styling.texts.title_color,
                                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Large'),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {data?.name}
                                    </Text>
                                )}

                                {progress > -1 && (
                                    <View
                                        style={{
                                            marginVertical: getCorrectHeight(deviceContext, 2),
                                            marginRight: getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                            backgroundColor: appContext.application.theme.progresses.base,
                                            height: getCorrectHeight(deviceContext, 2),
                                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                                            shadowOffset: { width: -1, height: 1 },
                                            shadowRadius: 2,
                                        }}
                                    >
                                        <View
                                            style={{
                                                backgroundColor: appContext.application.theme.progresses.progress,
                                                height: getCorrectHeight(deviceContext, 2),
                                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                shadowOffset: { width: -1, height: 1 },
                                                shadowRadius: 2,

                                                width: progress + '%',
                                            }}
                                        ></View>
                                    </View>
                                )}

                                {data?.translations != undefined && (
                                    <Text
                                        numberOfLines={2}
                                        style={{
                                            color: styling.texts.title_color,
                                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {getCorrectTranslation(data?.translations)}
                                    </Text>
                                )}
                                {data?.translations == undefined && data?.program == undefined && (
                                    <>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.texts.title_color,
                                                fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {' '}
                                        </Text>
                                        <Text
                                            numberOfLines={2}
                                            style={{
                                                color: styling.texts.title_color,
                                                fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {' '}
                                        </Text>
                                    </>
                                )}
                                {data?.program != undefined && (
                                    <>
                                        {!parentalIsApplied && (
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    color: styling.texts.subtext_color,
                                                    fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                    textShadowOffset: { width: -1, height: 1 },
                                                    textShadowRadius: 2,
                                                }}
                                            >
                                                {data?.program.n}
                                            </Text>
                                        )}
                                        {parentalIsApplied && (
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    color: styling.texts.subtext_color,
                                                    fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                    textShadowOffset: { width: -1, height: 1 },
                                                    textShadowRadius: 2,
                                                }}
                                            >
                                                ***********
                                            </Text>
                                        )}

                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                color: styling.texts.subsubtext_color,
                                                fontWeight: styling.texts.subsubtext_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {moment.unix(data?.program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(data?.program?.e).format('ddd ' + deviceContext.clockSetting)}
                                        </Text>
                                    </>
                                )}
                            </View>
                        )}
                    </View>
                </AnimatedPressable>
            </View>
        );
    }
};
export default Player;
