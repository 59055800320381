import { Image, Screen, ScrollView, View } from '@scriptx-com/xtv-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { CommonActions } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import Pressable from '../builder/components/pressable/pressable';
import { AppContext } from '../context/appContext';
import { DeviceContext } from '../context/deviceContext';
import { SettingsContext } from '../context/settingsContext';
import { UserContext } from '../context/userContext';
import LANG from '../languages/languages';
import Stacks from '../stacks/StackSelector';
import { getCorrectHeight, getCorrectWidth } from '../styling/correctSizes';
import { getFontSize } from '../styling/fontSizes';
import { getMenuIcon } from '../styling/menuIcon';
import utils from '../general/utils';
import Text from '../builder/components/text';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../builder/components/helpers/helper';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { colorShade } from '../styling/colorShade';
import { ContentContext } from '../context/contentContext';
import { getNativeBackPress } from '../../screens/wrapper/helpers/getNativeBackPress';
import { ActivityIndicator, BackHandler, Keyboard, Platform } from 'react-native';
import { AddCachingTaskEvent, CacheEventType, MediaCache } from 'react-native-theoplayer';
import { CachingTaskView } from '../builder/components/downloadbutton/downloadbutton';
import { checkIfKnownMenuItem, checkIfMenuHasWebview } from './helpers/helpers';

const Tab = createBottomTabNavigator();

function MyTabBar({ state, descriptors, navigation }) {
    var menu = '';
    var styling = [] as any;
    var addTaskEvent = undefined;
    const settingsContext = React.useContext(SettingsContext);
    const appContext = React.useContext(AppContext);
    const contextUser = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const [activeSettings, setActiveSettings] = useState('');
    const [visible, setVisible] = useState(true);
    const [task, setTask] = useState([] as any);
    const [downloadStatus, setDownloadStatus] = useState('false');

    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.browser;
    } else if (deviceContext.isTablet) {
        styling = appContext.application.navigators?.tablet;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.mobile;
    } else if (deviceContext.isTelevision) {
        styling = appContext.application.navigators?.television;
    }

    React.useEffect(() => {
        addTaskEvent = MediaCache.addEventListener(CacheEventType.addtask, (event: AddCachingTaskEvent) => {
            if (event.task.status == 'idle') {
                setTask(event.task);
                setDownloadStatus('loading');
            }
        });
        return () => {
            if (addTaskEvent) {
                MediaCache.removeEventListener(CacheEventType.addtask, addTaskEvent);
            }
        };
    }, []);

    global.menuWidth = 0;
    global.menuHeight = 50;
    const tabsToForward = ['Manual', 'Subscription', 'Messages', 'Support', 'About', 'Devices', 'Updates', 'Disclaimer', 'Channels', 'TV Guide', 'Radio', 'Recordings', 'Cams', 'Movies', 'Shorts', 'Series', 'Music', 'Podcasts', 'Courses'];

    React.useEffect(() => {
        let keyboardEventListeners = [];
        if (Platform.OS === 'android') {
            keyboardEventListeners = [Keyboard.addListener('keyboardDidShow', () => setVisible(false)), Keyboard.addListener('keyboardDidHide', () => setVisible(true))];
        }

        return () => {
            keyboardEventListeners.forEach((eventListener) => eventListener.remove());
        };
    }, []);

    React.useEffect(() => {
        const currentNavState = state?.routes?.[0]?.state?.routes?.[0]?.state;
        if (currentNavState) {
            const routeName = currentNavState.routes[currentNavState.index]?.name;
            if (routeName && contentContext.activeMenu !== routeName) {
                contentContext.setActiveMenu(routeName);
            }
        }
    }, [state.routes]);

    React.useEffect(() => {
        const backAction = () => {
            if (!getNativeBackPress()) {
                if (tabsToForward.includes(contentContext.activeMenu)) {
                    contentContext.setActiveMenu('Home');
                    setActiveSettings('');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Home' }],
                        })
                    );
                }
                return true;
            } else {
                return false;
            }
        };
        const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

        settingsContext.setTabBarHeight(getCorrectHeight(deviceContext, 40));
        return () => backHandler.remove();
    }, [navigation, contentContext.activeMenu]);

    const onPress = (menu: any) => {
        if (menu != 'Account' && activeSettings == '') {
            if (checkIfKnownMenuItem(menu)) {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            } else {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Custom' }],
                    })
                );
            }
        } else {
            contentContext.setActiveMenu(menu);
            if (menu == 'Back') {
                contentContext.setActiveMenu('Home');
                setActiveSettings('');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Home' }],
                    })
                );
            } else if (menu == 'Account') {
                contentContext.setActiveMenu('Manual');
                setActiveSettings(menu);
                navigation.dispatch(
                    CommonActions.navigate({
                        name: 'Manual',
                    })
                );
            } else if (menu == 'Switch Profile') {
                contentContext.setActiveMenu('Home');
                setActiveSettings('');
                settingsContext.setShowMainMenu(false);
                navigation.dispatch(
                    CommonActions.navigate({
                        name: 'Profiles',
                    })
                );
            } else if (menu == 'Logout') {
                settingsContext.setShowMainMenu(false);
                setActiveSettings('');

                contextUser.setAutoLogin(false);
                utils.storeJson('AutoLogin', false);

                contextUser.setAllProfiles([]);

                if (appContext.application.settings.enable_delete_userdata == true || deviceContext.isSmartTV == true) {
                    contextUser.setAppId('');
                    utils.storeJson('ServiceID', '');

                    contextUser.setUserId('');
                    utils.storeJson('UserID', '');
                    contextUser.setPass('');
                    utils.storeJson('Pass', '');
                }

                if (appContext.application.settings?.enable_nested == true) {
                    contentContext.setActiveMenu('Services');
                    navigation.dispatch(
                        CommonActions.navigate({
                            name: 'Services',
                        })
                    );
                } else if (appContext.application?.settings.enable_anonymous == true) {
                    contentContext.setActiveMenu('Anonymous');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Anonymous' }],
                        })
                    );
                } else {
                    contentContext.setActiveMenu('Login');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Login' }],
                        })
                    );
                }
            } else if (menu == 'Refresh') {
                setActiveSettings('');
                settingsContext.setShowMainMenu(false);
                contentContext.setActiveMenu('Loader');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Loader' }],
                    })
                );
            } else {
                setActiveSettings(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            }
        }
    };

    var menuArray = [] as any;
    if (activeSettings == '') {
        menuArray = [{ name: 'account', menu: 'Account' }];
        appContext.application.menus.forEach((element) => {
            if (
                element.name != 'Logout' &&
                element.name != 'Services' &&
                element.name != 'Disclaimer' &&
                element.name != 'Signin' &&
                element.name != 'Forgot' &&
                element.name != 'Check' &&
                element.name != 'Player' &&
                element.name != 'Profiles' &&
                element.name != 'Languages' &&
                element.name != 'Settings' &&
                element.name != 'Messages' &&
                element.name != 'Loader' &&
                element.name != 'Support'
            ) {
                if (
                    (contextUser.userId.indexOf('_review') > -1 && element.name == 'Search') ||
                    (contextUser.userId.indexOf('_review') > -1 && element.name == 'Downloads') ||
                    (contextUser.userId.indexOf('_review') > -1 && element.name == 'Recordings') ||
                    (!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && element.name == 'Downloads') ||
                    (element.name == 'Games' && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet) ||
                    (checkIfMenuHasWebview(appContext,element.name) && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet)
                ) {
                    //do nothing
                } else {
                    var menu_ = { name: element.name, menu: element.name };
                    menuArray.push(menu_);
                }
            }
        });

        var first = 'Search';
        menuArray.sort(function (x, y) {
            return x.menu == first ? -1 : y.menu == first ? 1 : 0;
        });

        var first = 'Account';
        menuArray.sort(function (x, y) {
            return x.menu == first ? -1 : y.menu == first ? 1 : 0;
        });
    } else {
        menuArray.push({ name: 'back', menu: 'Back' });
        if (appContext.application.settings.enable_profiles != false) {
        menuArray.push({ name: 'switch_profile', menu: 'Switch Profile' });
        }
        if (appContext.application.settings.show_info_manual) {
        menuArray.push({ name: 'manual', menu: 'Manual' });
        }
        if (appContext.application.settings.enable_register) {
            menuArray.push({ name: 'account', menu: 'Registration' });
        }

        if (appContext.application.settings.show_info_subscription && contextUser.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'subscription', menu: 'Subscription' });
        }

        if (appContext.application.settings.show_info_messages && contextUser.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'messages', menu: 'Messages' });
        }
        // if (appContext.application.settings.show_info_renewal && contextUser.userId.indexOf('_review') < 0) {
        //     menuArray.push({ name: 'renewal', menu: 'Renewal' });
        // }

        if (appContext.application.settings.show_info_support && contextUser.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'support', menu: 'Support' });
        }
        if (appContext.application.settings.show_info_apps && global.Cart != undefined && contextUser.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'apps', menu: 'Apps' });
        }
        if (appContext.application.settings.show_info_about) {
            menuArray.push({ name: 'about', menu: 'About' });
        }
        if (deviceContext.isPwa) {
            menuArray.push({ name: 'pwa_howto', menu: 'Pwa_' });
        }
        if (appContext.application.settings.show_info_devices) {
            menuArray.push({ name: 'devices', menu: 'Devices' });
        }
        if (appContext.application.settings.show_info_updates && contextUser.userId.indexOf('_review') < 0 && (deviceContext.isAndroid || deviceContext.isAndroidTV || deviceContext.isFireTV)) {
            menuArray.push({ name: 'updates', menu: 'Updates' });
        }
        if (appContext.application.settings.show_info_refresh) {
            menuArray.push({ name: 'refresh', menu: 'Refresh' });
        }
        if (appContext.application.settings.show_info_disclaimer && !deviceContext.isKaiOs && contextUser.userId.indexOf('_review') < 0) {
            menuArray.push({ name: 'disclaimer', menu: 'Disclaimer' });
        }
        if (appContext.application.settings.show_info_logout) {
            menuArray.push({ name: 'logout', menu: 'Logout' });
        }
    }
    if (menuArray.length > 1 && menu == '') {
        menu = menuArray[1].menu;
    }
    if (settingsContext.showMainMenu && visible) {
        return (
            <Screen
                style={{ flex: deviceContext.isKaiOs || deviceContext.isPwaVertical ? null : 0, height: getCorrectHeight(deviceContext, 40) }}
                stealFocus={false}
                focusOptions={{
                    stealFocus: false,
                    group: 'menu-bottom',
                    focusKey: 'menu-bottom',
                    nextFocusUp: ['content-screen', 'settings-screen'],
                }}
            >
                <ScrollView style={{ flexDirection: 'row', height: getCorrectHeight(deviceContext, 40) }} horizontal={true}>
                    <View
                        style={{
                            flex: 1,
                            backgroundColor: styling.general?.background_color1,
                            flexDirection: 'row',
                        }}
                    >
                        {menuArray.map((route, index) => {
                            return (
                                <Pressable animatorOptions={getFocusStyling('Buttons', appContext)} key={index} onPress={() => onPress(route.menu)}>
                                    <>
                                        {downloadStatus == 'loading' && route.menu == 'Downloads' && (
                                            <View style={{ position: 'absolute', zIndex: 999, left: getCorrectWidth(deviceContext, 15), top: getCorrectWidth(deviceContext, 7), marginRight: getCorrectWidth(deviceContext, 5) }}>
                                                <CachingTaskView size={'Large'} setDownloadStatus={setDownloadStatus} task={task} debug={false} deviceContext={deviceContext} appContext={appContext}></CachingTaskView>
                                            </View>
                                        )}
                                        {contentContext.activeMenu != route.menu && (
                                            <View
                                                style={{
                                                    height: getCorrectHeight(deviceContext, 34),
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: getCorrectWidth(deviceContext, (route.menu == 'Account' && !deviceContext.isPhone && !deviceContext.isPwaVertical) || route.menu == 'Switch Profile' ? 45 : 65),
                                                    margin: getCorrectWidth(deviceContext, 3),
                                                    paddingTop: getCorrectWidth(deviceContext, (route.menu == 'Account' && !deviceContext.isPhone && !deviceContext.isPwaVertical) || route.menu == 'Switch Profile' ? 0 : 3),
                                                    flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                                                }}
                                            >
                                                {((route.menu == 'Account' && !deviceContext.isPhone && !deviceContext.isPwaVertical) || route.menu == 'Switch Profile') && (
                                                    <Image
                                                        resizeMethod={'scale'}
                                                        resizeMode={'cover'}
                                                        source={{
                                                            uri: contextUser.profile.avatar,
                                                        }}
                                                        style={{
                                                            width: getCorrectWidth(deviceContext, 35),
                                                            height: getCorrectWidth(deviceContext, 35),
                                                            borderRadius: 100,
                                                            // borderColor: appContext.application.theme.selected.navigators.border_bottom_color,
                                                            // borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 2) : getCorrectWidth(deviceContext, 1),
                                                        }}
                                                    ></Image>
                                                )}
                                                {route.menu != 'Switch Profile' && route.menu == 'Account' && (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                                                    <FontAwesomeIcon size={getFontSize(deviceContext, 'Large') * 1.2} style={{ color: appContext.application.theme.icons?.background }} icon={getMenuIcon(route.menu, contextUser)} />
                                                )}

                                                {route.menu != 'Switch Profile' && route.menu != 'Account' && (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                                                    <FontAwesomeIcon size={getFontSize(deviceContext, 'Large') * 1.2} style={{ color: appContext.application.theme.icons?.background }} icon={getMenuIcon(route.menu, contextUser)} />
                                                )}

                                                {!deviceContext.isPwaVertical && !deviceContext.isKaiOs && !deviceContext.isPhone && !deviceContext.isTablet && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                    <Text
                                                        numberOfLines={1}
                                                        style={{
                                                            paddingTop: 0,
                                                            paddingLeft: 10,
                                                            paddingRight: 0,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Small'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {LANG.getTranslation(contextUser, route.name)}
                                                    </Text>
                                                )}

                                                {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && route.menu != 'Search' && route.menu == 'Account' && route.menu != 'Switch Profile' && (
                                                    <Text
                                                        numberOfLines={1}
                                                        style={{
                                                            paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 3 : 0,
                                                            paddingLeft: 10,
                                                            paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Small'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {LANG.getTranslation(contextUser, route.name)}
                                                    </Text>
                                                )}
                                                {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && route.menu == 'Search' && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                    <Text
                                                        numberOfLines={1}
                                                        style={{
                                                            paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 3 : 0,
                                                            paddingLeft: 10,
                                                            paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Small'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {LANG.getTranslation(contextUser, route.name)}
                                                    </Text>
                                                )}
                                                {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && route.menu != 'Search' && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                    <Text
                                                        numberOfLines={1}
                                                        style={{
                                                            paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 3 : 0,
                                                            // paddingLeft: 10,
                                                            // paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Small'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {LANG.getTranslation(contextUser, route.name)}
                                                    </Text>
                                                )}
                                            </View>
                                        )}
                                        {contentContext.activeMenu == route.menu && (
                                            <View
                                                style={{
                                                    height: getCorrectHeight(deviceContext, 34),
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: getCorrectWidth(deviceContext, 65),
                                                    margin: getCorrectWidth(deviceContext, 3),
                                                    flexDirection: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 'column' : 'row',
                                                    backgroundColor: getSelectedStyling('Navigators', appContext).background_color,
                                                    borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius,
                                                }}
                                            >
                                                {(route.menu == 'Account' || route.menu == 'Switch Profile') && (
                                                    <Image
                                                        resizeMethod={'scale'}
                                                        resizeMode={'contain'}
                                                        source={{
                                                            uri: contextUser.profile.avatar,
                                                        }}
                                                        style={{
                                                            width: getCorrectWidth(deviceContext, 35),
                                                            height: getCorrectWidth(deviceContext, 35),
                                                            //  borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 4) : getCorrectWidth(deviceContext, 2),
                                                            borderRadius: 100,
                                                        }}
                                                    ></Image>
                                                )}
                                                {route.menu != 'Account' && route.menu != 'Switch Profile' && (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && (
                                                    <FontAwesomeIcon size={getFontSize(deviceContext, 'Large') * 1.2} style={{ color: getSelectedStyling('Navigators', appContext).text_color }} icon={getMenuIcon(route.menu, contextUser)} />
                                                )}
                                                {!deviceContext.isPwaVertical && !deviceContext.isKaiOs && !deviceContext.isPhone && !deviceContext.isTablet && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                    <Text
                                                        numberOfLines={1}
                                                        style={{
                                                            paddingTop: 0,
                                                            //   paddingLeft: 10,
                                                            // paddingRight: 0,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Small'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                            color: getSelectedStyling('Navigators', appContext).text_color,
                                                        }}
                                                    >
                                                        {LANG.getTranslation(contextUser, route.name)}
                                                    </Text>
                                                )}
                                                {(deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) && route.menu != 'Account' && route.menu != 'Switch Profile' && (
                                                    <Text
                                                        numberOfLines={1}
                                                        style={{
                                                            paddingTop: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 3 : 0,
                                                            //  paddingLeft: 10,
                                                            //  paddingRight: deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical ? 10 : 0,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Small'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                            color: getSelectedStyling('Navigators', appContext).text_color,
                                                        }}
                                                    >
                                                        {LANG.getTranslation(contextUser, route.name)}
                                                    </Text>
                                                )}
                                            </View>
                                        )}
                                    </>
                                </Pressable>
                            );
                        })}
                    </View>
                </ScrollView>
                {deviceContext.isPhone && menuArray.length > 4 && (
                    <View
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: getCorrectHeight(deviceContext, 15),
                            width: getCorrectWidth(deviceContext, 15),
                            margin: getCorrectHeight(deviceContext, 5),
                            ...getPressableStyling(styling.components?.button?._id, appContext),
                            backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 50),
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronRight} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Normal') * 1.1}></FontAwesomeIcon>
                    </View>
                )}
            </Screen>
        );
    }

    return null;
}
export default () => {
    return (
        <Tab.Navigator tabBar={(props) => <MyTabBar {...props} />}>
            <Tab.Screen name="AppStack" component={Stacks} />
        </Tab.Navigator>
    );
};
