import { View } from '@scriptx-com/xtv-toolkit';
import * as React from 'react';
import { useContext, useState } from 'react';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import lang from '../../../languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { ScreenFadeIn } from '../../animations/screenFadeIn';
import { getKeyboard } from '../../elements/players/helpers/functions';
import { Screen } from '@scriptx-com/xtv-toolkit';
import { AppContext } from '../../../context/appContext';
import { colorShade } from '../../../styling/colorShade';
import Text from '../text';
import { getPressableStyling, getFocusStyling, getPressableTextStyling, getInputStyling } from '../helpers/helper';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import Input from '../input/input';
import Pressable from '../../../../application/builder/components/pressable/pressable';
import Image from '../image/image';
import { ContentContext } from '../../../context/contentContext';
import { RemoteImageHeightWidth } from '../remoteimage/remoteimage';
import moment from 'moment';
import { watchingStatus } from '../../../data/account';

export interface ParentalProps {
    styling: any;
    stylingContent: any;
    setShowModal: any;
    data?: any;
    type?: any;
    focusContext: any;
}
const PayPerView = ({ focusContext, styling, setShowModal, data, type, stylingContent }: ParentalProps) => {
    const deviceContext = useContext(DeviceContext);
    const userContext = useContext(UserContext);
    const appContext = useContext(AppContext);
    const contentContext = useContext(ContentContext);

    const [showError, setShowError] = useState(false);
    const [parental, setParental] = useState('');

    const checkParentalCode = () => {
        if (parental == userContext.profile.code || parental == userContext.profile.code) {
            //  setParentalApproved(true);
        } else {
            setShowError(true);
        }
    };

    const setPincode = (value) => {
        if (value == 'back') {
            setParental(parental.substring(0, parental.length - 1));
        } else if (value == 'clear') {
            setParental('');
        } else if (parental.length < 4) {
            setParental(parental + '' + value);
        }
    };
    const width = getRealWidth(deviceContext) * (deviceContext.isPhone ? 0.9 : 0.6);

    const getImage = () => {
        switch (type) {
            case 'Movie':
                return data.images.poster;
                break;

            default:
                break;
        }
    };
    const getTitle = () => {
        switch (type) {
            case 'Movie':
                return data.name;
                break;

            default:
                break;
        }
    };
    const getPrice = () => {
        switch (type) {
            case 'Movie':
                return 1, 99;
                break;

            default:
                break;
        }
    };

    const LogoRow = ({ item, type, deviceContext, appContext, contentContext, styling, channel }) => {
        if (type == 'Program') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        <Image
                            deviceContext={deviceContext}
                            resizeMethod={'scale'}
                            resizeMode={'contain'}
                            source={{ uri: appContext.cloudUrl + (channel != undefined ? channel.images?.square : contentContext.channelData.channel.images?.square) }}
                            style={{
                                width: getCorrectWidth(deviceContext, 35),
                                height: getCorrectWidth(deviceContext, 35),
                            }}
                        ></Image>
                    </View>
                    <View>
                        {item.program?.l != undefined && item.program?.l != '' && (
                            <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 10) }}>
                                <RemoteImageHeightWidth uri={item.program?.l} desiredWidth={getRealWidth(deviceContext) * 0.15} desiredHeight={50} deviceContext={deviceContext} />
                            </View>
                        )}
                        {(item.program?.l == undefined || item.program?.l == '') && (
                            <View style={{ justifyContent: 'center' }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                        color: styling.texts.title_color,
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {item.program.n}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            );
        } else if (type == 'Recording') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        <Image
                            deviceContext={deviceContext}
                            resizeMethod={'scale'}
                            resizeMode={'contain'}
                            source={{ uri: appContext.cloudUrl + contentContext.recordingData.channel.images?.square }}
                            style={{
                                width: getCorrectWidth(deviceContext, 35),
                                height: getCorrectWidth(deviceContext, 35),
                            }}
                        ></Image>
                    </View>
                    <View>
                        {contentContext.recordingData.program?.l != undefined && contentContext.recordingData.program?.l != '' && (
                            <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 10) }}>
                                <RemoteImageHeightWidth uri={contentContext.recordingData.program?.l} desiredWidth={getRealWidth(deviceContext) * 0.15} desiredHeight={50} deviceContext={deviceContext} />
                            </View>
                        )}
                        {(contentContext.recordingData.program?.l == undefined || contentContext.recordingData.program?.l == '') && (
                            <View style={{ justifyContent: 'center' }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                        color: styling.texts.title_color,
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {contentContext.recordingData.program.n}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            );
        } else if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Seasons') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        {item.images.logo != undefined && item.images.logo != '' && (
                            <View style={{ justifyContent: 'center', marginVertical: getCorrectHeight(deviceContext, 10) }}>
                                <RemoteImageHeightWidth uri={appContext.cloudUrl + item?.images.logo} desiredWidth={getRealWidth(deviceContext) * 0.15} desiredHeight={50} deviceContext={deviceContext} />
                            </View>
                        )}
                        {(item.images.logo == undefined || item.images.logo == '') && (
                            <View style={{ justifyContent: 'center' }}>
                                <Text
                                    numberOfLines={2}
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                        fontWeight: styling.texts.title_bold ? 'bold' : null,
                                        color: styling.texts.title_color,
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {item.name}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            );
        }
    };
    const StatusTimeRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
        if (type == 'Program') {
            var currentTime = moment().unix();
            var isLive = item.program.s < currentTime && item.program.e > currentTime;
            var isPast = item.program.s < currentTime && item.program.e < currentTime;
            var recordingDone = false;
            var isRecorded = false;
            var isReminded = false;
            var recordingPlanned = false;
            var recordingProgress = false;
            var currentTime = moment().unix();
            if (currentTime > item.program?.e) {
                recordingDone = true;
            } else if (currentTime > item.program?.s && currentTime < item.program?.e) {
                recordingProgress = true;
            } else {
                recordingPlanned = true;
            }

            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                        {isLive && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'crimson',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'live').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isRecorded && recordingProgress && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'crimson',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'recording').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isRecorded && recordingPlanned && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'crimson',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'planned').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isRecorded && recordingDone && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'crimson',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isReminded && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'royalblue',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'reminded').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'royalblue',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'replay').toUpperCase()}
                                </Text>
                            </View>
                        )}
                        {isLive && contentContext.channelData.channel.interactivetv.pausetv_enabled && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                                <Text
                                    style={{
                                        backgroundColor: 'royalblue',
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {lang.getTranslation(userContext, 'restart').toUpperCase()}
                                </Text>
                            </View>
                        )}

                        {(isLive || (isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled) || isReminded || isRecorded) && (
                            <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                                <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                            </View>
                        )}
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: isLive || (isPast && contentContext.channelData.channel.interactivetv.catchuptv_enabled) ? 5 : 0 }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {moment.unix(item.program?.s).format('ll ' + deviceContext.clockSetting) + ' - ' + moment.unix(item.program?.e).format(deviceContext.clockSetting)}
                            </Text>
                        </View>
                    </View>
                </View>
            );
        } else if (type == 'Recording') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                            </Text>
                        </View>

                        <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                            <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                        </View>

                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: 0 }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {moment.unix(contentContext.recordingData.program?.s).format('ll ' + deviceContext.clockSetting) + ' - ' + moment.unix(contentContext.recordingData.program?.e).format(deviceContext.clockSetting)}
                            </Text>
                        </View>
                    </View>
                </View>
            );
        } else {
            return <></>;
        }
    };
    const MetaRow = ({ item, type, deviceContext, appContext, userContext, contentContext, styling }) => {
        if (type == 'Program') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5) }}>
                        {item.program.mt?.ye != undefined && item.program.mt?.ye != '' && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.texts.title_color,
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {item.program?.mt?.ye}
                                </Text>
                            </View>
                        )}
                        {item.program.mt?.ye != undefined && item.program.mt?.ye != '' && item.program?.mt?.ge != undefined && item.program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {item.program?.mt?.ge != undefined && item.program?.mt?.ge.length > 0 && <View>{getTagsChannels(item.program?.mt?.ge, appContext, deviceContext, userContext)}</View>}

                        {item.program?.p != undefined && item.program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {item.program?.p != undefined && item.program?.p != '' && (
                            <View style={{ justifyContent: 'center' }}>
                                <Text
                                    style={{
                                        backgroundColor: appContext.application.theme.tags.background,
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        margin: getCorrectWidth(deviceContext, 2),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {item.program?.p}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            );
        } else if (type == 'Recording') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5) }}>
                        {contentContext.recordingData.program.mt?.ye != undefined && contentContext.recordingData.program.mt?.ye != '' && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.texts.title_color,
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {contentContext.recordingData.program?.mt?.ye}
                                </Text>
                            </View>
                        )}
                        {contentContext.recordingData.program.mt?.ye != undefined &&
                            contentContext.recordingData.program.mt?.ye != '' &&
                            contentContext.recordingData.program?.mt?.ge != undefined &&
                            contentContext.recordingData.program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {contentContext.recordingData.program?.mt?.ge != undefined && contentContext.recordingData.program?.mt?.ge.length > 0 && (
                            <View>{getTagsChannels(contentContext.recordingData.program?.mt?.ge, appContext, deviceContext, userContext)}</View>
                        )}

                        {contentContext.recordingData.program?.p != undefined && contentContext.recordingData.program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {contentContext.recordingData.program?.p != undefined && contentContext.recordingData.program?.p != '' && (
                            <View style={{ justifyContent: 'center' }}>
                                <Text
                                    style={{
                                        backgroundColor: appContext.application.theme.tags.background,
                                        color: appContext.application.theme.tags.color,
                                        borderRadius: appContext.application.theme.tags.radius / 2,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        margin: getCorrectWidth(deviceContext, 2),
                                        padding: getCorrectWidth(deviceContext, 2),
                                        paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                        includeFontPadding: false,
                                    }}
                                >
                                    {contentContext.recordingData.program?.p}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            );
        } else if (type == 'Movie' || type == 'Course' || type == 'Short' || type == 'Seasons') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5) }}>
                        {item.released != undefined && item.released != '' && (
                            <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.texts.title_color,
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {item.released}
                                </Text>
                            </View>
                        )}
                        {item.released != undefined && item.released != '' && item.tags != undefined && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {item.tags != undefined && <View>{getTags(item.tags, appContext, deviceContext, userContext)}</View>}
                        {item.tags != undefined && item.runtime != undefined && item.runtime != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {item.runtime != undefined && item.runtime != '' && (
                            <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.texts.title_color,
                                        includeFontPadding: false,
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {moment.utc().startOf('day').add({ minutes: item.runtime }).format('H')}
                                    {'h:'}
                                    {moment.utc().startOf('day').add({ minutes: item.runtime }).format('mm')}
                                    {'m'}
                                </Text>
                            </View>
                        )}
                        {item.runtime != undefined && item.runtime != '' && item.rating != undefined && item.rating != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                        {item.rating != undefined && item.rating != '' && (
                            <Text
                                style={{
                                    backgroundColor: appContext.application.theme.tags.background,
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    margin: getCorrectWidth(deviceContext, 2),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {item.rating}
                            </Text>
                        )}
                    </View>
                </View>
            );
        }
    };
    const DescriptionRow = ({ item, type, deviceContext, userContext, contentContext, styling }) => {
        if (type == 'Program') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        <Text
                            //  numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.program?.d}
                        </Text>
                    </View>
                </View>
            );
        } else if (type == 'Recording') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        <Text
                            //  numberOfLines={5}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {contentContext.recordingData.program?.d}
                        </Text>
                    </View>
                </View>
            );
        } else if (type == 'Movie' || type == 'Course' || type == 'Short') {
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        {item.translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                            <Text
                                //  numberOfLines={5}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),

                                    color: styling.texts.subtext_color,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.translations.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                            </Text>
                        )}
                        {item.translations.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations.length > 0 && (
                            <Text
                                // numberOfLines={5}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),

                                    color: styling.texts.subtext_color,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.translations[0]?.description}
                            </Text>
                        )}
                    </View>
                </View>
            );
        } else if (type == 'Seasons') {
            var watchlist = watchingStatus(type, item._id, userContext);
            var episodeIndex = 0;
            var seasonIndex = 0;
            if (watchlist != undefined) {
                episodeIndex = watchlist.continue.episode_index ?? 0;
                seasonIndex = watchlist.continue.season_index ?? 0;
            }
            return (
                <View style={{ flexDirection: 'column' }}>
                    <View>
                        {item.seasons[seasonIndex].translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                            <Text
                                //numberOfLines={5}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),

                                    color: styling.texts.subtext_color,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.seasons[seasonIndex].translations.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                            </Text>
                        )}
                        {item.seasons[seasonIndex].translations.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations.length > 0 && (
                            <Text
                                // numberOfLines={5}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),

                                    color: styling.texts.subtext_color,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.seasons[seasonIndex].translations[0]?.description}
                            </Text>
                        )}
                    </View>
                </View>
            );
        }
    };
    const DotInBetween = ({ deviceContext, styling }) => {
        return (
            <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
                <View style={{ width: getCorrectWidth(deviceContext, 3), height: getCorrectWidth(deviceContext, 3), backgroundColor: styling.texts.title_color, borderRadius: 100 }}></View>
            </View>
        );
    };
    const getTagsChannels = (tags, appContext, deviceContext, userContext) => {
        var tagsOut = [] as any;
        if (tags != undefined && tags.length > 0) {
            tags.map((tag, index) => {
                if (index < 3) {
                    tagsOut.push(
                        <Text
                            key={index}
                            style={{
                                backgroundColor: appContext.application.theme.tags.background,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                color: appContext.application.theme.tags.color,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                margin: getCorrectWidth(deviceContext, 2),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 4),
                                includeFontPadding: false,
                            }}
                        >
                            {tag}
                        </Text>
                    );
                }
            });
        }
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
    };
    const getTags = (tags, appContext, deviceContext, userContext) => {
        var tagsOut = [] as any;
        if (tags != undefined && tags[0] != undefined && tags[0].tag != undefined) {
            tags.map((tag, index) => {
                if (tag.tag != undefined) {
                    var test = tag.tag.find((t) => t.name == userContext.selectedLanguage);
                    if (test == undefined) {
                        tag.name = tag[0].description;
                    } else {
                        tag.name = test.description;
                    }
                }
                if (index < 3) {
                    tagsOut.push(
                        <Text
                            key={index}
                            style={{
                                backgroundColor: appContext.application.theme.tags.background,
                                color: appContext.application.theme.tags.color,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                margin: getCorrectWidth(deviceContext, 2),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 4),
                                includeFontPadding: false,
                            }}
                        >
                            {tag.name}
                        </Text>
                    );
                }
            });
        }
        return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
    };

    //16/9 plaatje
    return (
        <ScreenFadeIn>
            <Screen
                focusOptions={{ screenOrder: 2 }}
                style={{
                    position: 'absolute',
                    zIndex: 999,
                    flex: 1,
                    width: deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext),
                    height: deviceContext.isPhone ? getRealWidth(deviceContext) : getRealHeight(deviceContext),
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                }}
            >
                {data?._id != undefined && (
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            width: deviceContext.isPhone ? getRealHeight(deviceContext) : getRealWidth(deviceContext) * 0.8,
                        }}
                    >
                        <View
                            style={{
                                borderColor: '#333333',
                                borderWidth: 1,
                                borderRadius: getCorrectWidth(deviceContext, 3),
                                flexDirection: 'row',
                                alignSelf: 'center',
                                backgroundColor: colorShade(styling.background_color, 10),
                                flex: 1,
                            }}
                        >
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <View style={{ flex: 1, alignItems: 'center', flexDirection: 'column', padding: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ marginTop: 20, width: getRealWidth(deviceContext) * 0.3 }}>
                                        <LogoRow channel={undefined} item={data} type={type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={stylingContent}></LogoRow>
                                    </View>
                                    <View style={{ height: ((getRealWidth(deviceContext) * 0.3) / 16) * 9, width: getRealWidth(deviceContext) * 0.3 }}>
                                        <View>
                                            <View
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: getRealWidth(deviceContext) * 0.3,
                                                    height: ((getRealWidth(deviceContext) * 0.3) / 16) * 9,
                                                }}
                                            >
                                                <Image
                                                    isCache={false}
                                                    deviceContext={deviceContext}
                                                    source={{ uri: appContext.cloudUrl + getImage() }}
                                                    resizeMethod={'resize'}
                                                    resizeMode={'cover'}
                                                    style={{
                                                        borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                                        width: width * 0.15,
                                                        height: width * 0.15 * 1.5,
                                                    }}
                                                />
                                            </View>
                                            <View style={{ position: 'absolute', zIndex: 0 }}>
                                                <Image
                                                    blurRadius={98}
                                                    isCache={false}
                                                    deviceContext={deviceContext}
                                                    source={{ uri: appContext.cloudUrl + getImage() }}
                                                    resizeMethod={'resize'}
                                                    resizeMode={'cover'}
                                                    style={{
                                                        borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                                        width: getRealWidth(deviceContext) * 0.3,
                                                        height: ((getRealWidth(deviceContext) * 0.3) / 16) * 9,
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 20, width: getRealWidth(deviceContext) * 0.3 }}>
                                        {/* <LogoRow channel={undefined} item={data} type={type} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={stylingContent}></LogoRow> */}
                                        <StatusTimeRow item={data} type={type} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={stylingContent}></StatusTimeRow>
                                        <MetaRow item={data} type={type} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={stylingContent}></MetaRow>
                                        <DescriptionRow item={data} type={type} deviceContext={deviceContext} userContext={userContext} contentContext={contentContext} styling={stylingContent}></DescriptionRow>
                                    </View>
                                </View>
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20), marginTop:100 }}>
                                    <View style={{ marginRight: 50 }}>
                                        <View>
                                            <Text
                                                style={{
                                                    color: styling.title_color,
                                                    fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    marginBottom: getCorrectHeight(deviceContext, 10),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'rent')}
                                            </Text>
                                        </View>
                                        <Pressable
                                            style={{
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                width: '100%',
                                                marginTop: 10,
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => checkParentalCode()}
                                        >
                                            <View
                                                style={{
                                                    alignItems: 'center',
                                                    height: getCorrectHeight(deviceContext, 30),
                                                    flexDirection: 'row',
                                                    backgroundColor: 'red',
                                                }}
                                            >
                                                <View style={{ flex: 1, marginLeft: 20 }}>
                                                    <Text
                                                        style={{
                                                            padding: 10,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {lang.getTranslation(userContext, 'rent')} {type}
                                                    </Text>
                                                </View>
                                                <View style={{ flex: 1, alignContent: 'flex-end', marginRight: 20 }}>
                                                    <Text
                                                        style={{
                                                            textAlign: 'right',
                                                            padding: 10,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        4,99
                                                    </Text>
                                                </View>
                                            </View>
                                        </Pressable>
                                    </View>
                                    <View style={{ marginTop: 40, marginRight: 50 }}>
                                        <View>
                                            <Text
                                                style={{
                                                    color: styling.title_color,
                                                    fontWeight: styling.title_bold ? 'bold' : 'normal',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    marginBottom: getCorrectHeight(deviceContext, 10),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'buy')}
                                            </Text>
                                        </View>
                                        <Pressable
                                            style={{
                                                ...getPressableStyling(styling.components?.button?._id, appContext),
                                                width: '100%',
                                                marginTop: 10,
                                            }}
                                            animatorOptions={getFocusStyling('Buttons', appContext)}
                                            onPress={() => checkParentalCode()}
                                        >
                                            <View
                                                style={{
                                                    alignItems: 'center',
                                                    height: getCorrectHeight(deviceContext, 30),
                                                    flexDirection: 'row',
                                                    backgroundColor: 'red',
                                                }}
                                            >
                                                <View style={{ flex: 1, marginLeft: 20 }}>
                                                    <Text
                                                        style={{
                                                            padding: 10,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        {lang.getTranslation(userContext, 'buy')} {type}
                                                    </Text>
                                                </View>
                                                <View style={{ flex: 1, alignContent: 'flex-end', marginRight: 20 }}>
                                                    <Text
                                                        style={{
                                                            textAlign: 'right',
                                                            padding: 10,
                                                            fontFamily: deviceContext.fontType,
                                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                                            ...getPressableTextStyling(styling.components?.button?._id, appContext),
                                                        }}
                                                    >
                                                        19,99
                                                    </Text>
                                                </View>
                                            </View>
                                        </Pressable>
                                    </View>
                                </View>
                            </View>
                            {!deviceContext.isWebTV && !deviceContext.isTablet && !deviceContext.isPhone && (
                                <View style={{ flex: 1, flexDirection: 'row' }}>{getKeyboard(appContext.application.theme.keyboard.search, deviceContext, appContext, setPincode)}</View>
                            )}
                        </View>
                    </View>
                )}
            </Screen>
        </ScreenFadeIn>
    );
};

export default PayPerView;
