import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { CommonActions } from '@react-navigation/native';
import * as React from 'react';
import { useRef, useState } from 'react';
import Gradient from 'react-native-linear-gradient';
import { AddCachingTaskEvent, CacheEventType, MediaCache } from 'react-native-theoplayer';
import { Animated, Image, Screen, ScrollView, View, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import { createNativeStackNavigator } from '../../libs/@react-navigation';
import { Ratio } from '../../screens/wrapper/helpers/getFocusOptions';
import { CachingTaskView } from '../builder/components/downloadbutton/downloadbutton';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../builder/components/helpers/helper';
import Pressable from '../builder/components/pressable/pressable';
import Text from '../builder/components/text';
import { AppContext } from '../context/appContext';
import { ContentContext } from '../context/contentContext';
import { DeviceContext } from '../context/deviceContext';
import { SettingsContext } from '../context/settingsContext';
import { UserContext } from '../context/userContext';
import utils from '../general/utils';
import LANG from '../languages/languages';
import Stacks from '../stacks/StackSelector';
import { colorShade } from '../styling/colorShade';
import { getCorrectHeight, getCorrectWidth } from '../styling/correctSizes';
import { getFontSize } from '../styling/fontSizes';
import { getMenuIcon } from '../styling/menuIcon';
import { createTVSideNavigator } from './helpers/SideNavigator';
import { checkIfKnownMenuItem, checkIfMenuHasWebview } from './helpers/helpers';

const LinearGradient = withParentContextMapper(Gradient);
const SideNavigator = createTVSideNavigator();
const Stack = createNativeStackNavigator();
const AnimatedText = Animated.createAnimatedComponent(Text);
const AnimatedGradient = Animated.createAnimatedComponent(LinearGradient);
const TRANSLATE_VAL_HIDDEN = Ratio(-500);

const Menu = ({ navigation }: { navigation: any }) => {
    var addTaskEvent = undefined;

    const settingsContext = React.useContext(SettingsContext);
    const appContext = React.useContext(AppContext);
    const contextUser = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);
    const [task, setTask] = useState([] as any);
    const [downloadStatus, setDownloadStatus] = useState('false');

    var styling = [] as any;
    if (deviceContext.isWebTV && !deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.browser;
    } else if (deviceContext.isTablet) {
        styling = appContext.application.navigators?.tablet;
    } else if (deviceContext.isPhone || deviceContext.isKaiOs || deviceContext.isPwaVertical) {
        styling = appContext.application.navigators?.mobile;
    } else if (deviceContext.isTelevision) {
        styling = appContext.application.navigators?.television;
    }

    React.useEffect(() => {
        addTaskEvent = MediaCache.addEventListener(CacheEventType.addtask, (event: AddCachingTaskEvent) => {
            if (event.task.status == 'idle') {
                setTask(event.task);
                setDownloadStatus('loading');
            }
        });
        return () => {
            if (addTaskEvent) {
                MediaCache.removeEventListener(CacheEventType.addtask, addTaskEvent);
            }
        };
    }, []);

    global.menuWidth = 50;
    global.menuHeight = 0;
    const [menuOpen, setMenuOpen] = useState(false);
    const [showLogo, setShowLogo] = useState(false);

    const translateBgAnim = useRef(new Animated.Value(TRANSLATE_VAL_HIDDEN)).current;
    const opacityAnim = [useRef(new Animated.Value(0)).current];

    const translateTextAnim = [useRef(new Animated.Value(TRANSLATE_VAL_HIDDEN)).current];
    // @ts-ignore
    const timing = (object: Animated.AnimatedValue, toValue: number, duration = 150): Animated.CompositeAnimation => {
        return Animated.timing(object, {
            toValue,
            duration,
            useNativeDriver: true,
        });
    };
    const onFocus = () => {
        setMenuOpen(true);
        setShowLogo(true);
        Animated.parallel([timing(translateBgAnim, 1), timing(opacityAnim[0], 1, 500), timing(translateTextAnim[0], 0, 500)]).start();
    };
    const onBlur = () => {
        setShowLogo(false);
        setMenuOpen(false);
        Animated.parallel([timing(translateBgAnim, TRANSLATE_VAL_HIDDEN), timing(opacityAnim[0], 0, 100), timing(translateTextAnim[0], TRANSLATE_VAL_HIDDEN)]).start();
    };

    const [activeSettings, setActiveSettings] = useState('');

    React.useEffect(() => {
        if (contentContext.activeMenu === 'Switch Profile') {
            setShowLogo(false);
            setMenuOpen(false);
            translateBgAnim.setValue(TRANSLATE_VAL_HIDDEN);
            opacityAnim[0].setValue(0);
            translateTextAnim[0].setValue(TRANSLATE_VAL_HIDDEN);
        }
    }, [contentContext.activeMenu]);

    const onPress = (menu: any) => {
        if (menu != 'Account' && activeSettings == '') {
            onBlur();

            if (checkIfKnownMenuItem(menu)) {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: menu }],
                    })
                );
            } else {
                contentContext.setActiveMenu(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'custom' }],
                    })
                );
            }
        } else {
            if (menu == 'Back') {
                contentContext.setActiveMenu('Home');
                setActiveSettings('');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Home' }],
                    })
                );
            } else if (menu == 'Account') {
                contentContext.setActiveMenu('Manual');
                setActiveSettings(menu);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Manual' }],
                    })
                );
            } else if (menu == 'Switch Profile') {
                onBlur();
                contentContext.setActiveMenu('Switch Profile');
                setActiveSettings('');
                settingsContext.setShowMainMenu(false);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Profiles' }],
                    })
                );
            } else if (menu == 'Logout') {
                onBlur();
                setActiveSettings('');
                setShowLogo(false);

                translateBgAnim.setValue(TRANSLATE_VAL_HIDDEN);
                opacityAnim[0].setValue(0);
                translateTextAnim[0].setValue(TRANSLATE_VAL_HIDDEN);
                settingsContext.setShowMainMenu(false);

                setMenuOpen(false);
                contextUser.setAutoLogin(false);
                utils.storeJson('AutoLogin', false);

                contextUser.setAllProfiles([]);

                if (appContext.application.settings.enable_delete_userdata == true || deviceContext.isSmartTV == true) {
                    contextUser.setAppId('');
                    utils.storeJson('ServiceID', '');

                    contextUser.setUserId('');
                    utils.storeJson('UserID', '');
                    contextUser.setPass('');
                    utils.storeJson('Pass', '');
                }

                if (appContext.application.settings?.enable_nested == true) {
                    contentContext.setActiveMenu('Services');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Services' }],
                        })
                    );
                } else if (appContext.application?.settings.enable_anonymous == true) {
                    contentContext.setActiveMenu('Anonymous');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Anonymous' }],
                        })
                    );
                } else {
                    contentContext.setActiveMenu('Login');
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Login' }],
                        })
                    );
                }
            } else if (menu == 'Refresh') {
                setActiveSettings('');
                setShowLogo(false);
                setMenuOpen(false);
                translateBgAnim.setValue(TRANSLATE_VAL_HIDDEN);
                opacityAnim[0].setValue(0);
                translateTextAnim[0].setValue(TRANSLATE_VAL_HIDDEN);
                settingsContext.setShowMainMenu(false);
                contentContext.setActiveMenu('Loader');
                onBlur();
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Loader' }],
                    })
                );
            } else {
                contentContext.setActiveMenu(menu);
                navigation && navigation.push(menu);
            }
        }
    };

    const renderMenuButton = (item, index) => (
        <Pressable
            style={{
                alignSelf: 'center',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                height: getCorrectWidth(deviceContext, 40),
                width: showLogo ? getCorrectWidth(deviceContext, 190) : getCorrectWidth(deviceContext, 40),
                borderRadius: showLogo ? getPressableStyling(styling.components?.button?._id, appContext).borderRadius ?? 0 : 0,
                backgroundColor: 'transparent',
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            key={index}
            onPress={() => onPress(item.menu)}
            onMouseOver={() => onFocus()}
            onMouseOut={() => onBlur()}
        >
            <View
                style={{
                    flexGrow: 1,
                    alignSelf: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                }}
            >
                <View
                    style={{
                        width: getCorrectWidth(deviceContext, 40),
                        height: getCorrectHeight(deviceContext, 35),
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',

                        borderRadius: getPressableStyling(styling.components?.button?._id, appContext).borderRadius ?? 0,
                        backgroundColor: item.name[0].toUpperCase() + item.name.slice(1) == contentContext.activeMenu ? getSelectedStyling('Navigators', appContext).background_color : 'transparent',
                        zIndex: 999,
                    }}
                >
                    {(item.menu == 'Account' || item.menu == 'Switch Profile') && (
                        <Image
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            source={{ uri: contextUser.profile.avatar }}
                            style={{
                                width: getCorrectWidth(deviceContext, 30),
                                height: getCorrectWidth(deviceContext, 30),
                                borderRadius: 100,
                                //borderColor: '#fff',
                                //borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 2) : getCorrectWidth(deviceContext, 1),
                            }}
                        ></Image>
                    )}
                    {downloadStatus == 'loading' && item.menu == 'Downloads' && (
                        <View style={{ position: 'absolute', zIndex: 999, left: getCorrectWidth(deviceContext, 15), top: getCorrectWidth(deviceContext, 7), marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <CachingTaskView size={'Large'} setDownloadStatus={setDownloadStatus} task={task} debug={false} deviceContext={deviceContext} appContext={appContext}></CachingTaskView>
                        </View>
                    )}
                    {item.menu != 'Account' && item.menu != 'Switch Profile' && (
                        <FontAwesomeIcon size={getFontSize(deviceContext, 'Normal') * 1.4} style={{ color: appContext.application.theme.icons.background }} icon={getMenuIcon(item.menu, contextUser)} />
                    )}
                </View>
                <AnimatedText
                    numberOfLines={1}
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Normal'),
                        marginLeft: getCorrectWidth(deviceContext, 10),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                        transform: [{ translateX: translateTextAnim[0] }],
                        opacity: opacityAnim[item.index],
                        borderBottomColor: 'transparent',
                        paddingVertical: getCorrectHeight(deviceContext, 4),
                        zIndex: 1,
                    }}
                >
                    {LANG.getTranslation(contextUser, item.name)}
                </AnimatedText>
            </View>
        </Pressable>
    );

    const renderMenuButtons = () => {
        var menuArray = [] as any;
        if (activeSettings == '') {
            menuArray = [{ name: 'account', menu: 'Account' }];
            appContext.application.menus.forEach((element) => {
                if (
                    element.name != 'Casting' &&
                    element.name != 'Hamburger' &&
                    element.name != 'Logout' &&
                    element.name != 'Youtube' &&
                    element.name != 'Services' &&
                    element.name != 'Disclaimer' &&
                    element.name != 'Signin' &&
                    element.name != 'Forgot' &&
                    element.name != 'Check' &&
                    element.name != 'Player' &&
                    element.name != 'Profiles' &&
                    element.name != 'Languages' &&
                    element.name != 'Settings' &&
                    element.name != 'Messages' &&
                    element.name != 'Support' &&
                    element.name != 'Album_Details' &&
                    element.name != 'Album_Player' &&
                    element.name != 'Podcast_Details' &&
                    element.name != 'Podcast_Player' &&
                    element.name != 'Loader' &&
                    element.name != 'Programs'
                ) {
                    if (
                        (contextUser.userId.indexOf('_review') > -1 && element.name == 'Search') ||
                        (contextUser.userId.indexOf('_review') > -1 && element.name == 'Downloads') ||
                        (contextUser.userId.indexOf('_review') > -1 && element.name == 'Recordings') ||
                        (!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && element.name == 'Downloads') ||
                        (element.name == 'Games' && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet) ||
                        (checkIfMenuHasWebview(appContext,element.name) && !deviceContext.isWebTV && !deviceContext.isPhone && !deviceContext.isTablet)
                    ) {
                        //do nothing
                    } else {
                        var menu_ = { name: element.name, menu: element.name };
                        menuArray.push(menu_);
                    }
                }
            });

            var first = 'Switch Profile';
            menuArray.sort(function (x, y) {
                return x.menu == first ? -1 : y.menu == first ? 1 : 0;
            });

            var first = 'Search';
            menuArray.sort(function (x, y) {
                return x.menu == first ? -1 : y.menu == first ? 1 : 0;
            });
        } else {
            menuArray.push({ name: 'back', menu: 'Back' });
            if (appContext.application.settings.enable_profiles != false) {
            menuArray.push({ name: 'switch_profile', menu: 'Switch Profile' });
            }
            if (appContext.application.settings.show_info_manual) {
                menuArray.push({ name: 'manual', menu: 'Manual' });
            }
            if (appContext.application.settings.enable_register) {
                menuArray.push({ name: 'account', menu: 'Registration' });
            }

            if (appContext.application.settings.show_info_subscription && contextUser.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'subscription', menu: 'Subscription' });
            }

            if (appContext.application.settings.show_info_messages && contextUser.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'messages', menu: 'Messages' });
            }
            // if (appContext.application.settings.show_info_renewal && contextUser.userId.indexOf('_review') < 0) {
            //     menuArray.push({ name: 'renewal', menu: 'Renewal' });
            // }
            if (appContext.application.settings.show_info_support && contextUser.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'support', menu: 'Support' });
            }
            if (appContext.application.settings.show_info_apps && global.Cart != undefined && contextUser.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'apps', menu: 'Apps' });
            }
            if (appContext.application.settings.show_info_about) {
                menuArray.push({ name: 'about', menu: 'About' });
            }
            if (appContext.application.settings.show_info_devices) {
                menuArray.push({ name: 'devices', menu: 'Devices' });
            }
            if (appContext.application.settings.show_info_updates && contextUser.userId.indexOf('_review') < 0 && (deviceContext.isAndroid || deviceContext.isAndroidTV || deviceContext.isFireTV)) {
                menuArray.push({ name: 'updates', menu: 'Updates' });
            }
            if (appContext.application.settings.show_info_refresh) {
                menuArray.push({ name: 'refresh', menu: 'Refresh' });
            }
            if (appContext.application.settings.show_info_disclaimer && contextUser.userId.indexOf('_review') < 0) {
                menuArray.push({ name: 'disclaimer', menu: 'Disclaimer' });
            }
            if (appContext.application.settings.show_info_logout) {
                menuArray.push({ name: 'logout', menu: 'Logout' });
            }
        }

        var menu = [];
        menuArray.map((item, index) => {
            if (item.name != undefined) {
                menu.push(renderMenuButton(item, index));
            }
        });
        return menu;
    };

    //React.useEffect(() => {}, [settingsContext.showMainMenu]);

    if (settingsContext.showMainMenu) {
        return (
            <Screen
                style={{
                    height: '100%',
                    position: 'absolute',
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                focusOptions={{
                    // screenState: "foreground",
                    stealFocus: false,
                    group: 'menu-drawer',
                    verticalViewportOffset: Ratio(100),
                    focusKey: 'menu-drawer',
                    nextFocusRight: ['tv-guide-day-selector', 'content-screen', 'settings-screen'],
                    autoFocusEnabled: false,
                    ignoreInitialFocus: true,
                }}
            >
                {!menuOpen && (
                    <View
                        style={{
                            width: getCorrectWidth(deviceContext, 50),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    ></View>
                )}
                {menuOpen && (
                    <LinearGradient
                        style={{
                            backgroundColor: 'transparent',
                            width: getCorrectWidth(deviceContext, 200),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                        }}
                        locations={[0.2, 0.7]}
                        colors={[styling.general.background_color1, styling.general.background_color2]}
                        start={{ x: 0, y: 1 }}
                        end={{ x: 1, y: 1 }}
                    />
                )}

                <AnimatedGradient
                    style={[
                        {
                            width: getCorrectWidth(deviceContext, 200),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            transform: [{ translateX: translateBgAnim }],
                        },
                    ]}
                    locations={[0.0, 0.8]}
                    colors={[styling.general.background_color1, styling.general.background_color2]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                />
                <View
                    style={[
                        {
                            width: menuOpen ? getCorrectWidth(deviceContext, 200) : getCorrectWidth(deviceContext, 50),
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            //borderRightColor: styling.general.background_closed,
                            //borderRightWidth: 0,
                            backgroundColor: styling.general.background_closed,
                        },
                    ]}
                >
                    <View
                        style={{
                            height: getCorrectHeight(deviceContext, 65),
                            width: menuOpen ? getCorrectWidth(deviceContext, 200) : getCorrectWidth(deviceContext, 50),
                            paddingTop: showLogo ? 0 : getCorrectHeight(deviceContext, 10),
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexDirection: 'row',
                           // backgroundColor: showLogo ? colorShade(styling.general.background_closed, 10) : styling.general.background_closed,
                            alignItems: showLogo ? 'center' : 'flex-start',
                            //borderRightColor: styling.general.background_color1,
                            //borderRightWidth:0,
                        }}
                    >
                        {showLogo && (
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                style={{
                                    width: getCorrectWidth(deviceContext, 150),
                                    height: getCorrectWidth(deviceContext, 50),
                                }}
                                source={{ uri: appContext.application.branding.logos.logo }}
                            />
                        )}
                        {!showLogo && (
                            <Image
                                resizeMethod={'scale'}
                                resizeMode={'contain'}
                                style={{
                                    width: getCorrectWidth(deviceContext, 25),
                                    height: getCorrectWidth(deviceContext, 25),
                                }}
                                source={{ uri: appContext.application.branding.logos.icon }}
                            />
                        )}
                    </View>
                    <ScrollView showsVerticalScrollIndicator={false}>{renderMenuButtons()}</ScrollView>
                </View>
            </Screen>
        );
    } else {
        return null;
    }
};

function SideNavigation() {
    return (
        <SideNavigator.Navigator drawerContent={(props: any) => <Menu {...props} />}>
            <SideNavigator.Screen name={'AppStack'} component={Stacks} />
        </SideNavigator.Navigator>
    );
}

export default function Main() {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Main" component={SideNavigation} />
        </Stack.Navigator>
    );
}
