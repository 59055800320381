import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Pressable from '../../components/pressable/pressable';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { favoriteStatus, watchingStatus } from '../../../data/account';
import { UserContext } from '../../../context/userContext';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import lang from '../../../languages/languages';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import Image from '../../components/image/image';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import { ContentContext } from '../../../context/contentContext';
import { getLiveTag, getReplayTag } from '../players/helpers/helpers';


export interface Program {
    n: string;
    s: any;
    e: any;
}

export interface Data {
    name: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    _id: string;

    progress?: number;
    catchuptv?: boolean;

    program?: Program;
}

export interface Props {
    fontDensity?: number;
    focusContext: any;
    focusOptions?: any;
    focusRepeatContext: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    type: string;
    data: Data;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
}

export const Tile = ({ fontDensity, focusContext, focusRepeatContext, noMargin, height, width, onPress, styling, type, data, onLongPress, focusOptions, heroIsPlayer }: Props) => {
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const screenName = getCurrentScreenName();
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };

    var favorite = favoriteStatus(type, data?._id, userContext);

    const getRibbonText = () => {
        var test = styling.ribbon.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.title != undefined) {
                return styling.content.title[0].title;
            } else {
                return '';
            }
        }
    };
    const getExtraStyle = () => {
        if (styling.ribbon.align == 'Right') {
            return { right: -2 };
        } else {
            return { left: -2 };
        }
    };

    const focusWidth = () => {
        return getPressableStyling(styling.components.button._id, appContext).borderWidth * 2;
    };
    if (data == undefined) {
        return null;
    } else {
        return (
            <Pressable
                style={[
                    {
                        ...getPressableStyling(styling.components.button._id, appContext),
                        marginBottom: getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 5 : 4),
                        width: width - getCorrectWidth(deviceContext, 5),
                        marginHorizontal: getCorrectWidth(deviceContext, 2.5),
                    },
                ]}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                onFocus={() => onFocus(data)}
                onBlur={() => onBlur()}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => onPress(data?._id)}
                onLongPress={() => onLongPress(data?._id)}
                focusOptions={focusOptions}
            >
                <View>
                    {(type == 'Channels' || screenName == 'Channels' || type == 'Cams' || screenName == 'Cams' || type == 'Radio' || screenName == 'Radio') && appContext.application.theme.icons.show_live && (
                        <View
                            style={{
                                position: 'absolute',
                                top: 5,
                                left: 5,
                                width: '100%',
                                flexDirection: 'row',
                            }}
                        >
                            <>{getLiveTag(lang.getTranslation(userContext, 'live').toUpperCase(), appContext, deviceContext)}</>
                            {data?.catchuptv && <>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</>}
                        </View>
                    )}
                    {styling.ribbon != undefined && styling.ribbon.show_ribbon && (
                        <View style={[getExtraStyle(), { position: 'absolute', zIndex: 9999 }]}>
                            <View
                                style={{
                                    padding: getCorrectWidth(deviceContext, 3),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: styling.ribbon.background_color,

                                    borderRadius: getCorrectHeight(deviceContext, styling.ribbon.border_radius),
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.ribbon.title_color,
                                        fontWeight: styling.ribbon.title_bold ? 'bold' : 'normal',
                                    }}
                                >
                                    {getRibbonText()}
                                </Text>
                            </View>
                        </View>
                    )}
                    <View
                        style={{
                            margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                        
                            backgroundColor: appContext.application.theme.images?.background,
                            width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - getCorrectWidth(deviceContext, 5) - focusWidth(),
                        }}
                    >
                        <Image
                          isCache={true}
                            deviceContext={deviceContext}
                            source={{ uri: appContext.cloudUrl + data?.images.tile }}
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            style={{
                                borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - getCorrectWidth(deviceContext, 5) - focusWidth(),
                                width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - getCorrectWidth(deviceContext, 5) - focusWidth(),
                            }}
                        />
                        {appContext.application.theme.icons.show_favorite && favorite && (
                            <View
                                style={{
                                    position: 'absolute',
                                    right: getCorrectHeight(deviceContext, 5),
                                    top: getCorrectHeight(deviceContext, 5),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    backgroundColor: '#333',
                                    borderRadius: 100,
                                    width: width / 6,
                                    height: width / 6,
                                }}
                            >
                                <FontAwesomeIcon size={width / 9} style={{ color: appContext.application.theme.icons.background }} icon={faHeart} />
                            </View>
                        )}
                        {appContext.application.theme.icons.show_play && styling.content.styling.content.play_directly && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: getCorrectHeight(deviceContext, 25),
                                    right: 0,
                                    left: 0,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <View
                                    style={{
                                        borderRadius: 100,
                                        height: width / 5,
                                        width: width / 5,
                                        backgroundColor: 'rgba(0, 0, 0, 0.30)',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon size={width / 10} style={{ color: appContext.application.theme.icons.background }} icon={faPlay} />
                                </View>
                            </View>
                        )}
                        {styling.texts.placement_text != 'Below' && (
                            <View
                                style={{
                                    position: 'absolute',
                                    bottom: appContext.application.theme.images.margin / 2,
                                    left: appContext.application.theme.images.margin,
                                    right: appContext.application.theme.images.margin,
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        color: styling.texts.title_color,
                                        fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {data?.name}
                                </Text>
                            </View>
                        )}
                        <View
                            style={{
                                position: 'absolute',
                                bottom: getCorrectHeight(deviceContext, 2),
                                left: getCorrectHeight(deviceContext, 2),
                                right: getCorrectHeight(deviceContext, 2),
                            }}
                        >
                            {data?.progress > 0 && (
                                <View
                                    style={{
                                        marginVertical: getCorrectHeight(deviceContext, 2),
                                        backgroundColor: appContext.application.theme.progresses.base,
                                        height: getCorrectHeight(deviceContext, 1),

                                        width: '100%',
                                    }}
                                >
                                    <View
                                        style={{
                                            backgroundColor: appContext.application.theme.progresses.progress,
                                            height: getCorrectHeight(deviceContext, 2),
                                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                                            shadowOffset: { width: -1, height: 1 },
                                            shadowRadius: 2,

                                            width: data?.progress + '%',
                                        }}
                                    ></View>
                                </View>
                            )}
                            {data?.progress == 0 && (
                                <View
                                    style={{
                                        marginVertical: 4,
                                        backgroundColor: 'transparent',
                                        height: getCorrectHeight(deviceContext, 2),
                                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                                        shadowOffset: { width: -1, height: 1 },
                                        shadowRadius: 2,

                                        width: '100%',
                                    }}
                                ></View>
                            )}
                        </View>

                        {styling.texts.placement_text == 'Below' && (
                            <View
                                style={{
                                    marginTop: appContext.application.theme.images.margin > 0 ? 0 : getCorrectHeight(deviceContext, 5),
                                    marginHorizontal: appContext.application.theme.images.margin > 0 ? getCorrectWidth(deviceContext, appContext.application.theme.images.margin) : getCorrectHeight(deviceContext, 5),
                                    marginBottom: getCorrectHeight(deviceContext, 5),
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        // width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin),
                                        color: styling.texts.title_color,
                                        fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {data?.name}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            </Pressable>
        );
    }
};
export default Tile;
