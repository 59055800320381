import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import { AppContext } from '../../../context/appContext';
import { ContentContext } from '../../../context/contentContext';
import { DeviceContext } from '../../../context/deviceContext';
import { UserContext } from '../../../context/userContext';
import { favoriteStatus, watchingStatus } from '../../../data/account';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { getFontSize } from '../../../styling/fontSizes';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import Image from '../../components/image/image';
import Pressable from '../../components/pressable/pressable';
import Text from '../../components/text';


export interface Data {
    name: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    _id: string;
}

export interface Props {
    fontDensity?: number;
    focusContext: any;
    focusOptions?: any;
    focusRepeatContext: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
}

export const Rectangle = ({ fontDensity, focusContext, focusRepeatContext, noMargin, height, width, onPress, styling, type, data, onLongPress, focusOptions, heroIsPlayer }: Props) => {
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const contentContext = React.useContext(ContentContext);
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };

    if (fontDensity == undefined) {
        fontDensity = 1;
    }
    var favorite = favoriteStatus(type, data?._id, userContext);
    var progress = 0;

    if (type == 'Movies') {
        try {
            var watching = watchingStatus('Movies', data?._id, userContext);
            progress = Math.round((watching.position / watching.duration) * 100);
        } catch (e) {}
    }
    const getRibbonText = () => {
        var test = styling.ribbon.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.title != undefined) {
                return styling.content.title[0].title;
            } else {
                return '';
            }
        }
    };
    const getExtraStyle = () => {
        if (styling.ribbon.align == 'Right') {
            return { right: -2 };
        } else {
            return { left: -2 };
        }
    };

    const focusWidth = () => {
        return getPressableStyling(styling.components.button._id, appContext).borderWidth * 2;
    };

    if (data == undefined) {
        return null;
    } else {
        return (
            <Pressable
                style={[
                    {
                        ...getPressableStyling(styling.components.button._id, appContext),
                        marginBottom: getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 5 : 4),
                        marginHorizontal: getCorrectWidth(deviceContext, 2.5),
                        width: width,
                    },
                ]}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                onFocus={() => onFocus(data)}
                onBlur={() => onBlur()}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => onPress(data?._id)}
                onLongPress={() => onLongPress(data?._id)}
                focusOptions={focusOptions}
            >
                <View>
                    {styling.ribbon != undefined && styling.ribbon.show_ribbon && (
                        <View style={[getExtraStyle(), { position: 'absolute', zIndex: 9999 }]}>
                            <View
                                style={{
                                    padding: getCorrectWidth(deviceContext, 3),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: styling.ribbon.background_color,

                                    borderRadius: getCorrectHeight(deviceContext, styling.ribbon.border_radius),
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.ribbon.title_color,
                                        fontWeight: styling.ribbon.title_bold ? 'bold' : 'normal',
                                    }}
                                >
                                    {getRibbonText()}
                                </Text>
                            </View>
                        </View>
                    )}
                    <View
                        style={{
                            margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,

                            backgroundColor: appContext.application.theme.images?.background,
                            alignItems: 'center',
                            height: (width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth()) * 1.5,
                        }}
                    >
                        <Image
                          isCache={true}
                            deviceContext={deviceContext}
                            source={{ uri: appContext.cloudUrl + data?.images?.poster }}
                            resizeMethod={'resize'}
                            resizeMode={'cover'}
                            style={{
                                borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,

                                width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth(),
                                height: (width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth()) * 1.5,
                            }}
                        />

                        {favorite && appContext.application.theme.icons.show_favorite && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: 5,
                                    right: 5,
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'row',
                                }}
                            >
                                <FontAwesomeIcon size={width / 8} style={{ color: appContext.application.theme.icons.background }} icon={faHeart} />
                            </View>
                        )}
                        {appContext.application.theme.icons.show_play && styling.content.styling.content.play_directly && (
                            <View
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <View
                                    style={{
                                        borderRadius: 100,
                                        height: width / 3,
                                        width: width / 3,
                                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon size={width / 6} style={{ color: appContext.application.theme.icons.background }} icon={faPlay} />
                                </View>
                            </View>
                        )}
                        {styling.texts.placement_text == 'Above' && (
                            <View
                                style={{
                                    position: 'absolute',
                                    bottom: getCorrectWidth(deviceContext, 5),
                                    left: getCorrectWidth(deviceContext, 5),
                                    right: appContext.application.theme.images.margin,
                                }}
                            >
                                <Text
                                    numberOfLines={1}
                                    style={{
                                        // width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 3),
                                        color: styling.texts.title_color,
                                        fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                        textShadowOffset: { width: -1, height: 1 },
                                        textShadowRadius: 2,
                                    }}
                                >
                                    {data?.name}
                                </Text>
                            </View>
                        )}
                        <View
                            style={{
                                position: 'absolute',
                                bottom: getCorrectHeight(deviceContext, 0),
                                left: getCorrectHeight(deviceContext, 2),
                                right: getCorrectHeight(deviceContext, 2),
                            }}
                        >
                            {progress > 0 && (
                                <View
                                    style={{
                                        marginVertical: getCorrectHeight(deviceContext, 2),
                                        backgroundColor: appContext.application.theme.progresses.base,
                                        height: getCorrectHeight(deviceContext, 2),
                                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                                        shadowOffset: { width: -1, height: 1 },
                                        shadowRadius: 2,
                                        width: '100%',
                                    }}
                                >
                                    <View
                                        style={{
                                            backgroundColor: appContext.application.theme.progresses.progress,
                                            height: getCorrectHeight(deviceContext, 2),
                                            shadowColor: 'rgba(0, 0, 0, 0.3)',
                                            shadowOffset: { width: -1, height: 1 },
                                            shadowRadius: 2,
                                            width: progress + '%',
                                        }}
                                    ></View>
                                </View>
                            )}
                        </View>
                    </View>

                    {styling.texts.placement_text == 'Below' && (
                        <View
                            style={{
                                marginTop: appContext.application.theme.images.margin > 0 ? 0 : getCorrectHeight(deviceContext, 5),
                                marginHorizontal: appContext.application.theme.images.margin > 0 ? getCorrectWidth(deviceContext, appContext.application.theme.images.margin) : getCorrectHeight(deviceContext, 5),
                                marginBottom: getCorrectHeight(deviceContext, 5),
                            }}
                        >
                            <Text
                                numberOfLines={1}
                                style={{
                                    //  width: width - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 3),
                                    color: styling.texts.title_color,
                                    fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {data?.name}
                            </Text>
                        </View>
                    )}
                </View>
            </Pressable>
        );
    }
};
export default Rectangle;
