import {  View, Image as NormalImage } from '@scriptx-com/xtv-toolkit';
import { getCorrectHeight, getCorrectWidth } from '../../../../styling/correctSizes';
import { getFocusStyling, getPressableStyling, getPressableTextStyling, getSelectedStyling } from '../../../components/helpers/helper';
import Pressable from '../../../components/pressable/pressable';
import Text from '../../../components/text/index';
import { getFontSize } from '../../../../styling/fontSizes';
import lang from '../../../../languages/languages';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faClapperboardPlay, faHeart, faPlus, faCheck } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { favoriteStatus, recordStatus, remindStatus, watchingStatus } from '../../../../data/account';
import moment from 'moment';
import { getLiveImage } from '../../players/helpers/functions';
import { getRealHeight, getRealWidth } from '../../../../styling/realDeviceSizes';
import { RemoteImageHeightWidth } from '../../../components/remoteimage/remoteimage';
import Image from '../../../components/image/image';
import DownloadButton from '../../../components/downloadbutton/downloadbutton';
import React from 'react';
import { colorShade } from '../../../../styling/colorShade';
import { getCorrectLeftMargin } from '../../../../styling/appLeftMargin';


export const BackgroundImage = ({ item, program, type, deviceContext, appContext, contentContext }) => {
    return (
        <View
            style={{
                flex: 1,
                position: 'absolute',
                zIndex: 1,
            }}
        >
            <View
                style={{
                    width: getRealWidth(deviceContext),
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden',
                }}
            >
                {type == 'Channels' && getProgramImage(program, item, deviceContext, appContext)}
                {type == 'Movies' && getMovieImage(item, deviceContext, appContext)}
                {type == 'Series' && getSeriesImage(item, deviceContext, appContext, contentContext)}
                {type == 'Courses' && (
                    <NormalImage
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        FadeZoom
                        style={{
                            width: getRealWidth(deviceContext),
                            height: getRealHeight(deviceContext),
                        }}
                        source={{ uri: appContext.cloudUrl + item.images?.widescreen }}
                    />
                )}
                {type == 'Shorts' && (
                    <NormalImage
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        FadeZoom
                        style={{
                            width: getRealWidth(deviceContext),
                            height: getRealHeight(deviceContext),
                        }}
                        source={{
                            uri: item.images?.background,
                        }}
                    />
                )}
            </View>
        </View>
    );
};
export const BackgroundImageBoxed = ({ item, program, type, styling, deviceContext, appContext, contentContext }) => {
    var left = (getCorrectLeftMargin(deviceContext, appContext, 'type') + 10 + global.menuWidth / (deviceContext.isWebTV ? 1 : 2)) * 2;
    var leftInner = (isNaN(left) ? 0 : left) + 6;
    return (
        <View
            style={{
                flex: 1,
                position: 'absolute',
                zIndex: 1,
                borderWidth: 3,
                borderColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                borderRadius: 10,
                width: getRealWidth(deviceContext) - left,
            }}
        >
            <View
                style={{
                    position: 'absolute',
                    width: getRealWidth(deviceContext) - leftInner,
                    height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                    zIndex: 2,
                }}
            >
                <Image
                    isLocal={true}
                    deviceContext={deviceContext}
                    source={require('../../../../../assets/images/blackis.png')}
                    resizeMode={'cover'}
                    style={{
                        borderRadius: 5,
                        height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                        width: getRealWidth(deviceContext) - leftInner,
                        opacity: deviceContext.isWebTV ? 0.6 : 0.9,
                    }}
                ></Image>
            </View>
            <View
                style={{
                    width: getRealWidth(deviceContext) - leftInner,
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden',
                }}
            >
                {type == 'Channels' && getProgramImageBoxed(program, item, deviceContext, appContext, leftInner)}
                {type == 'Movies' && getMovieImageBoxed(item, deviceContext, appContext, leftInner)}
                {type == 'Series' && getSeriesImageBoxed(item, deviceContext, appContext, contentContext, leftInner)}
                {type == 'Courses' && (
                    <NormalImage
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        FadeZoom
                        style={{
                            height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                            width: getRealWidth(deviceContext) - leftInner,
                        }}
                        source={{ uri: appContext.cloudUrl + item.images?.widescreen }}
                    />
                )}
                {type == 'Shorts' && (
                    <NormalImage
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'cover'}
                        FadeZoom
                        style={{
                            height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                            width: getRealWidth(deviceContext) - leftInner,
                        }}
                        source={{
                            uri: item.images?.background,
                        }}
                    />
                )}
            </View>
        </View>
    );
};
export const LogoRow = ({ item, program, type, deviceContext, appContext, contentContext, styling }) => {
    if (type == 'Channels') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.7 }}>
                <View>
                    <Image
                        deviceContext={deviceContext}
                        resizeMethod={'scale'}
                        resizeMode={'contain'}
                        source={{ uri: appContext.cloudUrl + item?.images?.square }}
                        style={{
                            width: getCorrectWidth(deviceContext, 35),
                            height: getCorrectWidth(deviceContext, 35),
                        }}
                    ></Image>
                </View>
                <View>
                    {program?.l != undefined && program?.l != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <RemoteImageHeightWidth uri={program?.l} desiredWidth={getRealWidth(deviceContext) * 0.25} desiredHeight={50} deviceContext={deviceContext} />
                        </View>
                    )}
                    {(program?.l == undefined || program?.l == '') && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                numberOfLines={1}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {program.n}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Movies' || type == 'Courses' || type == 'Shorts' || type == 'Series') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View>
                    {item.images.logo != undefined && item.images.logo != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <RemoteImageHeightWidth uri={appContext.cloudUrl + item?.images.logo} desiredWidth={getRealWidth(deviceContext) * 0.25} desiredHeight={50} deviceContext={deviceContext} />
                        </View>
                    )}
                    {(item.images.logo == undefined || item.images.logo == '') && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                numberOfLines={2}
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraExtraLarge'),
                                    fontWeight: styling.texts.title_bold ? 'bold' : null,
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.name}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    }
};
export const StatusTimeRow = ({ item, program, type, deviceContext, appContext, userContext, contentContext, styling }) => {
    if (type == 'Channels') {
        var currentTime = moment().unix();
        var isLive = program.s < currentTime && program.e > currentTime;
        var isPast = program.s < currentTime && program.e < currentTime;
        var isRecorded = recordStatus(program.i, userContext);
        var isReminded = remindStatus(program.i, userContext);
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 5), marginTop: getCorrectHeight(deviceContext, 5) }}>
                    {isLive && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'live').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isRecorded && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'crimson',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'recorded').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isReminded && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'royalblue',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'reminded').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isPast && item.interactivetv.catchuptv_enabled && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'royalblue',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'replay').toUpperCase()}
                            </Text>
                        </View>
                    )}
                    {isLive && item && item.interactivetv.pausetv_enabled && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 2) }}>
                            <Text
                                style={{
                                    backgroundColor: 'royalblue',
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {lang.getTranslation(userContext, 'restart').toUpperCase()}
                            </Text>
                        </View>
                    )}

                    {(isLive || (isPast && item.interactivetv.catchuptv_enabled) || isReminded) && (
                        <View style={{ justifyContent: 'center', marginLeft: getCorrectWidth(deviceContext, 2) }}>
                            <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>
                        </View>
                    )}
                    <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5), marginLeft: isLive || (isPast && item.interactivetv.catchuptv_enabled) ? 5 : 0 }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                color: styling.texts.title_color,
                                includeFontPadding: false,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {moment.unix(program?.s).format('ll ' + deviceContext.clockSetting) + ' - ' + moment.unix(program?.e).format(deviceContext.clockSetting)}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};
export const MetaRow = ({ item, program, type, deviceContext, appContext, userContext, contentContext, styling }) => {
    if (type == 'Channels') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row' }}>
                    {program.mt?.ye != undefined && program.mt?.ye != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {program?.mt?.ye}
                            </Text>
                        </View>
                    )}
                    {program.mt?.ye != undefined && program.mt?.ye != '' && program?.mt?.ge != undefined && program?.mt?.ge.length > 0 && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {program?.mt?.ge != undefined && program?.mt?.ge.length > 0 && <View>{getTagsChannels(program?.mt?.ge, appContext, deviceContext, userContext)}</View>}

                    {program?.p != undefined && program?.p != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {program?.p != undefined && program?.p != '' && (
                        <View style={{ justifyContent: 'center' }}>
                            <Text
                                style={{
                                    backgroundColor: appContext.application.theme.tags.background,
                                    color: appContext.application.theme.tags.color,
                                    borderRadius: appContext.application.theme.tags.radius / 2,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    margin: getCorrectWidth(deviceContext, 2),
                                    padding: getCorrectWidth(deviceContext, 2),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    includeFontPadding: false,
                                }}
                            >
                                {program?.p}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Movies' || type == 'Courses' || type == 'Shorts' || type == 'Series') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 15) }}>
                    {item.released != undefined && item.released != '' && (
                        <View style={{ justifyContent: 'center', marginRight: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {item.released}
                            </Text>
                        </View>
                    )}
                    {item.released != undefined && item.released != '' && item.tags != undefined && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.tags != undefined && <View>{getTags(item.tags, appContext, deviceContext, userContext)}</View>}
                    {item.tags != undefined && item.runtime != undefined && item.runtime != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.runtime != undefined && item.runtime != '' && (
                        <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'Small'),
                                    color: styling.texts.title_color,
                                    includeFontPadding: false,
                                    textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                    textShadowOffset: { width: -1, height: 1 },
                                    textShadowRadius: 2,
                                }}
                            >
                                {moment.utc().startOf('day').add({ minutes: item.runtime }).format('H')}
                                {'h:'}
                                {moment.utc().startOf('day').add({ minutes: item.runtime }).format('mm')}
                                {'m'}
                            </Text>
                        </View>
                    )}
                    {item.runtime != undefined && item.runtime != '' && item.rating != undefined && item.rating != '' && <DotInBetween deviceContext={deviceContext} styling={styling}></DotInBetween>}
                    {item.rating != undefined && item.rating != '' && (
                        <Text
                            style={{
                                backgroundColor: appContext.application.theme.tags.background,
                                color: appContext.application.theme.tags.color,
                                borderRadius: appContext.application.theme.tags.radius / 2,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                margin: getCorrectWidth(deviceContext, 2),
                                padding: getCorrectWidth(deviceContext, 2),
                                paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                includeFontPadding: false,
                            }}
                        >
                            {item.rating}
                        </Text>
                    )}
                </View>
            </View>
        );
    }
};
export const DescriptionRow = ({ item, program, type, deviceContext, userContext, contentContext, styling }) => {
    // if (type == 'Channels') {
    //     return (
    //         <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
    //             <View>
    //                 <Text
    //                     numberOfLines={3}
    //                     style={{
    //                         fontFamily: deviceContext.fontType,
    //                         fontSize: getFontSize(deviceContext, 'Normal'),
    //                         color: styling.texts.subtext_color,
    //                         textShadowColor: 'rgba(0, 0, 0, 0.2)',
    //                         textShadowOffset: { width: -1, height: 1 },
    //                         textShadowRadius: 2,
    //                     }}
    //                 >
    //                     {program?.d}
    //                 </Text>
    //             </View>
    //         </View>
    //     );
    // } else
    if (type == 'Movies' || type == 'Courses' || type == 'Shorts') {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View>
                    {item.translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                        <Text
                            numberOfLines={3}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.translations?.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                        </Text>
                    )}
                    {item.translations.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations.length > 0 && (
                        <Text
                            numberOfLines={3}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.translations[0]?.description}
                        </Text>
                    )}
                </View>
            </View>
        );
    } else if (type == 'Series') {
        var watchlist = watchingStatus(type, item._id, userContext);
        var episodeIndex = 0;
        var seasonIndex = 0;
        if (watchlist != undefined) {
            episodeIndex = watchlist.continue.episode_index ?? 0;
            seasonIndex = watchlist.continue.season_index ?? 0;
        }
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.5 }}>
                <View>
                    {item.seasons[seasonIndex].translations.find((trans) => trans.name == userContext.selectedLanguage) != undefined && (
                        <Text
                            numberOfLines={3}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.seasons[seasonIndex].translations?.find((trans) => trans.name == userContext.selectedLanguage)?.description}
                        </Text>
                    )}
                    {item.seasons[seasonIndex].translations.find((trans) => trans.name == userContext.selectedLanguage) == undefined && item.translations.length > 0 && (
                        <Text
                            numberOfLines={3}
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),

                                color: styling.texts.subtext_color,
                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                textShadowOffset: { width: -1, height: 1 },
                                textShadowRadius: 2,
                            }}
                        >
                            {item.seasons[seasonIndex].translations[0]?.description}
                        </Text>
                    )}
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};
export const ProgressRow = ({ item, program, type, deviceContext, appContext, userContext, contentContext, styling }) => {
    var progress = 0;
    var remaining = 0;
    if (type == 'Channels') {
        var currentTime = moment().unix();
        var totalProgram = program?.e - program?.s;
        progress = ((currentTime - program?.s) / totalProgram) * 100;
        var isLive = program.s < currentTime && program.e > currentTime;
        remaining = totalProgram - (currentTime - program?.s);
        if (!isLive) {
            progress = 0;
        }
    }
    if (type == 'Movies' || type == 'Courses' || type == 'Shorts') {
        var watching = watchingStatus(type, item._id, userContext);
        if (watching != undefined && watching.position != undefined) {
            progress = Math.round((watching.position / watching.duration) * 100);
            remaining = watching.duration - watching.position;
        }
    }
    if (type == 'Series') {
        //
    }
    if (progress > 0) {
        return (
            <View style={{ flexDirection: 'column', width: getRealWidth(deviceContext) * 0.22, marginTop: getCorrectHeight(deviceContext, 15) }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <View
                        style={{
                            backgroundColor: appContext.application.theme.progresses.base,
                            height: getCorrectHeight(deviceContext, 1),
                            width: getRealWidth(deviceContext) * 0.22,
                        }}
                    >
                        <View
                            style={{
                                backgroundColor: appContext.application.theme.progresses.progress,
                                height: getCorrectHeight(deviceContext, 1),
                                width: progress + '%',
                            }}
                        ></View>
                    </View>
                    <View style={{ marginLeft: getCorrectWidth(deviceContext, 2), justifyContent: 'center' }}>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Small'),
                                fontWeight: styling.texts.title_bold ? 'bold' : null,
                                color: styling.texts.title_color,
                                includeFontPadding: false,
                            }}
                        >
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('H')}
                            {'h:'}
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('mm')}
                            {'m'}
                            {moment.utc().startOf('day').add({ seconds: remaining }).format('ss')}
                            {'s'} {lang.getTranslation(userContext, 'left')}
                        </Text>
                    </View>
                </View>
            </View>
        );
    } else {
        return <></>;
    }
};
export const ButtonRow = ({
    item,
    program,
    type,
    deviceContext,
    appContext,
    userContext,
    styling,
    onPressPlay,
    onPressTrailer,
    onPressPurchase,
    onPressFavorite,
    onPressWatchlist,
    onPressContinue,
    onPressPlayCatchupTV,
    onPressPlaySeason,
    onPressInformation,
    focusContext,
    hasPreferredFocus = true,
    focusable = true,
    onFocus,
    focusOptions,
}: any) => {
    var isFavorite = favoriteStatus(type, item._id, userContext);
    var watchlist = watchingStatus(type, item._id, userContext);
    var isWatchlist = watchlist == undefined ? false : true;
    var hasProgress = watchlist != undefined && watchlist?.position > 0 ? true : false;

    switch (type) {
        case 'Movies':
            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {!hasProgress && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={hasPreferredFocus}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressInformation(item)}
                            text={'information'}
                            focusOptions={{ focusKey: 'btn-main', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}
                    {hasProgress && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            deviceContext={true}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressContinue(item)}
                            text={'continue'}
                            focusOptions={{ focusKey: 'btn-main', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}

                    {item.payperview != undefined && item.payperview == true && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                            focusOptions={{ focusKey: 'purchase_option', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}

                    {(item.streams.movies.trailer != undefined || (item.trailer != undefined && item.trailer != '')) && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'trailer', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressTrailer(item)}
                            icon={faClapperboardPlay}
                        ></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'favorites', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressFavorite(isFavorite, item)}
                            icon={isFavorite ? faHeartSolid : faHeart}
                        ></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'watchlist', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressWatchlist(isWatchlist, item, seasonIndex, episodeIndex)}
                            icon={isWatchlist ? faCheck : faPlus}
                        ></ButtonIcon>
                    )}
                    {item.streams.movies.allow_downloads && userContext.userId.indexOf('_review') < 0 && (deviceContext.isTablet || deviceContext.isWebTV) && (
                        <DownloadButton focusOptions={{ focusKey: 'download', ...focusOptions }} focusable={focusable} styling={styling} screenName={type} item={item}></DownloadButton>
                    )}
                </View>
            );
            break;
        case 'Courses':
            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {!hasProgress && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={true}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressInformation(item)}
                            text={'information'}
                            focusOptions={{ focusKey: 'btn-main', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}
                    {hasProgress && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={true}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressContinue(item)}
                            text={'continue'}
                            focusOptions={{ focusKey: 'btn-main', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}
                    {item.payperview != undefined && item.payperview == true && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                            focusOptions={{ focusKey: 'purchase_option', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}

                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'watchlist', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressFavorite(isFavorite, item)}
                            icon={isFavorite ? faHeartSolid : faHeart}
                        ></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'favorites', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressWatchlist(isWatchlist, item)}
                            icon={isWatchlist ? faCheck : faPlus}
                        ></ButtonIcon>
                    )}
                </View>
            );
            break;
        case 'Shorts':
            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {!hasProgress && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={true}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressInformation(item)}
                            text={'information'}
                            focusOptions={{ focusKey: 'btn-main', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}
                    {hasProgress && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={true}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressContinue(item)}
                            text={'continue'}
                            focusOptions={{ focusKey: 'btn-main', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}
                    {item.payperview != undefined && item.payperview == true && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                            focusOptions={{ focusKey: 'purchase_option', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}

                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'favorites', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressFavorite(isFavorite, item)}
                            icon={isFavorite ? faHeartSolid : faHeart}
                        ></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'watchlist', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressWatchlist(isWatchlist, item)}
                            icon={isWatchlist ? faCheck : faPlus}
                        ></ButtonIcon>
                    )}
                    {item.streams.shorts.allow_downloads && userContext.userId.indexOf('_review') < 0 && (deviceContext.isTablet || deviceContext.isWebTV) && (
                        <DownloadButton focusOptions={{ focusKey: 'download', ...focusOptions }} focusable={focusable} styling={styling} screenName={type} item={item}></DownloadButton>
                    )}
                </View>
            );
            break;
        case 'Series':
            var episodeIndex = 0;
            var seasonIndex = 0;
            hasProgress = watchlist?.continue != undefined && watchlist?.continue?.position > 0 ? true : false;
            if (watchlist != undefined && hasProgress) {
                episodeIndex = watchlist.continue.episode_index;
                seasonIndex = watchlist.continue.season_index;
            }
            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {!hasProgress && (
                        <ButtonSeasonPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={true}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressInformation(item)}
                            text={'information'}
                            season={seasonIndex}
                            episode={episodeIndex}
                            focusOptions={{ focusKey: 'btn-main', ...focusOptions }}
                        ></ButtonSeasonPlusIcon>
                    )}
                    {hasProgress && (
                        <ButtonSeasonPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={true}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlaySeason(seasonIndex, episodeIndex)}
                            text={'continue'}
                            season={seasonIndex}
                            episode={episodeIndex}
                            focusOptions={{ focusKey: 'btn-main', ...focusOptions }}
                        ></ButtonSeasonPlusIcon>
                    )}
                    {item.payperview != undefined && item.payperview == true && (
                        <ButtonTextPlusIcon
                            focusable={focusable}
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                            focusOptions={{ focusKey: 'purchase_option', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}

                    {item.trailer != undefined && item.trailer != '' && (
                        <ButtonIcon
                            focusable={focusable}
                            focusOptions={focusOptions}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressTrailer(item)}
                            icon={faClapperboardPlay}
                        ></ButtonIcon>
                    )}

                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'favorites', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressFavorite(isFavorite, item)}
                            icon={isFavorite ? faHeartSolid : faHeart}
                        ></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'watchlist', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressWatchlist(isWatchlist, item, seasonIndex, episodeIndex)}
                            icon={isWatchlist ? faCheck : faPlus}
                        ></ButtonIcon>
                    )}
                </View>
            );
            break;
        case 'Channels':
            var currentTime = moment().unix();
            var isLive = program.s < currentTime && program.e > currentTime;

            return (
                <View style={{ flexDirection: 'row', marginTop: getCorrectHeight(deviceContext, 15) }} focusContext={focusContext}>
                    {isLive && (
                        <ButtonTextPlusIcon
                            focusContext={focusContext}
                            hasPreferredFocus={hasPreferredFocus}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlay(item)}
                            onFocus={onFocus}
                            text={'watch_now'}
                            focusable={focusable}
                            focusOptions={{ focusKey: 'btn-main', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}
                    {isLive && item.interactivetv.pausetv_enabled && (
                        <ButtonTextPlusIcon
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={() => onPressPlayCatchupTV('live', item, -1)}
                            text={'replay'}
                            focusable={focusable}
                            focusOptions={{ focusKey: 'replay', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}
                    {item.payperview != undefined && item.payperview == true && (
                        <ButtonTextPlusIcon
                            focusContext={focusContext}
                            hasPreferredFocus={false}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            styling={styling}
                            onPress={onPressPurchase}
                            text={'purchase_option'}
                            focusable={focusable}
                            focusOptions={{ focusKey: 'purchase_option', ...focusOptions }}
                        ></ButtonTextPlusIcon>
                    )}
                    {appContext.application.settings.allow_favorites && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'favorites', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressFavorite(isFavorite, item)}
                            icon={isFavorite ? faHeartSolid : faHeart}
                        ></ButtonIcon>
                    )}
                    {appContext.application.settings.allow_watchlist && (
                        <ButtonIcon
                            focusOptions={{ focusKey: 'watchlist', ...focusOptions }}
                            focusable={focusable}
                            focusContext={focusContext}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            styling={styling}
                            onPress={() => onPressWatchlist(isWatchlist, item)}
                            icon={isWatchlist ? faCheck : faPlus}
                        ></ButtonIcon>
                    )}
                </View>
            );
            break;
        default:
            return <View></View>;
            break;
    }
};
export const Pager = ({ selected, total, deviceContext, appContext, styling, onPagingPress }) => {
    var paging = [] as any;
    for (let index_ = 0; index_ < total; index_++) {
        paging.push(
            <Pressable
                style={{
                    height: getCorrectHeight(deviceContext, 4),
                    width: getCorrectHeight(deviceContext, 4),
                    ...getPressableStyling(styling.components?.button?._id, appContext),
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    margin: getCorrectWidth(deviceContext, 5),
                }}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onPress={() => onPagingPress(index_)}
            >
                <View
                    key={index_}
                    style={{
                        borderRadius: 100,
                        backgroundColor: selected == index_ ? getSelectedStyling('Navigators', appContext).background_color : getPressableTextStyling(styling.components?.button?._id, appContext).color,
                        height: getCorrectWidth(deviceContext, 4),
                        width: getCorrectWidth(deviceContext, 4),
                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                        shadowOffset: { width: -1, height: 1 },
                        shadowRadius: 2,
                    }}
                ></View>
            </Pressable>
        );
    }
    return paging;
};

///extra functions
const ButtonTextPlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, focusContext, hasPreferredFocus, onFocus, focusOptions, focusable }: any) => {
    return (
        <Pressable
            focusContext={focusContext}
            hasPreferredFocus={hasPreferredFocus}
            style={{
                marginRight: getCorrectHeight(deviceContext, 7),
                height: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
            }}
            focus={focusable}
            focusOptions={{
                ...focusOptions,
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
            onFocus={onFocus}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'row',
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Normal'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)}
                </Text>
            </View>
        </Pressable>
    );
};
const ButtonSeasonPlusIcon = ({ deviceContext, appContext, userContext, styling, onPress, text, season, episode, focusContext, hasPreferredFocus }) => {
    return (
        <Pressable
            focusContext={focusContext}
            hasPreferredFocus={hasPreferredFocus}
            style={{
                marginRight: getCorrectHeight(deviceContext, 7),
                height: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                justifyContent: 'center',
                alignItems: 'center',
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: getCorrectWidth(deviceContext, 20),
                    height: getCorrectHeight(deviceContext, 30),
                    flexDirection: 'row',
                }}
            >
                <Text
                    style={{
                        fontFamily: deviceContext.fontType,
                        fontSize: getFontSize(deviceContext, 'Normal'),
                        ...getPressableTextStyling(styling.components?.button?._id, appContext),
                    }}
                >
                    {lang.getTranslation(userContext, text)} {lang.getTranslation(userContext, 'season')} {season + 1} {lang.getTranslation(userContext, 'episode')} {episode + 1}
                </Text>
            </View>
        </Pressable>
    );
};
const ButtonIcon = ({ deviceContext, appContext, styling, onPress, icon, focusContext, focusable, focusOptions }: any) => {
    return (
        <Pressable
            focusContext={focusContext}
            focus={focusable}
            focusOptions={focusOptions}
            style={{
                opacity: 0.8,
                marginRight: getCorrectHeight(deviceContext, 7),
                height: getCorrectHeight(deviceContext, 30),
                width: getCorrectHeight(deviceContext, 30),
                ...getPressableStyling(styling.components?.button?._id, appContext),
                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor, 95),
                borderRadius: 100,
            }}
            animatorOptions={getFocusStyling('Buttons', appContext)}
            onPress={() => onPress()}
        >
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: getCorrectHeight(deviceContext, 30),
                    width: getCorrectHeight(deviceContext, 30),
                }}
            >
                <FontAwesomeIcon icon={icon} color={getPressableTextStyling(styling.components?.button?._id, appContext).color} size={getFontSize(deviceContext, 'Large')}></FontAwesomeIcon>
            </View>
        </Pressable>
    );
};
const getTags = (tags, appContext, deviceContext, userContext) => {
    var tagsOut = [] as any;
    if (tags != undefined && tags[0] != undefined && tags[0].tag != undefined) {
        tags.map((tag, index) => {
            if (tag.tag != undefined) {
                var test = tag.tag.find((t) => t.name == userContext.selectedLanguage);
                if (test == undefined) {
                    tag.name = tag[0].description;
                } else {
                    tag.name = test.description;
                }
            }
            if (index < 3) {
                tagsOut.push(
                    <Text
                        style={{
                            backgroundColor: appContext.application.theme.tags.background,
                            color: appContext.application.theme.tags.color,
                            borderRadius: appContext.application.theme.tags.radius / 2,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            margin: getCorrectWidth(deviceContext, 2),
                            padding: getCorrectWidth(deviceContext, 2),
                            paddingHorizontal: getCorrectWidth(deviceContext, 4),
                            includeFontPadding: false,
                        }}
                    >
                        {tag.name}
                    </Text>
                );
            }
        });
    }
    return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
};
const getTagsChannels = (tags, appContext, deviceContext, userContext) => {
    var tagsOut = [] as any;
    if (tags != undefined && tags.length > 0) {
        tags.map((tag, index) => {
            if (index < 3) {
                tagsOut.push(
                    <Text
                        style={{
                            backgroundColor: appContext.application.theme.tags.background,
                            borderRadius: appContext.application.theme.tags.radius / 2,
                            color: appContext.application.theme.tags.color,
                            fontSize: getFontSize(deviceContext, 'Small'),
                            margin: getCorrectWidth(deviceContext, 2),
                            padding: getCorrectWidth(deviceContext, 2),
                            paddingHorizontal: getCorrectWidth(deviceContext, 4),
                            includeFontPadding: false,
                        }}
                    >
                        {tag}
                    </Text>
                );
            }
        });
    }
    return <View style={{ flexGrow: 1, flexDirection: 'row' }}>{tagsOut}</View>;
};
const DotInBetween = ({ deviceContext, styling }) => {
    return (
        <View style={{ justifyContent: 'center', marginHorizontal: getCorrectWidth(deviceContext, 5) }}>
            <View style={{ width: getCorrectWidth(deviceContext, 3), height: getCorrectWidth(deviceContext, 3), backgroundColor: styling.texts.title_color, borderRadius: 100 }}></View>
        </View>
    );
};
const getProgramImage = (program, data, deviceContext, appContext) => {
    if (program != undefined && program?.m != '' && program?.m != null && program?.m.indexOf('https://') > -1) {
        return (
            <NormalImage
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: program.m }}
            />
        );
    } else {
        var test = checkIfPreviewIsWorking(data?.streams.channels.urls.standard_url) as any;
        return (
            <NormalImage
                blurRadius={test ? 0 : 95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: test ? getLiveImage(data?.streams.channels.urls.standard_url) : appContext.cloudUrl + data?.images?.square }}
            />
        );
    }
};
const getMovieImage = (item, deviceContext, appContext) => {
    if (item != undefined && item?.images?.background != '') {
        return (
            <NormalImage
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: appContext.cloudUrl + item?.images?.background }}
            />
        );
    } else {
        return (
            <NormalImage
                blurRadius={95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: appContext.cloudUrl + item?.images?.poster }}
            />
        );
    }
};
const getSeriesImage = (item, deviceContext, appContext, contentContext) => {
    if (item != undefined && item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.background != '') {
        return (
            <NormalImage
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: appContext.cloudUrl + item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.background }}
            />
        );
    } else {
        return (
            <NormalImage
                blurRadius={95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    width: getRealWidth(deviceContext),
                    height: getRealHeight(deviceContext),
                }}
                source={{ uri: appContext.cloudUrl + item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.poster }}
            />
        );
    }
};
const getProgramImageBoxed = (program, data, deviceContext, appContext, left) => {
    if (program != undefined && program?.m != '' && program?.m != null && program?.m.indexOf('https://') > -1) {
        return (
            <NormalImage
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderWidth: deviceContext.isWebTV ? 6 : 3,
                    borderColor: '#000000',
                    borderRadius: 6,
                    height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                    width: getRealWidth(deviceContext) - left,
                }}
                source={{ uri: program.m }}
            />
        );
    } else {
        var test = checkIfPreviewIsWorking(data?.streams.channels.urls.standard_url) as any;
        return (
            <NormalImage
                blurRadius={test ? 0 : 95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderWidth: deviceContext.isWebTV ? 6 : 3,
                    borderColor: '#000000',
                    borderRadius: 6,
                    height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                    width: getRealWidth(deviceContext) - left,
                }}
                source={{ uri: test ? getLiveImage(data?.streams.channels.urls.standard_url) : appContext.cloudUrl + data?.images?.square }}
            />
        );
    }
};
const getMovieImageBoxed = (item, deviceContext, appContext, left) => {
    if (item != undefined && item?.images?.background != '') {
        return (
            <NormalImage
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderWidth: deviceContext.isWebTV ? 6 : 3,
                    borderColor: '#000000',
                    borderRadius: 6,
                    height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                    width: getRealWidth(deviceContext) - left,
                }}
                source={{ uri: appContext.cloudUrl + item?.images?.background }}
            />
        );
    } else {
        return (
            <NormalImage
                blurRadius={95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderWidth: deviceContext.isWebTV ? 6 : 3,
                    borderColor: '#000000',
                    borderRadius: 6,
                    height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                    width: getRealWidth(deviceContext) - left,
                }}
                source={{ uri: appContext.cloudUrl + item?.images?.poster }}
            />
        );
    }
};
const getSeriesImageBoxed = (item, deviceContext, appContext, contentContext, left) => {
    if (item != undefined && item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.background != '') {
        return (
            <NormalImage
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderWidth: deviceContext.isWebTV ? 6 : 3,
                    borderColor: '#000000',
                    borderRadius: 6,
                    height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                    width: getRealWidth(deviceContext) - left,
                }}
                source={{ uri: appContext.cloudUrl + item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.background }}
            />
        );
    } else {
        return (
            <NormalImage
                blurRadius={95}
                deviceContext={deviceContext}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                FadeZoom
                style={{
                    borderWidth: deviceContext.isWebTV ? 6 : 3,
                    borderColor: '#000000',
                    borderRadius: 6,
                    height: getRealHeight(deviceContext) * (deviceContext.isWebTV ? 0.6 : 0.52),
                    width: getRealWidth(deviceContext) - left,
                }}
                source={{ uri: appContext.cloudUrl + item.seasons[contentContext.seriesData.seasonCategoryIndex].images?.poster }}
            />
        );
    }
};
const checkIfPreviewIsWorking = (url) => {
    fetch(url)
        .then((res) => {
            if (res.status == 404) {
                return false;
            } else {
                return true;
            }
        })
        .catch((err) => {
            return true;
        });
};
