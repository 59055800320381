import * as React from 'react';
import { View, Image, FlashList, CreateListRenderItemInfo } from '@scriptx-com/xtv-toolkit';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { ContentContext } from '../../../context/contentContext';
import { UserContext } from '../../../context/userContext';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Pressable from '../../components/pressable/pressable';
import { getFocusStyling, getPressableStyling, getPressableTextStyling } from '../../components/helpers/helper';
import Text from '../../components/text';
import { getFontSize } from '../../../styling/fontSizes';
import { getDetailsCourse, getDetailsMovie, getDetailsSeries, getDetailsShort } from '../rails/helpers/helper';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import lang from '../../../languages/languages';
import { useGridHeightCalculator } from '../../../hooks/useGridHeightCalculator';
import { SettingsContext } from '../../../context/settingsContext';
import { MediaCache } from 'react-native-theoplayer';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlay, faTrash, faDownload } from '@fortawesome/pro-light-svg-icons';
import { colorShade } from '../../../styling/colorShade';

export interface Props {
    styling: any;
    navigation: any;
    focusContext?: any;
}

export const List_Downloads = ({ styling, navigation, focusContext }: Props) => {
    const [show, setShow] = React.useState(false);
    const [noDownloads, setNoDownloads] = React.useState(false);
    const [dataList, setDataList] = React.useState([] as any);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const contentContext = React.useContext(ContentContext);

    const { tabBarHeight } = React.useContext(SettingsContext);
    const { onLayout, targetRef, gridHeight, paddingBottom } = useGridHeightCalculator();

    useEffectOnce(() => {
        const tasks = MediaCache.tasks as any;
        if (tasks != undefined) {
            setDataList(tasks);
            setShow(true);
        } else {
            setNoDownloads(true);
        }
    });

    const getCorrectTranslation = (translations) => {
        var normalArray = [] as any;
        if (deviceContext.isAndroid) {
            translations?.values?.forEach((element) => {
                normalArray.push(element.nameValuePairs);
            });
        } else {
            normalArray = translations;
        }
        return normalArray?.find((trans) => trans?.name == userContext.selectedLanguage)?.description;
    };

    const downloadRemove = async (item) => {
        setShow(false);
        const tasks = MediaCache.tasks as any;
        var task = tasks.find((f) => f.source.metadata._id == item.source.metadata._id);
        var tasksLeft = tasks.filter((f) => f.source.metadata._id != item.source.metadata._id);
        if (task != undefined) {
            await task.remove();
        }
        setDataList(tasksLeft);
        setShow(true);
    };
    const playItem = (item) => {
        if (item.source.metadata.type == 'Series') {
            getDetailsSeries(contentContext, item.source.metadata, navigation);
        }
        if (item.source.metadata.type == 'Movie') {
            getDetailsMovie(contentContext, item.source.metadata, navigation);
        }
        if (item.source.metadata.type == 'Course') {
            getDetailsCourse(contentContext, item.source.metadata, navigation);
        }
        if (item.source.metadata.type == 'Short') {
            getDetailsShort(contentContext, item.source.metadata, navigation);
        }
    };
    const renderListItem = ({ item, focusRepeatContext, index }: CreateListRenderItemInfo<any>) => {
        return (
            <View
                style={{
                    flexDirection: 'row',
                    margin: 5,
                    backgroundColor: getPressableStyling(styling.components.button._id, appContext).backgroundColor,
                    borderRadius: getPressableStyling(styling.components.button._id, appContext).borderRadius
                }}
            >
                <View>
                    {item.source.metadata.poster && (
                        <Image
                            deviceContext={deviceContext}
                            source={{ uri: appContext.cloudUrl + item.source.metadata.poster }}
                            resizeMethod={'resize'}
                            resizeMode={'cover'}
                            style={{
                                margin: getCorrectWidth(deviceContext, 4),
                                borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                height: deviceContext.isPhone ? 120 : 240,
                                width: deviceContext.isPhone ? 79.5 : 159,
                            }}
                        />
                    )}
                    {item.source.metadata.widescreen && (
                        <Image
                            deviceContext={deviceContext}
                            source={{ uri: appContext.cloudUrl + item.source.metadata.widescreen }}
                            resizeMethod={'resize'}
                            resizeMode={'cover'}
                            style={{
                                margin: getCorrectWidth(deviceContext, 4),
                                borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                height: 67.5,
                                width: 120,
                            }}
                        />
                    )}
                </View>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 5) }}>
                        <Text
                            numberOfLines={1}
                            style={{
                                color: styling.texts.title_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Large'),
                                marginBottom: 5,
                            }}
                        >
                            {item.source.metadata.name}
                        </Text>

                        <Text
                            numberOfLines={2}
                            style={{
                                color: styling.texts.title_color,
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'Normal'),
                                marginRight:10
                            }}
                        >
                            {getCorrectTranslation(item.source.metadata.translations)}
                        </Text>
                        <View style={{flexDirection:'row', justifyContent:'flex-end', alignContent:'flex-end', alignItems:'flex-end'}}>
                       {item.percentageCached == 1 && (
                            <Pressable
                                style={{
                                    marginVertical: getCorrectHeight(deviceContext, 5),
                                    marginRight: getCorrectHeight(deviceContext, 5),
                                    height: getCorrectHeight(deviceContext, 25),
                                    width: getCorrectHeight(deviceContext, 25),
                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                
                                    backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,95),
                                    borderRadius: 100
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => playItem(item)}
                            >
                                <>
                                    <View
                                        style={{
                                            width: getCorrectHeight(deviceContext, 25),
                                            height: getCorrectHeight(deviceContext, 25),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <FontAwesomeIcon
            
                                            icon={faPlay}
                                            color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                            size={getFontSize(deviceContext, 'Normal')}
                                        ></FontAwesomeIcon>

                           
                                    </View>
                                </>
                            </Pressable>
                        )}
                        {item.percentageCached < 1 && (
                            <Pressable
                                style={{
                                    marginVertical: getCorrectHeight(deviceContext, 5),
                                    marginRight: getCorrectHeight(deviceContext, 5),
                                    height: getCorrectHeight(deviceContext, 25),
                                    width: getCorrectHeight(deviceContext, 25),
                                    ...getPressableStyling(styling.components?.button?._id, appContext),
                      
                                    backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,95),
                                    borderRadius:100
                                }}
                                animatorOptions={getFocusStyling('Buttons', appContext)}
                                onPress={() => {

                                    item.start();
                                }}
                            >
                                <>
                                    <View
                                        style={{
                                            width: getCorrectHeight(deviceContext, 25),
                                            height: getCorrectHeight(deviceContext, 25),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <FontAwesomeIcon
               
                                            icon={faDownload}
                                            color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                            size={getFontSize(deviceContext, 'Normal')}
                                        ></FontAwesomeIcon>

                                   
                                    </View>
                                </>
                            </Pressable>
                        )}
                        <Pressable
                            style={{
                                marginVertical: getCorrectHeight(deviceContext, 5),
                                marginRight: getCorrectHeight(deviceContext, 5),
                                height: getCorrectHeight(deviceContext, 25),
                                width: getCorrectHeight(deviceContext, 25),
                                ...getPressableStyling(styling.components?.button?._id, appContext),
                     
                                backgroundColor: colorShade(getPressableStyling(styling.components?.button?._id, appContext).backgroundColor,95),
                                               borderRadius: 100
                            }}
                            animatorOptions={getFocusStyling('Buttons', appContext)}
                            onPress={() => downloadRemove(item)}
                        >
                            <>
                                <View
                                    style={{
                                        width: getCorrectHeight(deviceContext, 25),
                                        height: getCorrectHeight(deviceContext, 25),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <FontAwesomeIcon
                                
                                        icon={faTrash}
                                        color={getPressableTextStyling(styling.components?.button?._id, appContext).color}
                                        size={getFontSize(deviceContext, 'Normal')}
                                    ></FontAwesomeIcon>

                            
                                </View>
                            </>
                        </Pressable>
                        </View>
                    </View>
                </View>
            </View>
        );
    };

    return (
        <View style={[{ flexGrow: 1, flexDirection: 'row', height: gridHeight }]} focusContext={focusContext} ref={targetRef} onLayout={onLayout}>
            <View style={{ flex: 1 }}>
                <View
                    style={{
                        flex: 4,
                        justifyContent: 'flex-end',
                        marginBottom: deviceContext.isAndroid ? tabBarHeight : 0,
                    }}
                >
                    {noDownloads && (
                        <View
                            style={{
                                backgroundColor: '#111111',
                                justifyContent: 'center',
                                padding: getCorrectWidth(deviceContext, 20),
                            }}
                        >
                            <Text style={{ textAlign: 'center', color: '#fff', fontSize: getFontSize(deviceContext, 'Large'), marginBottom: 10, fontWeight: 'bold' }}>{lang.getTranslation(userContext, 'no_downloads_header')}</Text>
                            <Text style={{ textAlign: 'center', color: '#fff', fontSize: getFontSize(deviceContext, 'Small'), marginBottom: 20 }}>{lang.getTranslation(userContext, 'no_downloads')}</Text>
                        </View>
                    )}
                    {show && (
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                paddingLeft: getCorrectWidth(deviceContext, styling.placement.margin_left ?? 5),
                                paddingRight: getCorrectWidth(deviceContext, styling.placement.margin_right ?? 5),
                                paddingTop: getCorrectHeight(deviceContext, styling.placement.margin_top == 0 ? 5 : styling.placement.margin_top ?? 5),
                            }}
                        >
                            <FlashList
                                type={'row'}
                                horizontal={false}
                                data={dataList}
                                extraData={dataList}
                                showsVerticalScrollIndicator={false}
                                estimatedItemSize={getCorrectHeight(deviceContext, 130)}
                                drawDistance={getCorrectHeight(deviceContext, 130) * 2}
                                style={{ flex: 1 }}
                                renderItem={renderListItem}
                                initialScrollIndex={0}
                            />
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};

export default List_Downloads;
