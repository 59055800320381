import { ActivityIndicator, Image, ScrollView, View, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import { faEdit, faLock, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import { isFactorBrowser, isFactorMobile, isPlatformAndroid, isPlatformIos } from '@rnv/renative';
import moment from 'moment';
import * as React from 'react';
import { BackHandler, KeyboardAvoidingView as KAV, Platform } from 'react-native';
import Dropdown_ from '../../../application/builder/components/dropdown/dropdown';
import Input from '../../../application/builder/components/input/input';
import Keyboard from '../../../application/builder/components/keyboard/keyboard';
import Parental from '../../../application/builder/components/parental/parental_lock';
import Pressable from '../../../application/builder/components/pressable/pressable';
import ProfileImage from '../../../application/builder/components/profiles/profiles';
import FocusableSwitch from '../../../application/builder/components/switch/Switch';
import Text from '../../../application/builder/components/text';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import { updateProfilesJson } from '../../../application/data/account';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';
import { useTVRemoteHandler } from '../../../application/hooks/useTVRemoteHandler';
import lang from '../../../application/languages/languages';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { useSafeAreaInsets } from '../../../libs/react-native-safe-area-context';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import { getKeyboardStyling, getModalStyling, getDropdownStyling, getFocusStyling, getInputStyling, getPressableStyling, getPressableTextStyling, getSwitchStyling } from '../../../application/builder/components/helpers/helper';
import Modal from '../../../application/builder/components/modal/modal';
import type { DropdownRef } from './add';
import UTILS from '../../../application/general/utils';

const KeyboardAvoidingView = withParentContextMapper(KAV);

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const { profile, profileIndex } = route.params;
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const [name, setName] = React.useState(profile.name);
    const [screenSizes] = React.useState([{ name: 'Contain' }, { name: 'Cover' }, { name: 'Stretch' }]);
    const [times] = React.useState([{ name: 'AM/PM' }, { name: '24 Hour' }]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [ratings, setRatings] = React.useState([] as any);
    const [time, setTime] = React.useState(profile.clock ?? 'AM/PM');
    const [screenSize, setScreenSize] = React.useState(profile.aspectRatio ?? 'Cover');
    const [audioLanguage, setAudioLanguage] = React.useState(profile.audio ?? '');
    const [subtitleLanguage, setSubtitleLanguage] = React.useState(profile.audio ?? '');
    const [language, setLanguage] = React.useState(profile.language ?? '');
    const [code, setCode] = React.useState(profile.code ?? '');
    const [parentalIndex, setParentalIndex] = React.useState(profile.parental?.index ?? 0);
    const [parentalCode, setParentalCode] = React.useState(profile.parental?.code ?? '');
    const [parentalLocked, setParentalLocked] = React.useState(profile.parental?.locked ?? false);
    const [lockAccount, setLockAccount] = React.useState(profile.locked ?? false);
    const [showParental, setShowParental] = React.useState(false);
    const [consent, setConsent] = React.useState(false);
    const [showParentalDelete, setShowParentalDelete] = React.useState(false);
    const [showProfileImageSelection, setShowProfileImageSelection] = React.useState(false);
    const [avatar, setAvatar] = React.useState(profile.avatar);
    const [keyboardVisible, setKeyboardVisible] = React.useState(false);
    const [keyboardVisible2, setKeyboardVisible2] = React.useState(false);
    const insets = useSafeAreaInsets();

    const dropdownsRefs = React.useRef<{ ref1: DropdownRef; ref2: DropdownRef; ref3: DropdownRef; ref4: DropdownRef; ref5: DropdownRef }>({
        ref1: React.createRef(),
        ref2: React.createRef(),
        ref3: React.createRef(),
        ref4: React.createRef(),
        ref5: React.createRef(),
    }).current;

    useEffectOnce(() => {
        if (appContext.application.settings.agerating != undefined) {
            var ratings = appContext.application.settings.agerating.ratings;
            setRatings(ratings);
        }
    });

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.EDIT_PROFILE, duration);
            };
        }, [])
    );

    const disableNativeHardwareBackPress = () => {
        return false;
    };

    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);

    useTVRemoteHandler(
        ({ eventType, eventKeyAction }: any) => {
            if (!deviceContext.isWebTV && eventKeyAction === 'down' && !showProfileImageSelection) {
                if (['back', 'menu'].includes(eventType)) {
                    if (keyboardVisible) {
                        setKeyboardVisible(false);
                    } else {
                        navigation.navigate('Profiles');
                    }
                }
            }
        },
        [showProfileImageSelection, keyboardVisible]
    );

    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if ((keyCode == 413 || keyCode == 10009 || keyCode == 461) && !showProfileImageSelection) {
                if (keyboardVisible) {
                    setKeyboardVisible(false);
                } else {
                    navigation.navigate('Profiles');
                }
            }
        };
        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, [showProfileImageSelection, keyboardVisible]);

    const updateProfile = async () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.EDIT_PROFILE);
        setIsLoading(true);

        var langChanged = false;
        if (userContext.selectedLanguage != language) {
            userContext.setSelectedLanguage(language);
            UTILS.storeJson('Selected_Language', language);
            langChanged = true;
        }

        userContext.allProfiles[profileIndex].name = name;
        userContext.allProfiles[profileIndex].locked = lockAccount;
        userContext.allProfiles[profileIndex].code = code;
        userContext.allProfiles[profileIndex].avatar = avatar;
        userContext.allProfiles[profileIndex].language = language;
        userContext.allProfiles[profileIndex].audio = audioLanguage;
        userContext.allProfiles[profileIndex].clock = time;
        userContext.allProfiles[profileIndex].subtitles = subtitleLanguage;
        userContext.allProfiles[profileIndex].aspectRatio = screenSize;
        userContext.allProfiles[profileIndex].consent = consent;
        userContext.allProfiles[profileIndex].parental = {
            index: parentalIndex,
            code: parentalCode,
            locked: parentalLocked,
        };
        await updateProfilesJson(userContext.allProfiles, appContext.application, userContext);
        if (langChanged == true) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Loader', params: { fromEditOrAdd: true } }],
                })
            );
        } else {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Profiles', params: { fromEditOrAdd: true } }],
                })
            );
        }
    };

    const getParentalStatus = (index) => {
        if (index == parentalIndex) {
            return true;
        }
        return false;
    };
    const setParentalStatus = (index, value, locked) => {
        setParentalIndex(index);
        setParentalCode(value);
        setParentalLocked(locked);
    };

    const setLockAccountLocal = () => {
        setShowParental(true);
    };
    const setParentalApprovedLocal = () => {
        setShowParental(false);
        setLockAccount(!lockAccount);
    };
    const deleteProfile = () => {
        setShowParentalDelete(true);
    };
    const setParentalApprovedLocalDelete = async () => {
        setShowParentalDelete(false);
        setIsLoading(true);
        var newProfiles = userContext.allProfiles.filter((p) => p.id != profile.id);
        userContext.setAllProfiles(newProfiles);
        await updateProfilesJson(newProfiles, appContext.application, userContext);
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Profiles', params: { fromEditOrAdd: true } }],
            })
        );
    };

    const setProfileImage = (item, index) => {
        setAvatar(item.image);
        setShowProfileImageSelection(false);
    };

    const setConsentAccountLocal = () => {
        setConsent(!consent);
    };

    const dropdowns = [
        {
            title: lang.getTranslation(userContext, 'selected_language'),
            data: appContext.application.settings.languages,
            value: language !== '' ? language : language,
            type: 'Languages',
            onSelect: setLanguage,
        },
        {
            title: lang.getTranslation(userContext, 'selected_subtitle'),
            data: appContext.application.settings.languages,
            value: subtitleLanguage,
            type: 'Subs',
            onSelect: setSubtitleLanguage,
        },
        {
            title: lang.getTranslation(userContext, 'selected_audio'),
            data: appContext.application.settings.languages,
            value: audioLanguage,
            type: 'Audio',
            onSelect: setAudioLanguage,
        },
        {
            title: lang.getTranslation(userContext, 'selected_screensize'),
            data: screenSizes,
            value: screenSize,
            type: 'Screens',
            onSelect: setScreenSize,
        },
        {
            title: lang.getTranslation(userContext, 'time_setting'),
            data: times,
            value: time,
            type: 'Times',
            onSelect: setTime,
        },
    ];

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {showParental && (
                <Parental
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    setParentalApproved={setParentalApprovedLocal}
                    setShowParental={setShowParental}
                    profile={profile}
                    type={'lock_profile'}
                />
            )}
            {showParentalDelete && (
                <Modal
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    type={'delete_profile'}
                    submitChoice={setParentalApprovedLocalDelete}
                    setShowModal={() => setShowParentalDelete(false)}
                ></Modal>
            )}
            <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === 'ios' ? 'padding' : 'height'} keyboardVerticalOffset={30}>
                {keyboardVisible && (
                    <Keyboard
                        onCloseKeyboard={() => setKeyboardVisible(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components.keyboard._id, appContext)}
                        onChangText={setName}
                        value={name}
                        focusOptions={{ nextFocusUp: 'profile', nextFocusDown: 'profile' }}
                    />
                )}
                {keyboardVisible2 && (
                    <Keyboard
                        onCloseKeyboard={() => setKeyboardVisible2(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components.keyboard._id, appContext)}
                        onChangText={setCode}
                        value={code}
                        focusOptions={{ nextFocusUp: 'parental-code', nextFocusDown: 'parental-code' }}
                    />
                )}
                {showProfileImageSelection && (
                    <ProfileImage
                        stylingprofiles={appContext.application.authentication.profiles}
                        styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                        setShowProfileImageSelection={setShowProfileImageSelection}
                        setProfileImage={setProfileImage}
                        profile={profile}
                    />
                )}

                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flex: 1.5,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.profiles?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.profiles?.header.title_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'edit_profile')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.profiles?.header.sub_size),
                                fontWeight: appContext.application.authentication?.profiles?.header.sub_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.profiles?.header.sub_color,
                                width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                textAlign: 'center',
                            }}
                        >
                            {lang.getTranslation(userContext, 'wittheprofile')}
                        </Text>
                    </View>
                    <View style={{ flex: 5, flexDirection: 'row' }}>
                        <ScrollView
                            contentContainerStyle={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingBottom: insets.bottom + (isPlatformAndroid ? 40 : isPlatformIos ? 120 : 40),
                            }}
                        >
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <View style={{ flex: 1, margin: getCorrectWidth(deviceContext, 20) }}>
                                    <View style={{ position: 'absolute', top: -20, right: -50, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                        <FontAwesomeIcon icon={faEdit} color={'#ffffff'} size={getFontSize(deviceContext, 'ExtraLarge')}></FontAwesomeIcon>
                                        <Text
                                            style={{
                                                marginTop: 5,
                                                textAlign: 'center',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'X-Small'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'edit_avatar')}
                                        </Text>
                                    </View>
                                    <Pressable
                                        animatorOptions={{
                                            type: 'scale',
                                            focus: {
                                                scale: 1.1,
                                                duration: 150,
                                            },
                                        }}
                                        onPress={() => setShowProfileImageSelection(true)}
                                    >
                                        <Image
                                            resizeMethod={'scale'}
                                            resizeMode={'cover'}
                                            source={{ uri: avatar }}
                                            style={{
                                                width: getCorrectWidth(deviceContext, 100),
                                                height: getCorrectWidth(deviceContext, 100),
                                                borderRadius: getCorrectWidth(deviceContext, 100) / 2,
                                                borderColor: getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext).backgroundColor,
                                                borderWidth: deviceContext.isKaiOs ? getCorrectWidth(deviceContext, 4) : getCorrectWidth(deviceContext, 2),
                                            }}
                                        />
                                    </Pressable>
                                </View>
                                <View
                                    style={{
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                        justifyContent: 'flex-start',
                                        padding: getCorrectHeight(deviceContext, 10),
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,
                                    }}
                                >
                                    <Input
                                        value={name}
                                        onChangText={(value) => setName(value)}
                                        icon={faUser}
                                        width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.35)}
                                        isSecure={false}
                                        placeholder={lang.getTranslation(userContext, 'your_name')}
                                        animation={getFocusStyling('Inputs', appContext)}
                                        styling={getInputStyling(appContext.application.authentication.profiles.components?.input?._id, appContext)}
                                        focusOptions={{ focusKey: 'profile' }}
                                        onFocus={() => {
                                            if (keyboardVisible) {
                                                setKeyboardVisible(false);
                                            }
                                        }}
                                        showKeyboard={() => {
                                            setKeyboardVisible(true);
                                        }}
                                    />
                                </View>

                                {ratings.length > 0 && (
                                    <View
                                        style={{
                                            marginTop: getCorrectHeight(deviceContext, 5),
                                            justifyContent: 'flex-start',
                                            padding: getCorrectHeight(deviceContext, 20),
                                            paddingVertical: getCorrectHeight(deviceContext, 10),
                                            paddingTop: getCorrectHeight(deviceContext, 20),
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                            backgroundColor: appContext.application.theme.settings.background_color2,
                                            borderRadius: appContext.application.theme.settings.border_radius,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'select_age_rating')}
                                        </Text>
                                        <View style={{ flexDirection: 'row' }}>
                                            <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                                                {ratings.map((rating, index) => {
                                                    return (
                                                        <View style={{ margin: getCorrectHeight(deviceContext, 10) }}>
                                                            <FocusableSwitch
                                                                focusContext={focusContext}
                                                                onValueChange={() => setParentalStatus(index, rating.name, rating.locked)}
                                                                value={getParentalStatus(index)}
                                                                focusOptions={{
                                                                    focusKey: `parental-rating-${index}`,
                                                                    nextFocusDown: 'parental-lock-switch',
                                                                }}
                                                                styling={getSwitchStyling(appContext.application.authentication.profiles.components?.switch?._id, appContext)}
                                                            />
                                                            <Text
                                                                style={{
                                                                    marginTop: getCorrectHeight(deviceContext, 5),
                                                                    fontFamily: deviceContext.fontType,
                                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                                    color: appContext.application.theme.settings.font_color,
                                                                }}
                                                            >
                                                                {rating.name}
                                                            </Text>
                                                        </View>
                                                    );
                                                })}
                                            </ScrollView>
                                        </View>
                                    </View>
                                )}
                                <View
                                    style={{
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,
                                        height: getCorrectHeight(deviceContext, 40),
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingLeft: getCorrectWidth(deviceContext, 20),
                                        paddingRight: getCorrectWidth(deviceContext, 20),
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),

                                        flexDirection: 'row',
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal'),
                                            color: appContext.application.theme.settings.font_color,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'lock_account_parental_control')}
                                    </Text>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <View
                                            style={{
                                                marginLeft: getCorrectHeight(deviceContext, 10),
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <FocusableSwitch
                                                focusContext={focusContext}
                                                onValueChange={() => setLockAccountLocal()}
                                                value={lockAccount}
                                                focusOptions={{ focusKey: 'parental-lock-switch', nextFocusUp: 'parental-rating-0' }}
                                                styling={getSwitchStyling(appContext.application.authentication.profiles.components?.switch?._id, appContext)}
                                            ></FocusableSwitch>
                                        </View>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, 80),
                                        marginTop: getCorrectHeight(deviceContext, 5),
                                        justifyContent: 'center',
                                        paddingLeft: getCorrectWidth(deviceContext, 20),
                                        paddingRight: getCorrectWidth(deviceContext, 20),
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,
                                    }}
                                >
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'personalized_ads')}
                                        </Text>
                                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            <View
                                                style={{
                                                    marginLeft: getCorrectHeight(deviceContext, 10),
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <FocusableSwitch
                                                    focusContext={focusContext}
                                                    onValueChange={() => setConsentAccountLocal()}
                                                    value={consent}
                                                    focusOptions={{ focusKey: 'consent-switch', nextFocusUp: 'parental-lock-switch' }}
                                                    styling={getSwitchStyling(appContext.application.authentication.profiles.components?.switch?._id, appContext)}
                                                ></FocusableSwitch>
                                            </View>
                                        </View>
                                    </View>
                                    <View>
                                        <Text
                                            style={{
                                                marginTop: getCorrectHeight(deviceContext, 8),
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Small'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'consent_personalized_ads')}
                                        </Text>
                                    </View>
                                </View>
                                {dropdowns.map(({ data, title, type, value, onSelect }, idx) => (
                                    <View
                                        style={{
                                            backgroundColor: appContext.application.theme.settings.background_color2,
                                            borderRadius: appContext.application.theme.settings.border_radius,
                                            height: getCorrectHeight(deviceContext, 40),
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                            marginTop: 10,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            zIndex: 999 - idx,
                                            overflow: 'visible',
                                        }}
                                    >
                                        <View
                                            style={{
                                                justifyContent: 'center',
                                                width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.5 : 0.2),

                                                paddingLeft: getCorrectWidth(deviceContext, 20),
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    color: appContext.application.theme.settings.font_color,
                                                }}
                                            >
                                                {title}
                                            </Text>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row', alignSelf: 'center', overflow: 'visible' }}>
                                            <Dropdown_
                                                width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.38 : 0.19)}
                                                styling={getDropdownStyling(appContext.application.authentication.profiles.components?.dropdown?._id, appContext)}
                                                animation={getFocusStyling('Dropdowns', appContext)}
                                                value={value}
                                                dataIn={data}
                                                type={type}
                                                setSelection={onSelect}
                                                dropdownRef={dropdownsRefs[`ref${idx + 1}`]}
                                                onToggleDropdown={(value) => {
                                                    if (isFactorMobile || isFactorBrowser) {
                                                        dropdowns.forEach((_, index) => {
                                                            const nextRef: DropdownRef = dropdownsRefs[`ref${index + 1}`];

                                                            if (index !== idx && value && nextRef.current && nextRef.current.isOpen()) {
                                                                nextRef.current.toggleDropdown(false);
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </View>
                                    </View>
                                ))}

                                <View
                                    style={{
                                        height: getCorrectHeight(deviceContext, 40),
                                        backgroundColor: appContext.application.theme.settings.background_color2,
                                        borderRadius: appContext.application.theme.settings.border_radius,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        marginTop: 10,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.49 : deviceContext.isWebTV || deviceContext.isTablet ? 0.198 : 0.195),

                                            paddingLeft: getCorrectWidth(deviceContext, 20),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'parental_code')}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            flexDirection: 'row',
                                            alignSelf: 'center',
                                            overflow: 'visible',
                                        }}
                                    >
                                        <Input
                                            maxLength={4}
                                            value={code}
                                            isSecure={true}
                                            onChangText={(value) => setCode(value.replace(/[^0-9]/g, ''))}
                                            icon={faLock}
                                            width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.38 : deviceContext.isTablet ? 0.198 : 0.19)}
                                            placeholder={'****'}
                                            animation={getFocusStyling('Inputs', appContext)}
                                            styling={getInputStyling(appContext.application.authentication.profiles.components?.input?._id, appContext)}
                                            focusOptions={{ focusKey: 'parental-code' }}
                                            onFocus={() => {
                                                if (keyboardVisible2) {
                                                    setKeyboardVisible2(false);
                                                }
                                            }}
                                            showKeyboard={() => {
                                                setKeyboardVisible2(true);
                                            }}
                                        />
                                    </View>
                                </View>

                                <Pressable
                                    focusOptions={{ focusKey: 'submit-button' }}
                                    style={{
                                        marginTop: 10,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        ...getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => updateProfile()}
                                >
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: getCorrectHeight(deviceContext, 30),
                                        }}
                                    >
                                        {!isLoading && (
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    color: appContext.application.theme.settings.font_color,
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'submit')}
                                            </Text>
                                        )}
                                        {isLoading && <ActivityIndicator color={getPressableTextStyling(appContext.application.authentication.profiles.components?.button?._id, appContext).color} size={'large'}></ActivityIndicator>}
                                    </View>
                                </Pressable>
                                <Pressable
                                    style={{
                                        marginTop: 10,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        ...getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    focusOptions={{ focusKey: 'delete-profile-btn' }}
                                    onPress={() => deleteProfile()}
                                >
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: getCorrectHeight(deviceContext, 30),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'delete_profile')}
                                        </Text>
                                    </View>
                                </Pressable>
                                <Pressable
                                    style={{
                                        marginTop: 10,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.9 : 0.4),
                                        ...getPressableStyling(appContext.application.authentication.profiles.components?.button?._id, appContext),
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => navigation.navigate('Profiles')}
                                >
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: getCorrectHeight(deviceContext, 30),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                color: appContext.application.theme.settings.font_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'back')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </KeyboardAvoidingView>
        </ScreenWrapper>
    );
};
export default Screen_;
