import * as React from 'react';
import { View } from '@scriptx-com/xtv-toolkit';
import { getFontSize } from '../../../styling/fontSizes';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Pressable from '../../components/pressable/pressable';
import { favoriteStatus } from '../../../data/account';
import { Program } from '../../../models/content/epg/epg_data';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { ContentContext } from '../../../context/contentContext';
import { InteractiveTV } from '../../../models/content/shared/interactivetv';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { UserContext } from '../../../context/userContext';
import { checkParentalLockShouldApply } from '../../elements/players/helpers/functions';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Image from '../../components/image/image';
import Text from '../../components/text';
import { getFocusStyling, getPressableStyling } from '../../components/helpers/helper';
import lang from '../../../languages/languages';
import { getReplayTag } from '../players/helpers/helpers';


export interface Data {
    name: string;
    number: string;
    images: {
        widescreen?: string;
        square?: string;
        tile?: string;
        background?: string;
        poster?: string;
        logo?: string;
    };
    _id: string;
    progress?: number;
    catchuptv?: boolean;
    interactivetv?: InteractiveTV;
    program?: Program;
}

export interface Props {
    fontDensity?: number;
    focusContext: any;
    focusRepeatContext: any;
    focusOptions?: any;
    noMargin?: boolean;
    height: number;
    width: number;
    onPress: any;
    styling: any;
    data: Data;
    type: string;
    rails?: boolean;
    onLongPress: any;
    heroIsPlayer: boolean;
}

export const Long = ({ fontDensity, focusContext, focusRepeatContext, noMargin, height, width, onPress, styling, type, data, onLongPress, rails, heroIsPlayer }: Props) => {
    const screenName = getCurrentScreenName();
    const contentContext = React.useContext(ContentContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const appContext = React.useContext(AppContext);
    const timeout = React.useRef<NodeJS.Timeout>();

    const onFocus = (data) => {
        timeout.current = setTimeout(() => {
            if (styling.content?.link_to_hero != undefined && styling.content?.link_to_hero && heroIsPlayer) {
                contentContext.setHero({
                    type: type,
                    data: [data],
                });
            }
        }, 1000);
    };

    const onBlur = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    };

    if (fontDensity == undefined) {
        fontDensity = 1;
    }
    var parentalIsApplied = false;
    var favorite = favoriteStatus(type, data?._id, userContext);

    if (screenName == 'Channel_Player') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    data.progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;
                    parentalIsApplied = checkParentalLockShouldApply(userContext, contentContext, currentItem, data);
                }
            }
        }
    }
    if (screenName == 'Channels') {
        if (contentContext.epgTv[0] != undefined) {
            var epgAll = contentContext.epgTv?.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    data.progress = percentageProgram;
                    data.catchuptv = data?.interactivetv.pausetv_enabled;

                    parentalIsApplied = checkParentalLockShouldApply(userContext, contentContext, currentItem, data);
                }
            }
        }
    }
    if (screenName == 'Radio') {
        if (contentContext.epgRadio[0] != undefined) {
            var epgAll = contentContext.epgRadio.find((e) => e.i == data?._id);
            if (epgAll != undefined) {
                var currentTime = moment().unix();
                var currentItem = epgAll.epg.find((e) => e.s <= currentTime && e.e > currentTime);
                if (currentItem != undefined) {
                    var totalProgram = currentItem.e - currentItem.s;
                    var percentageProgram = ((currentTime - currentItem.s) / totalProgram) * 100;
                    data.program = currentItem;
                    data.progress = percentageProgram;
                }
            }
        }
    }
    const getRibbonText = () => {
        var test = styling.ribbon.title.find((t) => t.language == userContext.selectedLanguage);
        if (test != undefined) {
            return test.title;
        } else {
            if (styling.content.title != undefined) {
                return styling.content.title[0].title;
            } else {
                return '';
            }
        }
    };
    const getExtraStyle = () => {
        if (styling.ribbon.align == 'Right') {
            return { right: -2 };
        } else {
            return { left: -2 };
        }
    };

    const focusWidth = () => {
        return getPressableStyling(styling.components.button._id, appContext).borderWidth * 2;
    };
    if (data == undefined) {
        return null;
    } else {
        return (
            <Pressable
                style={[
                    {
                        ...getPressableStyling(styling.components.button._id, appContext),
                        backgroundColor: type == 'Channels_List' ? '#00000095' : getPressableStyling(styling.components.button._id, appContext).backgroundColor,
                        marginBottom: getCorrectWidth(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 5 : 4),
                        width: width - getCorrectWidth(deviceContext, 5),
                        marginHorizontal: getCorrectWidth(deviceContext, 2.5),
                    },
                ]}
                focusContext={focusContext}
                focusRepeatContext={focusRepeatContext}
                animatorOptions={getFocusStyling('Buttons', appContext)}
                onFocus={() => onFocus(data)}
                onBlur={() => onBlur()}
                onPress={() => onPress(data?._id)}
                onLongPress={() => onLongPress(data?._id)}
            >
                <View>
                    {styling.ribbon != undefined && styling.ribbon.show_ribbon && (
                        <View style={[getExtraStyle(), { position: 'absolute', zIndex: 9999 }]}>
                            <View
                                style={{
                                    padding: getCorrectWidth(deviceContext, 3),
                                    paddingHorizontal: getCorrectWidth(deviceContext, 5),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: styling.ribbon.background_color,

                                    borderRadius: getCorrectHeight(deviceContext, styling.ribbon.border_radius),
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: deviceContext.fontType,
                                        fontSize: getFontSize(deviceContext, 'Small'),
                                        color: styling.ribbon.title_color,
                                        fontWeight: styling.ribbon.title_bold ? 'bold' : 'normal',
                                    }}
                                >
                                    {getRibbonText()}
                                </Text>
                            </View>
                        </View>
                    )}
                    <View
                        style={{
                            margin: getCorrectHeight(deviceContext, appContext.application.theme.images.margin),
                            borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,

                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 5,
                        }}
                    >
                        <Image
                          isCache={true}
                            deviceContext={deviceContext}
                            source={{ uri: appContext.cloudUrl + data?.images?.square }}
                            resizeMethod={'scale'}
                            resizeMode={'cover'}
                            style={{
                                borderRadius: getCorrectHeight(deviceContext, appContext.application.theme.images.radius) / 3,
                                height: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth(),
                                width: height - getCorrectWidth(deviceContext, appContext.application.theme.images.margin * 2) - focusWidth(),
                            }}
                        />

                        <View
                            style={{
                                flex: 3,
                                padding: getCorrectWidth(deviceContext, 5),
                                marginLeft: getCorrectWidth(deviceContext, 5),
                            }}
                        >
                            <View style={{ flexDirection: 'row', marginBottom: getCorrectHeight(deviceContext, 3) }}>
                                <View style={{ flex: 1 }}>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            includeFontPadding: false,
                                            color: styling.texts.title_color,
                                            fontWeight: styling.texts.title_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Normal') * 1.3,
                                            marginRight: getCorrectHeight(deviceContext, 10),
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {data?.number}. {data?.name}
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        justifyContent: 'center',
                                    }}
                                >
                                    {data?.catchuptv && (
                                    <>{getReplayTag(lang.getTranslation(userContext, 'replay').toUpperCase(), appContext, deviceContext)}</>
                                    )}
                                </View>
                            </View>

                            {data?.program != undefined && (
                                <>
                                    <Text
                                        numberOfLines={1}
                                        style={{
                                            includeFontPadding: false,
                                            color: styling.texts.subtext_color,
                                            fontWeight: styling.texts.subsubtext_bold == true ? 'bold' : 'normal',
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small') * 1.25,
                                            textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                            textShadowOffset: { width: -1, height: 1 },
                                            textShadowRadius: 2,
                                        }}
                                    >
                                        {moment.unix(data?.program?.s).format('ddd ' + deviceContext.clockSetting)} - {moment.unix(data?.program?.e).format('ddd ' + deviceContext.clockSetting)}{' '}
                                    </Text>

                                    {appContext.application.theme.progresses.show_progress && (
                                        <View
                                            style={{
                                                marginVertical: getCorrectHeight(deviceContext, 1),
                                                backgroundColor: appContext.application.theme.progresses.base,
                                                height: getCorrectHeight(deviceContext, 2),
                                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                shadowOffset: { width: -1, height: 1 },
                                                shadowRadius: 2,
                                            }}
                                        >
                                            <View
                                                style={{
                                                    backgroundColor: appContext.application.theme.progresses.progress,
                                                    height: getCorrectHeight(deviceContext, 2),
                                                    shadowColor: 'rgba(0, 0, 0, 0.3)',
                                                    shadowOffset: { width: -1, height: 1 },
                                                    shadowRadius: 2,
                                                    width: data?.progress + '%',
                                                }}
                                            ></View>
                                        </View>
                                    )}

                                    {!parentalIsApplied && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                includeFontPadding: false,
                                                color: styling.texts.subtext_color,
                                                fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Small') * 1.25,
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            {data?.program.n}
                                        </Text>
                                    )}
                                    {parentalIsApplied && (
                                        <Text
                                            numberOfLines={1}
                                            style={{
                                                includeFontPadding: false,
                                                color: styling.texts.subtext_color,
                                                fontWeight: styling.texts.subtext_bold == true ? 'bold' : 'normal',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                textShadowColor: 'rgba(0, 0, 0, 0.2)',
                                                textShadowOffset: { width: -1, height: 1 },
                                                textShadowRadius: 2,
                                            }}
                                        >
                                            *********
                                        </Text>
                                    )}
                                </>
                            )}
                            <View style={{ flexDirection: 'row' }}>
                                {appContext.application.theme.icons.show_favorite && favorite && (
                                    <View
                                        style={{
                                            position: 'absolute',
                                            right: getCorrectHeight(deviceContext, 5),
                                            top: getCorrectHeight(deviceContext, 5),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            backgroundColor: '#333',
                                            borderRadius: 100,
                                            width: width / 6,
                                            height: width / 6,
                                        }}
                                    >
                                        <FontAwesomeIcon size={width / 10} style={{ color: appContext.application.theme.icons.background }} icon={faHeart} />
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                </View>
            </Pressable>
        );
    }
};
export default Long;
