import react from 'react';

export const getKeyboardStyling = (_id, appContext) => {
    var keyboard = appContext.application.components.keyboards.find((b) => b._id == _id);
    if (keyboard) {
        return keyboard;
    } else {
        return {
            type: '',
            name: '',
            background_color: '#333333',
            components: {
                button: {
                    _id: '',
                },
            },
        };
    }
};
export const getDropdownStyling = (_id, appContext) => {
    var dropdown = appContext.application.components.dropdowns.find((b) => b._id == _id);
    if (dropdown) {
        return dropdown;
    } else {
        return {
            type: '',
            name: '',
            background_color: '#000000',
            border_radius: 0,
            border_width: 0,
            border_color: '#000000',
            components: {
                button: {
                    _id: '',
                },
            },
        };
    }
};
export const getInputStyling = (_id, appContext) => {
    var input = appContext.application.components.inputs.find((b) => b._id == _id);
    if (input) {
        return input;
    } else {
        return {
            type: '',
            name: '',
            background_color: '#000000',
            border_radius: 0,
            border_width: 0,
            border_color: '#000000',
            text_color: '#ffffff',
            text_bold: false,
            placeholder_color: '#999999',
            icon_color: '#ffffff',
        };
    }
};
export const getSwitchStyling = (_id, appContext) => {
    var switch_ = appContext.application.components.switches.find((b) => b._id == _id);
    if (switch_) {
        return switch_;
    } else {
        return {
            type: '',
            name: '',
            background_color: '#000000',
            toggle_color: '#444444',
            selected_color: '#999999',

            text_color: '#ffffff',
            text_bold: false,
        };
    }
};
export const getModalStyling = (_id, appContext) => {
    var modal = appContext.application.components.modals.find((b) => b._id == _id);
    if (modal) {
        return modal;
    } else {
        return {
            type: '',
            name: '',
            header_color: '#999999',
            background_color: '#000000',
            border_radius: 0,
            border_width: 0,
            border_color: '#000000',
            title_color: '#ffffff',
            title_bold: false,
            subtext_color: '#ffffff',
            subtext_bold: false,
            components: {
                button: {
                    _id: '',
                },
            },
        };
    }
};
export const getPressableStyling = (_id, appContext) => {
    var button = appContext.application.components.buttons.find((b) => b._id == _id);
    if (button) {
        return {
            backgroundColor: button.background_color,
            borderColor: button.border_color,
            borderRadius: button.border_radius ?? 2,
            borderWidth: button.border_width ?? 0,
        };
    } else {
        return {
            backgroundColor: '#000000',
            borderColor: '#000000',
            borderRadius: 2,
            borderWidth: 0,
        };
    }
};
export const getMessageStyling = (appContext) => {
    var message = appContext.application.theme.messages;
    if (message) {
        return {
            backgroundColor: message.background_color,
            borderColor: message.border_color,
            color: message.font_color,
            borderRadius: message.border_radius ?? 0,
            borderWidth: message.border_width ?? 0,
        };
    } else {
        return {
            backgroundColor: '#000000',
            borderColor: '#000000',
            color: '#ffffff',
            borderRadius: 0,
            borderWidth: 0,
        };
    }
};
export const getAdsStyling = (appContext) => {
    var ads = appContext.application.theme.ads;
    if (ads) {
        return {
            backgroundColor: ads.background_color,
            borderColor: ads.border_color,
            color: ads.font_color,
            borderRadius: ads.border_radius ?? 0,
            borderWidth: ads.border_width ?? 0,
        };
    } else {
        return {
            backgroundColor: '#000000',
            borderColor: '#000000',
            color: '#ffffff',
            borderRadius: 0,
            borderWidth: 0,
        };
    }
};
export const getPressableTextStyling = (_id, appContext) => {
    var button = appContext?.application?.components?.buttons.find((b) => b._id == _id);
    if (button) {
        return {
            color: button.text_color,
            // fontWeight: button.text_bold ? 'bold' : 'normal',
        };
    } else {
        return {
            color: '#ffffff',
            //fontWeight: 'normal',
        };
    }
};
export const getFocusStyling = (type, appContext) => {
    switch (type) {
        case 'Dropdowns':
            return appContext.application.theme.focus.dropdowns;
            break;
        case 'Buttons':
            return appContext.application.theme.focus.buttons;
            break;
        case 'Menus':
            return appContext.application.theme.focus.menus;
            break;
        case 'Rails':
            return appContext.application.theme.focus.rails;
            break;
        case 'Grids':
            return appContext.application.theme.focus.grids;
            break;
        case 'Inputs':
            return appContext.application.theme.focus.inputs;
            break;
        case 'Navigators':
            return appContext.application.theme.focus.navigators;
            break;
        case 'Players':
            return appContext.application.theme.focus.players;
            break;
        default:
            return {
                type: 'background',
                focus: {
                    duration: 150,
                    borderColor: '#000000',
                    borderWidth: 0,
                    backgroundColor: '#999999',
                },
                blur: {
                    borderWidth: 0,
                    backgroundColor: 'transparent',
                },
            };
            break;
    }
};
export const getSelectedStyling = (type, appContext) => {
    switch (type) {
        case 'Menus':
            return appContext.application.theme.selected.menus;
            break;
        case 'Navigators':
            return appContext.application.theme.selected.navigators;
            break;

        default:
            return {
                element: '',
                background_color: '#999999',
                text_color: '#ffffff',
                text_bold: false,
                border_bottom_width: 0,
                border_bottom_color: '#000000',
            };
            break;
    }
};
