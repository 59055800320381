import * as React from 'react';
import { View, ActivityIndicator } from '@scriptx-com/xtv-toolkit';
import Pressable from '../../../application/builder/components/pressable/pressable';
import Input from '../../../application/builder/components/input/input';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import lang from '../../../application/languages/languages';
import { faEnvelope, faMobile } from '@fortawesome/pro-light-svg-icons';
import { getCorrectHeight, getCorrectWidth } from '../../../application/styling/correctSizes';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';
import { UserContext } from '../../../application/context/userContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import Keyboard from '../../../application/builder/components/keyboard/keyboard';
import ScreenWrapper from '../../wrapper';
import { AppContext } from '../../../application/context/appContext';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import { sendPageReport } from '../../../reporting/apis/page';
import { pageType } from '../../../reporting/models/apps';
import utils from '../../../application/general/utils';
import { getGlobals } from '../../../globals';
import Text from '../../../application/builder/components/text';
import { getKeyboardStyling, getFocusStyling, getPressableStyling, getPressableTextStyling, getInputStyling } from '../../../application/builder/components/helpers/helper';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);

    const [show, setShow] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState(false);

    const [mobile, setMobile] = React.useState('0031625297579');
    const [email, setEmail] = React.useState('sander@mwaretv.com');

    const [showKeyboard3, setShowKeyboard3] = React.useState(false);
    const [showKeyboard4, setShowKeyboard4] = React.useState(false);

    const [error, setError] = React.useState('');

    useEffectOnce(() => {
        setShow(true);
    });

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.GETSTARTED, duration);
            };
        }, [])
    );

    const getStarted = async () => {
        setLoading(true);
        await fetch(getGlobals().AppApiUrl + '/auth/code?instance=' + appContext.application.client + '&cms=' + appContext.application.deployment + '&crm=' + appContext.application.branding.service, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((response) => {
                getMessage(response.code);
            })
            .catch((error) => {
                return 'nocode';
            });
    };
    const checkTimer = (code) => {
        setTimeout(() => {
            pingCode(code);
        }, 3000);
    };
    const pingCode = (code) => {
        fetch(getGlobals().AppApiUrl + '/auth/ping?code=' + code + '&instance=' + appContext.application.client + '&cms=' + appContext.application.deployment + '&crm=' + appContext.application.branding.service, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.userid != undefined) {
                    userContext.setUserId(response.userid);
                    utils.storeJson('UserID', response.userid);
                    userContext.setPass(response.pass);
                    utils.storeJson('Pass', response.pass);
                    userContext.setAutoLogin(true);
                    utils.storeJson('AutoLogin', true);

                    userContext.setIsOnboardingFinished(true);
                    utils.storeJson('OnboardingFinished', true);

                    //login and continue
                    if (appContext.application.settings?.enable_nested == true) {
                        userContext.setAppId(response.serviceid);
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Services' }],
                            })
                        );
                    } else {
                        if (appContext.application?.settings.enable_anonymous != true) {
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 1,
                                    routes: [{ name: 'Login' }],
                                })
                            );
                        } else {
                            navigation.dispatch(
                                CommonActions.reset({
                                    index: 1,
                                    routes: [{ name: 'Anonymous' }],
                                })
                            );
                        }
                    }
                } else {
                    checkTimer(code);
                }
            });
    };
    const getMessage = async (code) => {
        if (email == '' && mobile == '') {
            setError(lang.getTranslation('filloutform'));
        } else {
            setMessage(true);
            setLoading(true);
            //sent email to customer to get subscription
            fetch(getGlobals().AppApiUrl + '/getstarted?instance=' + appContext.application.client + '&cms=' + appContext.application.deployment + '&crm=' + appContext.application.branding.service, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cart_id: appContext.cart._id,
                    email: email,
                    mobile: mobile,
                    code: code,
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    //start timer and when timer has correct values reload and continue
                    checkTimer(code);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const loginManually = () => {
        userContext.setIsOnboardingFinished(true);
        utils.storeJson('OnboardingFinished', true);
        if (appContext.application?.settings.enable_anonymous != true) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Login' }],
                })
            );
        } else {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Anonymous' }],
                })
            );
        }
    };
    const goBack = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Onboarding' }],
            })
        );
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {showKeyboard3 && (
                <Keyboard
                    onCloseKeyboard={() => setShowKeyboard3(false)}
                    focusContext={focusContext}
                    width={getRealWidth(deviceContext) * 0.8}
                    isSecure={false}
                    placeholder={''}
                    styling={getKeyboardStyling(appContext.application.authentication.login.components.keyboard._id, appContext)}
                    onChangText={setMobile}
                    value={mobile}
                ></Keyboard>
            )}
            {showKeyboard4 && (
                <Keyboard
                    onCloseKeyboard={() => setShowKeyboard4(false)}
                    focusContext={focusContext}
                    width={getRealWidth(deviceContext) * 0.8}
                    isSecure={false}
                    placeholder={''}
                    styling={getKeyboardStyling(appContext.application.authentication.login.components.keyboard._id, appContext)}
                    onChangText={setEmail}
                    value={email}
                ></Keyboard>
            )}
            {!show && (
                <View style={{ flex: 1 }}>
                    <View style={{ flex: 4, flexDirection: 'row' }}>
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                        </View>
                    </View>
                </View>
            )}

            {show && (
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                fontWeight: appContext.application.authentication?.registration?.header.title_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.registration?.header.title_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'get_started')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: deviceContext.fontType,
                                fontSize: getFontSize(deviceContext, appContext.application.authentication?.registration?.header.sub_size),
                                fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                color: appContext.application.authentication?.registration?.header.title_color,
                            }}
                        >
                            {lang.getTranslation(userContext, 'get_started_info').toUpperCase()}
                        </Text>
                    </View>
                    <View style={{ flex: 4, flexDirection: 'row' }}>
                        {message && (
                            <View style={{ flex: 1 }}>
                                <View style={{ flex: 4, flexDirection: 'row' }}>
                                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                        {appContext.cart.customer.mandatory_email && appContext.cart.customer.mandatory_phone && (
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    fontWeight: appContext.application.authentication?.registration?.header.title_bold ? 'bold' : 'normal',
                                                    color: appContext.application.authentication?.registration?.header.title_color,
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'check_both')}
                                            </Text>
                                        )}
                                        {appContext.cart.customer.mandatory_phone && (
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    fontWeight: appContext.application.authentication?.registration?.header.title_bold ? 'bold' : 'normal',
                                                    color: appContext.application.authentication?.registration?.header.title_color,
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'check_phone')}
                                            </Text>
                                        )}
                                        {appContext.cart.customer.mandatory_email && (
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Large'),
                                                    fontWeight: appContext.application.authentication?.registration?.header.title_bold ? 'bold' : 'normal',
                                                    color: appContext.application.authentication?.registration?.header.title_color,
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'check_email')}
                                            </Text>
                                        )}
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                fontWeight: appContext.application.authentication?.registration?.header.title_bold ? 'bold' : 'normal',
                                                color: appContext.application.authentication?.registration?.header.title_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'auto_login')}
                                        </Text>
                                        <View style={{ paddingTop: getCorrectHeight(deviceContext, 20) }}>
                                            <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                                        </View>
                                        <Pressable style={{ marginTop: getCorrectHeight(deviceContext, 20) }} animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => loginManually()}>
                                            <View
                                                style={{
                                                    width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                                    height: getCorrectHeight(deviceContext, 30),
                                                    ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: deviceContext.fontType,
                                                        fontSize: getFontSize(deviceContext, 'Normal'),
                                                        ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                    }}
                                                >
                                                    {lang.getTranslation(userContext, 'login_manual')}
                                                </Text>
                                            </View>
                                        </Pressable>
                                    </View>
                                </View>
                            </View>
                        )}
                        {appContext.cart != undefined && !message && (
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <View
                                    style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        padding: getCorrectWidth(deviceContext, 10),
                                        marginBottom: getCorrectHeight(deviceContext, 10),
                                        borderRadius: getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext).borderRadius,
                                    }}
                                >
                                    {appContext.cart.customer.mandatory_phone && !appContext.cart.customer.mandatory_email && (
                                        <Text
                                            style={{
                                                alignSelf: 'flex-start',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'signup_explanation_phone')}
                                        </Text>
                                    )}
                                    {appContext.cart.customer.mandatory_email && (
                                        <Text
                                            style={{
                                                alignSelf: 'flex-start',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'signup_explanation_email')}
                                        </Text>
                                    )}
                                </View>
                                {appContext.cart.customer.mandatory_phone && !appContext.cart.customer.mandatory_email && (
                                    <View style={{ marginBottom: getCorrectHeight(deviceContext, 15) }}>
                                        {/* <Text
                                                style={{
                                                    alignSelf: 'flex-start',
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(
                                                        deviceContext,
                                                        appContext.application.authentication?.registration?.header
                                                            .sub_size
                                                    ),
                                                    fontWeight: appContext.application.authentication?.registration
                                                        ?.header.sub_bold
                                                        ? 'bold'
                                                        : 'normal',
                                                    color: appContext.application.authentication?.registration?.header
                                                        .sub_color,
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'your_mobile_username')}
                                            </Text> */}
                                        <Input
                                            showKeyboard={() => setShowKeyboard3(!showKeyboard3)}
                                            value={mobile}
                                            style={{ marginTop: 10 }}
                                            onChangText={(value) => setMobile(value)}
                                            icon={faMobile}
                                            width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                                            isSecure={false}
                                            placeholder={lang.getTranslation(userContext, 'mobile')}
                                            animation={getFocusStyling('Inputs', appContext)}
                                            styling={getInputStyling(appContext.application.authentication.login.components?.input?._id, appContext)}
                                        ></Input>
                                    </View>
                                )}
                                {appContext.cart.customer.mandatory_email && appContext.cart.customer.mandatory_phone && (
                                    <View style={{}}>
                                        <Text
                                            style={{
                                                alignSelf: 'flex-start',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'ExtraLarge'),
                                                fontWeight: appContext.application.authentication?.registration?.header.sub_bold ? 'bold' : 'normal',
                                                color: appContext.application.authentication?.registration?.header.sub_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'or')}
                                        </Text>
                                    </View>
                                )}
                                {appContext.cart.customer.mandatory_email && (
                                    <View style={{ marginBottom: getCorrectHeight(deviceContext, 15) }}>
                                        {/* <Text
                                            style={{
                                                alignSelf: 'flex-start',
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(
                                                    deviceContext,
                                                    appContext.application.authentication?.registration?.header.sub_size
                                                ),
                                                fontWeight: appContext.application.authentication?.registration?.header
                                                    .sub_bold
                                                    ? 'bold'
                                                    : 'normal',
                                                color: appContext.application.authentication?.registration?.header
                                                    .sub_color,
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'your_email_username')}
                                        </Text> */}
                                        <Input
                                            showKeyboard={() => setShowKeyboard4(!showKeyboard4)}
                                            value={email}
                                            style={{ marginTop: 10 }}
                                            onChangText={(value) => setEmail(value)}
                                            icon={faEnvelope}
                                            width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                                            isSecure={false}
                                            placeholder={lang.getTranslation(userContext, 'email')}
                                            animation={getFocusStyling('Inputs', appContext)}
                                            styling={getInputStyling(appContext.application.authentication.login.components?.input?._id, appContext)}
                                        ></Input>
                                    </View>
                                )}
                                <Pressable style={{ marginTop: 20 }} animatorOptions={getFocusStyling('Buttons', appContext)} onPress={() => getStarted()}>
                                    <View
                                        style={{
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {!loading && (
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'submit')}
                                            </Text>
                                        )}
                                        {loading && <ActivityIndicator color={getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext).color} size={'small'}></ActivityIndicator>}
                                    </View>
                                </Pressable>
                                <Pressable
                                    style={{
                                        marginTop: 10,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => goBack()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'back')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
