import { ActivityIndicator, View, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import { faShield, faUser } from '@fortawesome/pro-light-svg-icons';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import moment from 'moment';
import * as React from 'react';
import { BackHandler, KeyboardAvoidingView as KAV } from 'react-native';
import Input from '../../../application/builder/components/input/input';
import Keyboard from '../../../application/builder/components/keyboard/keyboard';
import Modal from '../../../application/builder/components/modal/modal';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { KeyboardContext } from '../../../application/context/keyboardContext';
import { PlayerContext } from '../../../application/context/playerContext';
import { UserContext } from '../../../application/context/userContext';
import { getUserIp } from '../../../application/data/account';
import { useEffectOnce } from '../../../application/hooks/useEffectOnce';
import { useTVRemoteHandler } from '../../../application/hooks/useTVRemoteHandler';
import lang from '../../../application/languages/languages';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import HTMLRenderer from '../../../libs/react-native-render-html';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import Text from '../../../application/builder/components/text';
import { getFocusStyling, getInputStyling, getKeyboardStyling, getModalStyling, getPressableStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';
import Subscriber_Messages from '../../../application/builder/elements/promotions/message';
import { getAuthenticationText } from '../helper/helper';
import utils from '../../../application/general/utils';

const KeyboardAvoidingView = withParentContextMapper(KAV);

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const { keyboardVisible, openKeyboard, value, setKeyboardVisible } = React.useContext(KeyboardContext);
    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [username, setUsername] = React.useState(userContext.userId);
    const [password, setPassword] = React.useState(userContext.pass);
    const [error, setError] = React.useState('');
    const [type, setType] = React.useState('');

    const [showExit, setShowExit] = React.useState(false);
    const showExitSync = React.useRef(false);
    const backCount = React.useRef(0);
    const disableNativeHardwareBackPress = () => {
        return true;
    };
    React.useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return () => BackHandler.removeEventListener('hardwareBackPress', disableNativeHardwareBackPress);
    }, []);

    useTVRemoteHandler(({ eventType, eventKeyAction }: any) => {
        if (!deviceContext.isWebTV && eventKeyAction === 'down') {
            if (['back', 'menu'].includes(eventType)) {
                if (keyboardVisible) {
                    setKeyboardVisible(false);
                } else {
                    if (appContext.application.settings?.enable_nested == true) {
                        goBack();
                        return;
                    } else if (appContext.application.settings.languages.length > 1) {
                        goBack();
                        return;
                    } else {
                        backCount.current++;
                        if (backCount.current == 1) {
                            setShowExit(true);
                            showExitSync.current = true;
                        }
                        if (backCount.current == 2) {
                            if (deviceContext.isTizen) {
                                // @ts-expect-error
                                if (typeof tizen !== 'undefined') {
                                    // @ts-expect-error
                                    window.tizen.application.getCurrentApplication().exit();
                                }
                            } else if (deviceContext.isWebos) {
                                // @ts-expect-error
                                if (typeof webOS !== 'undefined') {
                                    // @ts-expect-error
                                    webOS.platformBack();
                                }
                            } else {
                                BackHandler.exitApp();
                            }
                        }
                    }
                }
            } else {
                if (showExitSync.current == true) {
                    cancelExit();
                }
            }
        }
    });
    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                if (keyboardVisible) {
                    setKeyboardVisible(false);
                } else {
                    if (appContext.application.settings?.enable_nested == true) {
                        goBack();
                        return;
                    } else if (appContext.application.settings.languages.length > 1) {
                        goBack();
                        return;
                    } else {
                        backCount.current++;
                        if (backCount.current == 1) {
                            setShowExit(true);
                            showExitSync.current = true;
                        }
                        if (backCount.current == 2) {
                            if (deviceContext.isTizen) {
                                // @ts-expect-error
                                if (typeof tizen !== 'undefined') {
                                    // @ts-expect-error
                                    window.tizen.application.getCurrentApplication().exit();
                                }
                            } else if (deviceContext.isWebos) {
                                // @ts-expect-error
                                if (typeof webOS !== 'undefined') {
                                    // @ts-expect-error
                                    webOS.platformBack();
                                }
                            } else {
                                BackHandler.exitApp();
                            }
                        }
                    }
                }
            } else {
                if (showExitSync.current == true) {
                    cancelExit();
                }
            }
        };

        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);

    const cancelExit = () => {
        setShowExit(false);
        showExitSync.current = false;
        backCount.current = 0;
    };

    useEffectOnce(() => {
        if (userContext.autoLogin) {
            loginUser();
        } else {
            setShow(true);
        }
    });

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.LOGIN, duration);
            };
        }, [])
    );

    const loginUser = async () => {
        if (loading == false) {
            setLoading(true);
            sendActionReport(appContext, deviceContext, userContext, actionType.LOGIN);
            var result = await getUserIp(username, password, appContext, userContext, deviceContext, userContext, playerContext, false);
            if (result != 'success') {
                setError(result);
                setLoading(false);
                setShow(true);
            } else {
                setError('');
                setLoading(false);
                if (deviceContext.isWebTV) {
                    var webTvUrl = window.location.href;
                    if (webTvUrl.indexOf('connect=true') > -1) {
                        navigation && navigation.navigate('LoginCode');
                    } else {
                        startContentLoader();
                    }
                } else {
                    startContentLoader();
                }
            }
        }
    };
    const startContentLoader = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Loader' }],
            })
        );
    };

    const goBack = () => {
        if (appContext.application.settings?.enable_nested == true || userContext.orginalPackageId != '') {
            if (userContext.orginalPackageId == '') {
                userContext.setAppId('');
                utils.storeJson('ServiceID', '');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Services' }],
                    })
                );
            } else {
                utils.storeJson('ServiceID', '');
                userContext.setAppId('');
                fetchData(userContext.orginalPackageId);
            }
        } else {
            if (appContext.application.settings.languages.length > 1) {
                userContext.setSelectedLanguage('');
                userContext.setSelectedLanguageCode('');
                userContext.setAutoLogin(false);
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Language' }],
                    })
                );
            }
        }
    };

    const fetchData = async (packageId) => {
        const path = 'https://cloudtv.akamaized.net/donotremove/tvms/domains/' + packageId + '/app.json?' + moment().unix();
        await fetch(path)
            .then((response) => response.json())
            .then(async (responseJson) => {
                global.Application = responseJson;
                var pathCart = 'https://cloudtv.akamaized.net/' + responseJson.client + '/' + responseJson.deployment + '/' + responseJson.branding.service + '/carts/' + responseJson.settings.webshop_id + '_cart.json';
                await fetch(pathCart)
                    .then((responseCart) => responseCart.json())
                    .then((responseCartJson) => {
                        global.Cart = responseCartJson;
                        appContext.setApplication(global.Application);
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Services' }],
                            })
                        );
                    })
                    .catch(() => {
                        appContext.setApplication(global.Application);
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Services' }],
                            })
                        );
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const setUsernameLocal = (value: any) => {
        setUsername(value);
        setError('');
    };
    const setPasswordLocal = (value: any) => {
        setPassword(value);
        setError('');
    };
    const loginUserViaQr = () => {
        navigation && navigation.navigate('Connect');
    };

    const forgotPassword = () => {
        navigation && navigation.navigate('Forgot');
    };
    const getStarted = () => {
        if (appContext.application.settings.enable_signup) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'GetStarted' }],
                })
            );
        }
    };

    const registerAccount = () => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [{ name: 'Register' }],
            })
        );
    };
    const openPwa = () => {
        navigation && navigation.navigate('Pwa');
    };
    const closeError = () => {
        setError('');
    };

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            <KeyboardAvoidingView behavior={deviceContext.isApple ? 'padding' : 'height'} style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                {showExit && <Modal focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'exit'} submitChoice={cancelExit} timerTime={3}></Modal>}
                {keyboardVisible && type == 'userid' && (
                    <Keyboard
                        onCloseKeyboard={() => setKeyboardVisible(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components.keyboard._id, appContext)}
                        onChangText={setUsernameLocal}
                        value={username}
                        focusOptions={{ nextFocusUp: 'submit-button', nextFocusDown: 'submit-button' }}
                    ></Keyboard>
                )}
                {keyboardVisible && type == 'password' && (
                    <Keyboard
                        onCloseKeyboard={() => setKeyboardVisible(false)}
                        focusContext={focusContext}
                        width={getRealWidth(deviceContext) * 0.8}
                        isSecure={false}
                        placeholder={''}
                        styling={getKeyboardStyling(appContext.application.authentication.services.components.keyboard._id, appContext)}
                        onChangText={setPasswordLocal}
                        value={password}
                        focusOptions={{ nextFocusUp: 'submit-button', nextFocusDown: 'submit-button' }}
                        onBlur={() => setKeyboardVisible(false)}
                    ></Keyboard>
                )}

                {!show && (
                    <View style={{ flex: 1 }}>
                        <View style={{ flex: 4, flexDirection: 'row' }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                            </View>
                        </View>
                    </View>
                )}
                {error != '' && <Modal error={error} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'error'} setShowModal={closeError} submitChoice={closeError}></Modal>}
                {show && (
                    <View style={{ flex: 1, width: getRealWidth(deviceContext) }}>
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, 'ExtraExtraLarge'),
                                    fontWeight: appContext.application.authentication?.login?.header.title_bold ? 'bold' : 'normal',
                                    color: appContext.application.authentication?.login?.header.title_color,
                                    textAlign: 'center',
                                }}
                            >
                                {lang.getTranslation(userContext, 'signin')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: deviceContext.fontType,
                                    fontSize: getFontSize(deviceContext, appContext.application.authentication?.login?.header.sub_size),
                                    fontWeight: appContext.application.authentication?.login?.header.sub_bold ? 'bold' : 'normal',
                                    color: appContext.application.authentication?.login?.header.sub_color,
                                    textAlign: 'center',
                                }}
                            >
                                {lang.getTranslation(userContext, 'sigininaccount').toUpperCase()}
                            </Text>
                        </View>
                        <View
                            style={{
                                flex: 4,
                                flexDirection: appContext.application.authentication?.login?.translations?.translations?.length > 0 && !deviceContext.isKaiOs ? 'row' : 'column',
                            }}
                        >
                            {userContext.userId.indexOf('_review') < 0 && <Subscriber_Messages key={0} focusContext={focusContext} location={'login'}></Subscriber_Messages>}

                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <Input
                                    value={username}
                                    onChangText={(value) => setUsernameLocal(value)}
                                    icon={faUser}
                                    width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                                    isSecure={false}
                                    placeholder={lang.getTranslation(userContext, 'userid')}
                                    animation={getFocusStyling('Inputs', appContext)}
                                    styling={getInputStyling(appContext.application.authentication.login.components?.input?._id, appContext)}
                                    focusOptions={{ focusKey: 'userid' }}
                                    onFocus={() => {
                                        if (keyboardVisible) {
                                            setKeyboardVisible(false);
                                        }
                                    }}
                                    showKeyboard={() => {
                                        setKeyboardVisible(true);
                                        setType('userid');
                                    }}
                                ></Input>

                                <Input
                                    value={password}
                                    style={{ marginTop: 10 }}
                                    onChangText={(value) => setPasswordLocal(value)}
                                    icon={faShield}
                                    width={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                                    isSecure={true}
                                    placeholder={lang.getTranslation(userContext, 'password')}
                                    animation={getFocusStyling('Inputs', appContext)}
                                    styling={getInputStyling(appContext.application.authentication.login.components?.input?._id, appContext)}
                                    focusOptions={{ focusKey: 'pass' }}
                                    onFocus={() => {
                                        if (keyboardVisible) {
                                            setKeyboardVisible(false);
                                        }
                                    }}
                                    showKeyboard={() => {
                                        setKeyboardVisible(true);
                                        setType('password');
                                    }}
                                ></Input>
                                <View style={{ marginTop: 10 }}>
                                    <Text
                                        style={{
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Small'),
                                            fontWeight: appContext.application.authentication?.login?.header.sub_bold ? 'bold' : 'normal',
                                            color: appContext.application.authentication?.login?.header.sub_color,
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'user_pass_case')}
                                    </Text>
                                </View>
                                <Pressable
                                    style={{
                                        marginTop: 20,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => loginUser()}
                                    focusOptions={{ focusKey: 'submit-button' }}
                                    onFocus={() => {
                                        if (keyboardVisible) {
                                            setKeyboardVisible(false);
                                        }
                                    }}
                                >
                                    <View>
                                        {!loading && ( 
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'submit')}
                                            </Text>
                                        )}
                                        {loading && <ActivityIndicator color={getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext).color} size={'small'}></ActivityIndicator>}
                                    </View>
                                </Pressable>

                                {appContext.application.settings?.enable_forgot && (
                                    <Pressable
                                        style={{
                                            marginTop: 10,
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => forgotPassword()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'forgot_password')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                                {!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && !deviceContext.isKaiOs && (
                                    <Text
                                        style={{
                                            padding: 10,
                                            fontFamily: deviceContext.fontType,
                                            fontSize: getFontSize(deviceContext, 'Large'),
                                            ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                        }}
                                    >
                                        {lang.getTranslation(userContext, 'or')}
                                    </Text>
                                )}
                                {!deviceContext.isPhone && !deviceContext.isTablet && !deviceContext.isWebTV && !deviceContext.isKaiOs && appContext.application.settings.enable_connectcode && (
                                    <Pressable
                                        style={{
                                            marginTop: 10,
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => loginUserViaQr()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'signin_via_qr')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                                {appContext.application.settings.enable_signup && appContext.application.settings.offer_type == 'cart' && (
                                    <Pressable
                                        style={{
                                            marginTop: 10,
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => getStarted()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'get_started')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                                {appContext.application.settings.enable_register && appContext.application.settings.offer_type == 'freemium' && (
                                    <Pressable
                                        style={{
                                            marginTop: 10,
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => registerAccount()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'register_free_account')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                                {deviceContext.isPwa && (
                                    <Pressable
                                        style={{
                                            marginTop: 10,
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                            height: getCorrectHeight(deviceContext, 30),
                                            ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        animatorOptions={getFocusStyling('Buttons', appContext)}
                                        onPress={() => openPwa()}
                                    >
                                        <View>
                                            <Text
                                                style={{
                                                    fontFamily: deviceContext.fontType,
                                                    fontSize: getFontSize(deviceContext, 'Normal'),
                                                    ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                                }}
                                            >
                                                {lang.getTranslation(userContext, 'pwa_howto')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                )}
                                <Pressable
                                    style={{
                                        marginTop: 10,
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => goBack()}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(appContext.application.authentication.login.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getTranslation(userContext, 'back')}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                            {appContext.application.authentication?.login?.text.length > 0 && (
                                <View
                                    style={{
                                        borderColor: appContext.application.authentication?.login?.header.title_color,
                                        borderWidth: 1,
                                        marginVertical: 50,
                                    }}
                                ></View>
                            )}
                            {appContext.application.authentication?.login?.translations?.translations?.length > 0 && !deviceContext.isKaiOs && !deviceContext.isPhone && !deviceContext.isPwaVertical && (
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <View
                                        style={{
                                            borderRadius: getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext).borderRadius,
                                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.4),
                                            marginVertical: 50,
                                        }}
                                    >
                                        <HTMLRenderer
                                            html={getAuthenticationText(appContext.application.authentication?.login?.translations?.translations, userContext)}
                                            contentWidth={getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3)}
                                        />
                                    </View>
                                </View>
                            )}
                        </View>
                    </View>
                )}
            </KeyboardAvoidingView>
        </ScreenWrapper>
    );
};
export default Screen_;
