import * as React from 'react';
import { View, ActivityIndicator, withParentContextMapper } from '@scriptx-com/xtv-toolkit';
import { Details_Top as styling } from '../../../models/elements/details/top/top';
import { getRealHeight, getRealWidth } from '../../../styling/realDeviceSizes';
import { getCorrectHeight, getCorrectWidth } from '../../../styling/correctSizes';
import { TextFade } from '../../animations/textFade';
import { setRecommendations } from '../../../data/account';
import { getCurrentScreenName } from '../../../hooks/getScreenName';
import { ContentContext } from '../../../context/contentContext';
import { UserContext } from '../../../context/userContext';
import Modal from '../../components/modal/modal';
import { DeviceContext } from '../../../context/deviceContext';
import { AppContext } from '../../../context/appContext';
import Parental from '../../components/parental/parental';
import Image from '../../components/image/image';
import { getModalStyling } from '../../components/helpers/helper';
import { ActorsRowText, MetaRow, StatusTimeRow, DescriptionRow, ProgressRow, BackgroundImage, LogoRow, ButtonRowTop, ActorsRowImages, ButtonRowRound, ButtonRowBig, EpisodeList, LessonList } from './helpers/helpers_portrait';
import {
    onPressContinue,
    onPressFavorite,
    onPressPlay,
    onPressPlayCatchupTV,
    onPressPlayRecording,
    onPressPlaySeason,
    onPressPurchase,
    onPressRecording,
    onPressRecordingDelete,
    onPressRemind,
    onPressReplay,
    onPressTrailer,
    onPressWatchlist,
    playEpisode,
    playLesson,
    recordProgram,
    remindProgram,
} from './helpers/functions';
import { sendActionReport } from '../../../../reporting/apis/action';
import { actionType } from '../../../../reporting/models/apps';
import { PlayerContext } from '../../../context/playerContext';
import TheoPlayer from '../../components/players/theoPlayer';
import { useSafeAreaInsets } from '../../../../libs/react-native-safe-area-context';
import { SettingsContext } from '../../../context/settingsContext';
import moment from 'moment';

const TextFadeWithContext = withParentContextMapper(TextFade);

export interface Props {
    styling: styling;
    navigation: any;
    focusContext?: any;
    contentScreensAvailable: boolean;
}

export const Details_Top = ({ styling, navigation, focusContext, contentScreensAvailable }: Props) => {
    
    const [data, setData] = React.useState([] as any);

    const [isDropdownOpen, setDropdownOpen] = React.useState(false);
    const [selectedSeasonIndex, setSelectedSeasonIndex] = React.useState(0);

    const [showRecording, setShowRecording] = React.useState(false);
    const [showRecordingSuccess, setShowRecordingSuccess] = React.useState(false);
    const [showRecordingError, setShowRecordingError] = React.useState(false);
    const [showRecordingWaiting, setShowRecordingWaiting] = React.useState(false);
    const [showParentalRecording, setShowParentalRecording] = React.useState(false);
    const [showRecordingRemoved, setShowRecordingRemoved] = React.useState(false);

    const [showReminder, setShowReminder] = React.useState(false);
    const [showReminderSuccess, setShowReminderSuccess] = React.useState(false);
    const [showReminderRemoved, setShowReminderRemoved] = React.useState(false);
    const [showParentalReminder, setShowParentalReminder] = React.useState(false);

    const [playerHidden, setPlayerHidden] = React.useState(true);

    const screenName = getCurrentScreenName();
    const contentContext = React.useContext(ContentContext);
    const appContext = React.useContext(AppContext);
    const userContext = React.useContext(UserContext);
    const deviceContext = React.useContext(DeviceContext);
    const playerContext = React.useContext(PlayerContext);
    const settingsContext = React.useContext(SettingsContext);

    const [updateScreenFavorite, setUpdateScreenFavorite] = React.useState(0);
    const [updateScreenWatchlist, setUpdateScreenWatchlist] = React.useState(0);

    const insets = useSafeAreaInsets();

    const getData = async () => {
        setPlayerHidden(false)
        var path = '';
        if (screenName == 'Short') {
            setData(contentContext.shortData.short);
        } else {
            if (screenName == 'Movie') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/movies/details/' + contentContext.movieData.movie._id + '_details.json';
            }
            if (screenName == 'Seasons') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/series/details/' + contentContext.seriesData.series._id + '_details.json';
            }
            if (screenName == 'Course') {
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/courses/details/' + contentContext.courseData.course._id + '_details.json';
            }
            if (screenName == 'Program') {
                const date = moment().add(contentContext.epgOffSetDay, 'day').format('DD') + '-' + Number(moment().add(contentContext.epgOffSetDay, 'day').month() + 1) + '-' + moment().add(contentContext.epgOffSetDay, 'day').year();
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/epg/details/' + contentContext.channelData.channel._id + '_' + date + '_details.json';
            }
            if (screenName == 'Recording') {
                const date = moment.unix(contentContext.recordingData.program.s).format('DD') + '-' + Number(moment.unix(contentContext.recordingData.program.s).month() + 1) + '-' + moment.unix(contentContext.recordingData.program.s).year();
                path = appContext.cloudUrl + appContext.application.client + '/' + appContext.application.deployment + '/channels/epg/details/' + contentContext.recordingData.channel._id + '_' + date + '_details.json';
            }
            try {
                let response = await fetch(path);
                let data = await response.json();
                if (data != undefined) {
                    if (screenName == 'Seasons') {
                        contentContext.setSeriesData({
                            ...contentContext.seriesData,
                            series: data,
                            seasons: data.seasons,
                            season: data.seasons[0],
                            seasonCategoryIndex: 0,
                        });
                        setRecommendations(contentContext.seriesData.series.tags, appContext.application, userContext);
                    }
                    if (screenName == 'Course') {
                        contentContext.setCourseData({
                            ...contentContext.courseData,
                            course: data,
                        });
                        setRecommendations(data.tags, appContext.application, userContext);
                    }
                    if (screenName == 'Movie') {
                        contentContext.setMovieData({
                            ...contentContext.movieData,
                            movie: data,
                        });
                        setRecommendations(data.tags, appContext.application, userContext);
                    }
                    if (screenName == 'Program') {
                        var program = data.find((e) => e.i == contentContext.channelData.program.i);
                        contentContext.setRecordingData({
                            recordingIndex: 0,
                            program: program,
                            channel: contentContext.channelData.channel,
                        });
                        if (program != undefined) {
                            data = { program: program };
                        } else {
                            navigation && navigation.goBack();
                        }
                    }
                    if (screenName == 'Recording') {
                        var program = data.find((e) => e.i == contentContext.recordingData.program.i);
                        data = { program: program };
                    }
                    setData(data);
                }
            } catch (error) {
                setData(null);
            }
        }
    };

    React.useEffect(() => {
        navigation.addListener('focus', () => {
            getData();
        });
    }, [navigation]);

    const onSubmitRecording = async () => {
        setShowRecording(false);
        setShowRecordingWaiting(true);
        var result = (await recordProgram(userContext, appContext, data.program, contentContext.channelData.channel)) as any;
        if (result?.success == false) {
            setShowRecordingWaiting(false);
            setShowRecordingError(true);
        } else {
            setShowRecordingWaiting(false);
            setShowRecordingSuccess(true);
        }
    };
    const onSubmitReminder = () => {
        setShowReminder(false);
        remindProgram(userContext, appContext, data.program, contentContext.channelData.channel);
        setShowReminderSuccess(true);
    };

    const onPressChromecast = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_CHROMECAST);
        if (playerContext.casting?.type != 'Chromecast') {
            playerContext.setCasting({
                type: 'Chromecast',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };
    const onPressAirplay = () => {
        sendActionReport(appContext, deviceContext, userContext, actionType.PLAYER_AIRPLAY);
        if (playerContext.casting?.type != 'Airplay') {
            playerContext.setCasting({
                type: 'Airplay',
            });
        } else {
            playerContext.setCasting({
                type: '',
            });
        }
    };
    const onSelectSeason = (contentContext, seasonIndex) => {
        contentContext.setSeriesData({
            ...contentContext.seriesData,
            localCategoryIndex: seasonIndex,
            seasonCategoryIndex: seasonIndex,
        });
        setSelectedSeasonIndex(seasonIndex);
    };

    return (
        <View style={{ flexGrow: 1, 
        marginBottom: contentScreensAvailable ? 0 : global.menuHeight,

         }} focusContext={focusContext}>
            {showParentalRecording && (
                <Parental
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    setParentalApproved={() => {
                        setShowRecording(true);
                        setShowParentalRecording(false);
                    }}
                    setShowParental={setShowParentalRecording}
                    type={'access_content'}
                ></Parental>
            )}
            {showParentalReminder && (
                <Parental
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.parental, appContext)}
                    setParentalApproved={() => {
                        setShowReminder(true);
                        setShowParentalReminder(false);
                    }}
                    setShowParental={setShowParentalRecording}
                    type={'access_content'}
                ></Parental>
            )}
            {showRecordingSuccess && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.recording, appContext)}
                    type={'recording_success'}
                    submitChoice={null}
                    setShowModal={() => setShowRecordingSuccess(false)}
                ></Modal>
            )}
            {showRecordingError && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.recording, appContext)}
                    type={'recording_error'}
                    submitChoice={null}
                    setShowModal={() => setShowRecordingError(false)}
                ></Modal>
            )}
            {showRecordingWaiting && <Modal program={data.program} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.recording, appContext)} type={'recording_waiting'} submitChoice={null}></Modal>}
            {showRecording && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.recording, appContext)}
                    type={'recording'}
                    submitChoice={onSubmitRecording}
                    setShowModal={() => setShowRecording(false)}
                ></Modal>
            )}
            {showReminder && (
                <Modal program={data.program} focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)} type={'reminder'} setShowModal={setShowReminder} submitChoice={onSubmitReminder}></Modal>
            )}
            {showReminderSuccess && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                    type={'reminder_success'}
                    setShowModal={setShowReminderSuccess}
                    submitChoice={() => setShowReminderSuccess(false)}
                ></Modal>
            )}
            {showReminderRemoved && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                    type={'reminder_removed'}
                    setShowModal={setShowReminderRemoved}
                    submitChoice={() => setShowReminderRemoved(false)}
                ></Modal>
            )}
            {showRecordingRemoved && (
                <Modal
                    program={data.program}
                    focusContext={focusContext}
                    styling={getModalStyling(appContext.application.theme.modals.reminder, appContext)}
                    type={'recording_removed'}
                    setShowModal={setShowRecordingRemoved}
                    submitChoice={() => setShowRecordingRemoved(false)}
                ></Modal>
            )}

            <View style={{ flexGrow: 1 }}>
                {data != null && data?.length == 0 && (
                    <View
                        style={{
                            flexDirection: 'row',
                            width: getRealWidth(deviceContext),
                            height: getRealHeight(deviceContext) - getCorrectHeight(deviceContext, deviceContext.isPhone || deviceContext.isPwaVertical ? 0 : 100),
                        }}
                    >
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <ActivityIndicator size={'large'} color={'white'}></ActivityIndicator>
                        </View>
                    </View>
                )}

                {data != null && data.length != 0 && (
                    <View style={{ flexGrow: 1 }}>
                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext),
                                height:getRealHeight(deviceContext),
                  
                                zIndex: 2,
                            }}
                        >
                            <Image
                                isLocal={true}
                                deviceContext={deviceContext}
                                source={require('../../../../assets/images/gradient-details-mobile.png')}
                                resizeMode={'cover'}
                                style={{ height: screenName == 'Seasons' || screenName == 'Course' ? getRealHeight(deviceContext) : '110%', width: getRealWidth(deviceContext) }}
                            ></Image>
                        </View>

                        <View
                            style={{
                                position: 'absolute',
                                width: getRealWidth(deviceContext),
                                height: '100%',
                                zIndex: 2,
                            }}
                        >
                            <Image isLocal={true} deviceContext={deviceContext} source={require('../../../../assets/images/gradient-details-mobile.png')} resizeMode={'cover'} style={{ height: '110%', width: getRealWidth(deviceContext) }}></Image>
                        </View>

                        <BackgroundImage item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext}></BackgroundImage>

                        <ButtonRowTop
                            insets={insets}
                            onPressAirplay={() => onPressAirplay()}
                            onPressChromecast={() => onPressChromecast()}
                            deviceContext={deviceContext}
                            appContext={appContext}
                            userContext={userContext}
                            playerContext={playerContext}
                            styling={styling}
                            onPressBack={() => navigation.goBack()}
                        ></ButtonRowTop>

                        <View
                            style={{
                                zIndex: 99,
                                flexGrow: 1,
                                width: getRealWidth(deviceContext),
                                marginTop: getRealHeight(deviceContext) * 0.4,
                            }}
                        >
                            <TextFadeWithContext>
                                <View style={{ marginHorizontal: getCorrectWidth(deviceContext, 10) }}>
                                    {/* <LogoRow item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} contentContext={contentContext} styling={styling}></LogoRow> */}
                                    <StatusTimeRow item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></StatusTimeRow>
                                    <MetaRow item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></MetaRow>
                                    <ProgressRow item={data} type={screenName} deviceContext={deviceContext} appContext={appContext} userContext={userContext} contentContext={contentContext} styling={styling}></ProgressRow>
                                </View>
                            </TextFadeWithContext>
                            <View style={{ flexGrow: 1 }}>
                                <ButtonRowBig
                                    item={data}
                                    type={screenName}
                                    deviceContext={deviceContext}
                                    appContext={appContext}
                                    userContext={userContext}
                                    contentContext={contentContext}
                                    styling={styling}
                                    onPressRecordingDelete={(item) => onPressRecordingDelete(userContext, appContext, data.program, navigation)}
                                    onPressBack={() => navigation.goBack()}
                                    onPressPlay={(item) => {
                                        setPlayerHidden(true);
                                        onPressPlay(screenName, navigation, item, contentContext, appContext, userContext, 0, settingsContext);
                                    }}
                                    onPressPlayRecording={(item) => {
                                        setPlayerHidden(true);
                                        onPressPlayRecording(navigation, settingsContext);
                                    }}
                                    onPressPlaySeason={(seasonIndex, episodeIndex) => {
                                        setPlayerHidden(true);
                                        onPressPlaySeason(contentContext, data, navigation, seasonIndex, episodeIndex, settingsContext);
                                    }}
                                    onPressPlayCatchupTV={(startType, item, programIndex) => {
                                        setPlayerHidden(true);
                                        onPressPlayCatchupTV(navigation, startType, item, contentContext, settingsContext, programIndex);
                                    }}
                                    onPressPurchase={() => onPressPurchase()}
                                    onPressContinue={(item) => {
                                        setPlayerHidden(true);
                                        onPressContinue(screenName, navigation, item, settingsContext);
                                    }}
                                    onPressRemind={(isReminded) => onPressRemind(isReminded, data.program, appContext, userContext, setShowReminder, setShowReminderRemoved)}
                                ></ButtonRowBig>
                                <View style={{ marginHorizontal: getCorrectWidth(deviceContext, 15), marginTop: getCorrectHeight(deviceContext, 10) }}>
                                    <DescriptionRow appContext={appContext} item={data} type={screenName} deviceContext={deviceContext} userContext={userContext} contentContext={contentContext} styling={styling}></DescriptionRow>
                                </View>

                                <View style={{ marginHorizontal: getCorrectWidth(deviceContext, 15) }}>
                                    <ActorsRowText type={screenName} styling={styling} item={data} appContext={appContext} deviceContext={deviceContext} userContext={userContext}></ActorsRowText>
                                </View>
                                <View style={{ paddingVertical: getCorrectHeight(deviceContext, 5), marginBottom: getCorrectHeight(deviceContext, screenName == 'Short' ? 70 : 5) }}>
                                    <ButtonRowRound
                                        item={data}
                                        type={screenName}
                                        deviceContext={deviceContext}
                                        appContext={appContext}
                                        userContext={userContext}
                                        contentContext={contentContext}
                                        styling={styling}
                                        onPressTrailer={(item) => {
                                            setPlayerHidden(true);
                                            onPressTrailer(screenName, navigation, item, settingsContext);
                                        }}
                                        onPressReplay={() => {
                                            setPlayerHidden(true);
                                            onPressReplay(screenName, navigation, settingsContext);
                                        }}
                                        onPressFavorite={(isFavorite, item) => onPressFavorite(isFavorite, contentContext, userContext, screenName, appContext, deviceContext, setUpdateScreenFavorite, item)}
                                        onPressWatchlist={(isWatchlist, item, seasonIndex, episodeIndex) => onPressWatchlist(isWatchlist, userContext, screenName, appContext, contentContext, setUpdateScreenWatchlist, item, seasonIndex, episodeIndex)}
                                        onPressRecording={(isRecorded) => onPressRecording(isRecorded, userContext, appContext, data.program, setShowRecording, setShowRecordingRemoved)}
                                    ></ButtonRowRound>
                                </View>
                            </View>
                            <View style={{ flexGrow: 1, marginHorizontal: getCorrectWidth(deviceContext, 10) }}>
                                <ActorsRowImages type={screenName} item={data} styling={styling} appContext={appContext} deviceContext={deviceContext} userContext={userContext}></ActorsRowImages>
                            </View>
                            {screenName == 'Seasons' && (
                                <View style={{ flexGrow: 1 }}>
                                    <EpisodeList
                                        playEpisode={(item, seasonIndex, episodeIndex) => {
                                            setPlayerHidden(true);
                                            playEpisode(contentContext, item, navigation, seasonIndex, episodeIndex, settingsContext);
                                        }}
                                        focusContext={focusContext}
                                        series={data}
                                        seasons={data.seasons}
                                        styling={styling}
                                        deviceContext={deviceContext}
                                        userContext={userContext}
                                        appContext={appContext}
                                        contentContext={contentContext}
                                        onSelectSeason={onSelectSeason}
                                        setDropdownOpen={setDropdownOpen}
                                        isDropdownOpen={isDropdownOpen}
                                    ></EpisodeList>
                                </View>
                            )}
                            {screenName == 'Course' && (
                                <View style={{ flexGrow: 1 }}>
                                    <LessonList
                                        screenName={screenName}
                                        playLesson={(item, lessonIndex) => {
                                            setPlayerHidden(true);
                                            playLesson(contentContext, item, navigation, lessonIndex, settingsContext);
                                        }}
                                        focusContext={focusContext}
                                        styling={styling}
                                        deviceContext={deviceContext}
                                        userContext={userContext}
                                        appContext={appContext}
                                        contentContext={contentContext}
                                        course={data}
                                    ></LessonList>
                                </View>
                            )}
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
};

export default Details_Top;
